import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MRT_ColumnPinningState, MRT_DensityState } from 'material-react-table'
import {
  SortTableKeys,
  TableFiltersSelectionType,
  TableFiltersType,
} from './types'
import { transformSelectionDataToSend } from '../useFiltersController'

// Слайс для сохранения состояний конфигов таблиц
export const TABLE_SETTINGS_MODULE = 'TABLE_SETTINGS'

export type TableSettingsModel = {
  id: number
  v: number
  columnSizing: Record<string, number>
  columnVisibility: Record<string, boolean>
  columnOrder: string[] | []
  columnPinning: MRT_ColumnPinningState
  fontSize: number
  isFetched: boolean
  isNow: boolean
  density: MRT_DensityState
  filters?: TableFiltersSelectionType
  filtersToSend?: TableFiltersType
  sortKeys?: SortTableKeys[]
  generalSearch?: string
}

export type TableStateType = {
  tables: Record<number, TableSettingsModel>
}

export type tableConfigModel = {
  v: number
  columnSizing: Record<string, number>
  columnVisibility: Record<string, boolean>
  columnOrder: string[] | []
  fontSize: number
  density: MRT_DensityState
}

const initialState: TableStateType = {
  tables: {},
}

const tableSlice = createSlice({
  name: TABLE_SETTINGS_MODULE,
  initialState,
  reducers: {
    setGeneralSearch: (
      state,
      action: PayloadAction<{ tableId: number; generalSearch: string }>
    ) => {
      const { tableId, generalSearch } = action.payload
      state.tables[tableId].generalSearch = generalSearch || ''
    },

    setSortKeys: (
      state,
      action: PayloadAction<{ tableId: number; sortKey: SortTableKeys['key'] }>
    ) => {
      const { tableId, sortKey } = action.payload

      let isExist = true

      const keys = (state.tables[tableId].sortKeys || []).map((sort) => {
        if (sort.key === sortKey) {
          isExist = false
          return { ...sort, descending: !sort.descending }
        }
        return sort
      })

      state.tables[tableId].sortKeys = isExist
        ? [...keys, { key: sortKey, descending: true }]
        : keys
    },

    mutableUpdateTableFilters: (
      state,
      action: PayloadAction<{
        tableId: number
        key: keyof TableFiltersSelectionType
        value: TableFiltersSelectionType[keyof TableFiltersSelectionType]
      }>
    ) => {
      const { key, tableId, value } = action.payload

      state.tables[tableId].filters = {
        ...state.tables[tableId].filters,
        [key]: value,
      }
    },

    updateTableFilters: (
      state,
      action: PayloadAction<{
        tableId: number
        filters: Partial<TableFiltersSelectionType>
      }>
    ) => {
      const { tableId, filters } = action.payload
      state.tables[tableId].filters = {
        ...state.tables[tableId].filters,
        ...filters,
      }
    },

    onClearAllFilters: (state, action: PayloadAction<number>) => {
      state.tables[action.payload].filters = undefined
      state.tables[action.payload].filtersToSend = undefined
      state.tables[action.payload].sortKeys = undefined
      state.tables[action.payload].generalSearch = undefined
    },

    sendFilters: (
      state,
      action: PayloadAction<{
        tableId: number
        filtersKeys: {
          key: keyof TableFiltersSelectionType
          listKey: keyof TableFiltersSelectionType
          ignoreListKey: keyof TableFiltersSelectionType
        }
      }>
    ) => {
      const { tableId, filtersKeys } = action.payload

      const { key, listKey, ignoreListKey } = filtersKeys

      const filters = state.tables[tableId].filters

      const filtersToUse: Partial<TableFiltersSelectionType> = {
        [key]: filters?.[key],
        [listKey]: filters?.[listKey],
        [ignoreListKey]: filters?.[ignoreListKey],
      }

      state.tables[tableId].filtersToSend = {
        ...state.tables[tableId].filtersToSend,
        ...transformSelectionDataToSend(filtersToUse || {}),
      }
    },
    clearLastVisit: (state, action: PayloadAction<number>) => {
      const tableId = action.payload

      if (!state.tables[tableId]?.filters) {
        state.tables[tableId].filters = {}
      }

      // @ts-ignore
      state.tables[tableId].filters.lastVisit = {
        userLastVisitEnd: null,
        userLastVisitStart: null,
      }

      if (!state.tables[tableId]?.filtersToSend) {
        state.tables[tableId].filtersToSend = {}
      }

      // @ts-ignore
      state.tables[tableId].filtersToSend.lastVisit = {
        userLastVisitEnd: null,
        userLastVisitStart: null,
      }
    },
    setLastVisitToUse: (state, action) => {
      const { rangeKey, tableId, rangeValue } = action.payload

      state.tables[tableId] = {
        ...state.tables[tableId],
        filters: {
          ...state.tables[tableId]?.filters,
          // @ts-ignore
          lastVisit: {
            ...state.tables[tableId]?.filters?.lastVisit,
            [rangeKey]: rangeValue || null,
          },
        },
      }
    },
    setLastVisitValueSend: (state, action) => {
      const { lastVisitObj, tableId } = action.payload
      state.tables[tableId] = {
        ...state.tables[tableId],
        filtersToSend: {
          ...state.tables[tableId]?.filtersToSend,
          // @ts-ignore
          lastVisit: { ...lastVisitObj },
        },
      }
    },
    setUserAcessUse: (
      state,
      action: PayloadAction<{ tableId: number; filterAccess: string | number }>
    ) => {
      const { tableId, filterAccess } = action.payload

      // @ts-ignore
      state.tables[tableId] = {
        ...state.tables[tableId],
        filters: {
          ...state.tables[tableId]?.filters,
          // @ts-ignore
          userAccessFilter: filterAccess,
        },
      }
    },
    setUserAcessSend: (
      state,
      action: PayloadAction<{ tableId: number; filterAccess: boolean | null }>
    ) => {
      const { tableId, filterAccess } = action.payload

      // @ts-ignore
      state.tables[tableId] = {
        ...state.tables[tableId],
        filtersToSend: {
          ...state.tables[tableId]?.filtersToSend,
          // @ts-ignore
          userAccessFilter: filterAccess,
        },
      }
    },
    clearUserAcess: (state, action: PayloadAction<number>) => {
      const tableId = action.payload

      state.tables[tableId] = {
        ...state.tables[tableId],
        filters: {
          ...state.tables[tableId]?.filters,
          userAccessFilter: '',
        },
        filtersToSend: {
          ...state.tables[tableId]?.filtersToSend,
          userAccessFilter: null,
        },
      }
    },
    initTable: (
      state,
      { payload }: PayloadAction<Record<number, TableSettingsModel>>
    ) => {
      state.tables = { ...state.tables, ...payload }
    },
    resetTableSlice: () => initialState,
    setColumnSizing: (
      state,
      {
        payload: { columnSizing, tableId },
      }: PayloadAction<{
        tableId: number
        columnSizing: Record<string, number>
      }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], columnSizing }
    },
    updateColumnSizing: (
      state,
      {
        payload: { columnSizing, tableId },
      }: PayloadAction<{
        tableId: number
        columnSizing: Record<string, number>
      }>
    ) => {
      state.tables[tableId].columnSizing = {
        ...state.tables[tableId].columnSizing,
        ...columnSizing,
      }
    },

    updateColumnPinning: (
      state,
      {
        payload,
      }: PayloadAction<{
        tableId: number
        columnPinning: MRT_ColumnPinningState
      }>
    ) => {
      state.tables[payload.tableId].columnPinning = payload.columnPinning
    },
    setColumnPinning: (
      state,
      {
        payload: { tableId, columnPinning },
      }: PayloadAction<{
        tableId: number
        columnPinning: MRT_ColumnPinningState
      }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], columnPinning }
    },
    setColumnVisibility: (
      state,
      {
        payload: { columnVisibility, tableId },
      }: PayloadAction<{
        tableId: number
        columnVisibility: Record<string, boolean>
      }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], columnVisibility }
    },
    setFonSize: (
      state,
      {
        payload: { fontSize, tableId },
      }: PayloadAction<{
        tableId: number
        fontSize: number
      }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], fontSize }
    },
    updateColumnVisibility: (
      state,
      {
        payload: { columnVisibility, tableId },
      }: PayloadAction<{
        tableId: number
        columnVisibility: Record<string, boolean>
      }>
    ) => {
      state.tables[tableId].columnVisibility = {
        ...state.tables[tableId].columnVisibility,
        ...columnVisibility,
      }
    },
    setColumnOrder: (
      state,
      {
        payload: { columnOrder, tableId },
      }: PayloadAction<{ tableId: number; columnOrder: string[] }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], columnOrder }
    },
    updateColumnOrder: (
      state,
      {
        payload: { columnOrder, tableId },
      }: PayloadAction<{ tableId: number; columnOrder: string[] }>
    ) => {
      state.tables[tableId].columnOrder = columnOrder
    },
    updateFontSize: (
      state,
      {
        payload: { fontSize, tableId },
      }: PayloadAction<{
        tableId: number
        fontSize: number
      }>
    ) => {
      state.tables[tableId].fontSize = fontSize
    },
    setFetched: (
      state,
      {
        payload: { tableId, isFetched },
      }: PayloadAction<{ tableId: number; isFetched: boolean }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], isFetched }
    },
    setIsNow: (
      state,
      {
        payload: { isNow, tableId },
      }: PayloadAction<{ tableId: number; isNow: boolean }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], isNow }
    },
    setDensity: (
      state,
      {
        payload: { density, tableId },
      }: PayloadAction<{ tableId: number; density: MRT_DensityState }>
    ) => {
      state.tables[tableId] = { ...state.tables[tableId], density }
    },
    updateDensity: (
      state,
      {
        payload: { density, tableId },
      }: PayloadAction<{ tableId: number; density: MRT_DensityState }>
    ) => {
      state.tables[tableId].density = density
    },
  },
})

export default tableSlice.reducer

export const {
  initTable,
  setColumnSizing,
  setColumnVisibility,
  setColumnOrder,
  setFetched,
  setIsNow,
  updateColumnOrder,
  updateColumnVisibility,
  updateColumnSizing,
  resetTableSlice,
  updateFontSize,
  setFonSize,
  updateColumnPinning,
  setDensity,
  updateDensity,
  setColumnPinning,
  updateTableFilters,
  sendFilters,
  mutableUpdateTableFilters,
  setSortKeys,
  onClearAllFilters,
  setGeneralSearch,
  setLastVisitValueSend,
  setLastVisitToUse,
  clearLastVisit,
  setUserAcessUse,
  setUserAcessSend,
  clearUserAcess,
} = tableSlice.actions
