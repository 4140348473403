import { uploadImageMainTab } from 'shared/api/requests/image'
import React, {
  DragEvent,
  DragEventHandler,
  MutableRefObject,
  memo,
} from 'react'
import { ImgCropper } from 'shared/UI/ImgCropper/ImgCropper'
import { coreUpdater } from 'shared/api/core/coreApi'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import { BORDER_RADIUS } from 'shared/lib/consts'
import { showSnack } from 'store/snackbar/snackbarSlice'
import image1 from 'shared/assets/images/no_image.png'
import { Typography, Tooltip, Box } from '@mui/material'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

const styles = {
  imgWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
    maxWidth: '400px',
    border: '1px dotted',
    borderRadius: BORDER_RADIUS,
    overflow: 'hidden',
    mr: 2,
    position: 'relative',
  },
  dropEffect: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s ease',

    '&:hover': {
      background: 'rgba(0,0,0,0.5)',

      '& p': {
        opacity: 1,
      },
    },
  },
}

export const ImgUpdate = memo(
  ({
    materialId,
    inputRef,
  }: {
    materialId: string | number
    inputRef: MutableRefObject<HTMLInputElement | null>
  }) => {
    const dispatch = useAppDispatch()

    const information = useAppSelector(
      (state) => state.materialSettings.information
    )

    const [open, setOpen] = React.useState(false)
    const [file, setFile] = React.useState<File | null>(null)

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (!files) return

      setFile(files[0])
      setOpen(true)

      if (inputRef.current) {
        inputRef.current!.value = ''
      }
    }

    const handleLoadFile = (file: File) => {
      uploadImageMainTab({
        courseId: materialId,
        imageType: '1',
        image: file,
      })
        .then((res) => {
          setFile(null)
          setOpen(false)
          coreUpdater.materialSettings()
        })
        .catch(() => {
          dispatch(showSnack({ typeSnack: 'error', messageSnack: 'Ошибка' }))
          setFile(null)
          setOpen(false)
        })
    }

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      const file = event.dataTransfer.files[0]

      setFile(file)
      setOpen(true)
    }

    return (
      <>
        <Box sx={styles.imgWrapper}>
          <Box
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleDrop}
            sx={styles.dropEffect}
          >
            <Typography
              component={'p'}
              color="white"
              align="center"
              sx={{ opacity: 0, transition: 'all 0.3s ease' }}
            >
              Перетащите с вашего компьютера картинку
            </Typography>
          </Box>
          {!information?.urlMiniature && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '0',
                right: '0',
                cursor: 'pointer',
              }}
            >
              <Tooltip title="Перетащите с вашего компьютера картинку, чтобы загрузить ее">
                <QuestionMarkIcon />
              </Tooltip>
            </Box>
          )}
          <img
            style={{
              minWidth: '100%',
              height: '100%',
              objectFit: information?.urlMiniature ? 'cover' : 'contain',
            }}
            src={information?.urlMiniature ? information?.urlMiniature : image1}
            alt="no available"
            height={'250px'}
            width={'400px'}
          />
        </Box>
        {open && file && (
          <ImgCropper
            open={open}
            setOpen={setOpen}
            file={file}
            callback={handleLoadFile}
          />
        )}
        <input
          accept="image/png, image/jpeg, image/gif"
          type="file"
          ref={inputRef}
          onChange={handleChangeFile}
          hidden
        />
      </>
    )
  }
)
