import { useCallback, useEffect, useState } from 'react'
import { IUserAdditionalInfo } from 'store/userAdditionlInfo/userAdditionalTypes'

interface PropsController {
  rows?: any
  single?: boolean
  defaultSelected?: any[]
  defaultIgnoreList?: any[]
  defaultCheckAll?: boolean
  onlySelectedShow?: boolean
  hideSettings?: boolean

  // Trigger to clear selections users
  clearSelectedTrigger?: boolean

  firstPageUsers: IUserAdditionalInfo[]
  getSaveSelect?: (arr: IUserAdditionalInfo) => any
}

export type ChooseListToCheck = 'ignored' | 'selected'

export const useUsersTableControllerSelect = ({
  rows,
  single,
  defaultSelected,
  defaultIgnoreList,
  defaultCheckAll,
  onlySelectedShow,
  clearSelectedTrigger,
  firstPageUsers,
  getSaveSelect,
}: PropsController) => {
  useState<keyof IUserAdditionalInfo>('userFullName')
  const [selected, setSelected] = useState<any[]>(defaultSelected || [])
  const [assignUsers, setAssignUsers] = useState<any>([])
  const [ignoreList, setIgnoreList] = useState<any[]>(defaultIgnoreList || [])
  const [checkAll, setCheckAll] = useState<boolean>(!!defaultCheckAll)

  useEffect(() => {
    clearSelects()
  }, [clearSelectedTrigger])

  const isSelected = useCallback(
    (
      user: IUserAdditionalInfo,
      // Лист в котором будет проверка
      ignored: ChooseListToCheck = 'selected'
    ): boolean | undefined => {
      const listToCheck = ignored === 'ignored' ? ignoreList : selected
      if (getSaveSelect) {
        return !!listToCheck.find((userFind) => {
          const user1 = getSaveSelect(userFind)
          const user2 = getSaveSelect(user)
          if (typeof user1 === 'object') {
            return user1.userId === user2.userId
          }
          return user1 === user2
        })
      }

      return !!listToCheck.find((us) => {
        if (typeof us === 'object') {
          return us.userId === user.userId
        }
        return us === user.userId
      })
    },
    [getSaveSelect, ignoreList, selected]
  )

  const selectAllControl = useCallback(
    (state: boolean) => {
      if (state) {
        // if (onlySelectedShow) {
        //   setCheckAll(false)
        //   setIgnoreList([])
        //   setSelected([])
        //   setAssignUsers([])
        //   return
        // }
        const allSelected = firstPageUsers.map((user) =>
          getSaveSelect ? getSaveSelect(user) : user.userId
        )
        setSelected(allSelected)
        setCheckAll(true)
        return
      }
      setCheckAll(false)
      setIgnoreList([])
      setSelected([])
      setAssignUsers([])
    },
    [firstPageUsers, getSaveSelect, onlySelectedShow]
  )

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | boolean) => {
      if (single) return
      if (typeof event === 'boolean') {
        selectAllControl(true)
      } else {
        setSelected([])
        selectAllControl(event.target.checked)
      }
    },
    [selectAllControl, single]
  )

  const clearSelects = () => {
    setAssignUsers([])
    setSelected([])
    setIgnoreList([])
    setCheckAll(false)
  }

  const handleClick = useCallback(
    (
      event: React.MouseEvent<unknown>,
      user: IUserAdditionalInfo,
      isIgnored: boolean
    ) => {
      const userForAdd = getSaveSelect ? getSaveSelect(user) : user.userId

      if (checkAll) {
        if (isIgnored)
          setIgnoreList((prev) =>
            prev.filter((val) => {
              if (typeof val === 'string') return val !== userForAdd
              else return val.userId !== userForAdd.userId
            })
          )
        else setIgnoreList((prev) => [...prev, userForAdd])

        return
      }

      const selectedIndex = selected.findIndex((user1) => {
        if (typeof user1 === 'object') {
          return user1.userId === user.userId
        }
        return user1 === user.userId
      })

      let newSelected: any[] = []

      if (single) {
        if (!!selectedIndex) setSelected([userForAdd])
        else setSelected([])
        return
      }

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, userForAdd)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      setSelected(newSelected)
    },
    [checkAll, getSaveSelect, selected, single]
  )

  return {
    isSelected,
    handleClick,
    handleSelectAllClick,
    selected,
    assignUsers,
    setSelected,
    clearSelects,
    checkAll,
    ignoreList,
  } as const
}
