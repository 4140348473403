import {
  ResponseMapperHandler,
  TableQueryWrapper,
  muiTableHeadCellSX,
  muiTableBodyCellSX,
  muiTablePaperSX,
} from '@proapteka/table'
import { FC, ReactNode, memo, useCallback } from 'react'
import { useTableSettingsStoreConnector } from 'shared/hooks/useTableSettingsStoreConnector'
import _ from 'lodash'
import { REGISTRATION_REQUESTS_COLUMNS_CONFIGS } from 'shared/lib/proTable'
import { MRT_ColumnDef } from 'material-react-table'
import { ChooseListToCheck } from 'widgets/UsersTableAll/model/useUsersTableControllerSelect'
import { IUserAdditionalInfo } from 'store/userAdditionlInfo/userAdditionalTypes'
import { FontChangeComponent } from 'shared/UI/FontChangeComponent/FontChangeComponent'
import EmptyTableFallback from 'shared/UI/EmptyTableFallback/EmptyTableFallback'
import { Box, Stack } from '@mui/material'

export interface ProTableProps {
  // id-таблицы по которой можно получить конфиги из стора
  tableId: number
  // версия таблицы
  version: number
  // конфиги для header-а таблицы
  columnLabels: MRT_ColumnDef<any>[]
  // api коллбэк функция
  apiRequestFn: (params: any) => void
  // фильтры для запроса
  filters: any
  // не стандартный mapper для таблицы
  responseMapper: ResponseMapperHandler
  // Выбранные пользователи
  actionToolbar?: ReactNode
  // Функция для обработки поиска
  onSearchChange: (upadater: any) => void
  // Триггер подгрузки данных
  triggers?: boolean

  addColumnOrder?: string[]
  addColumnSizing?: Record<string, number>
  addColumnVisibility?: Record<string, boolean>
  // правая часть кнопки
  additionalToolbarInternalActionsAfter?: ReactNode
  // Проверка на что чекбокс выбран
  isSelected: (
    user: IUserAdditionalInfo,
    // Лист в котором будет проверка
    ignored: ChooseListToCheck
  ) => boolean | undefined

  checkAll: boolean
  // Максимальная высота таблицы
  maxHeight?: string | number
  hideAllFilters?: boolean
}

const ProTable: FC<ProTableProps> = memo(
  (props) => {
    const {
      tableId,
      columnLabels,
      apiRequestFn,
      filters,
      responseMapper,
      actionToolbar,
      onSearchChange,
      triggers,
      addColumnOrder,
      addColumnSizing,
      addColumnVisibility,
      additionalToolbarInternalActionsAfter,
      isSelected,
      checkAll,
      maxHeight,
      hideAllFilters,
    } = props

    const tableSettingsStateGroup = useTableSettingsStoreConnector({
      tableId,
      version: 2, // При изменении конфигов повышать версию в ручную
      initColumnOrder: [
        ...REGISTRATION_REQUESTS_COLUMNS_CONFIGS.columnOrder,
        ...(addColumnOrder || []),
      ],
      initColumnSizing: {
        ...REGISTRATION_REQUESTS_COLUMNS_CONFIGS.columnSizing,
        ...(addColumnSizing || {}),
      },
      initColumnVisibility: {
        ...REGISTRATION_REQUESTS_COLUMNS_CONFIGS.columnVisibility,
        ...(addColumnVisibility || {}),
      },
      initFontSize: REGISTRATION_REQUESTS_COLUMNS_CONFIGS.defaultFontSize,
      initColumnPinning: {
        left: ['selectAll', 'userFullName'],
        right: [],
      },
      initDensity: REGISTRATION_REQUESTS_COLUMNS_CONFIGS.density,
    })

    const isChecked = useCallback(
      (originalRow: IUserAdditionalInfo): boolean | undefined => {
        const isIgnored = isSelected(originalRow, 'ignored')

        const checked = isIgnored
          ? false
          : checkAll || isSelected(originalRow, 'selected')

        return checked
      },
      [checkAll, isSelected]
    )

    const initialState = {
      columnSizing: REGISTRATION_REQUESTS_COLUMNS_CONFIGS.columnSizing,
      columnOrder: REGISTRATION_REQUESTS_COLUMNS_CONFIGS.columnOrder,
      columnVisibility: REGISTRATION_REQUESTS_COLUMNS_CONFIGS.columnVisibility,
      columnPinning: REGISTRATION_REQUESTS_COLUMNS_CONFIGS.columnPinning,
      showGlobalFilter: hideAllFilters ? false : true,
      globalFilter: filters.generalSearch,
    }

    const additionalToolbarInternalActionsBefore = useCallback(
      () => additionalToolbarInternalActionsAfter,
      [additionalToolbarInternalActionsAfter]
    )

    const requestParams = {
      pageNumber: 1,
      pageSize: 20,
      ...filters,
    }

    const settings = {
      isShowFilter: false,
      isMultipleSorting: true,
      isShowTotalCount: true,
      isGetTotal: true,
    }

    return (
      <TableQueryWrapper
        apiRequestFn={apiRequestFn}
        requestParams={requestParams}
        observableDependencies={[triggers]}
        responseMapper={responseMapper}
        settings={settings}
        tableProps={{
          columns: columnLabels,
          initialState: initialState,
          tableId,
          additionalToolbarInternalActionsBefore:
            additionalToolbarInternalActionsBefore,
          tableStates: {
            columnPinning: tableSettingsStateGroup.columnPinning,
            globalFilter: filters.generalSearch,
          },
          tableSettingsStateGroup: tableSettingsStateGroup,
          isAllowFetch: true,
          tableContainerSx: {
            maxHeight: maxHeight || 'calc(100vh - 290px - 160px)',
            '&::-webkit-scrollbar': {
              width: '16px',
            },
          },
          rootTableProps: {
            renderEmptyRowsFallback: () => <EmptyTableFallback />,
            onGlobalFilterChange: onSearchChange,
            onColumnPinningChange: (update) => {
              tableSettingsStateGroup.onColumnPinningChange({
                tableId,
                columnPinning:
                  typeof update === 'function'
                    ? update(tableSettingsStateGroup.columnPinning)
                    : update,
              })
            },
            enableGlobalFilter: !hideAllFilters,
            layoutMode: 'grid',
            enableFilters: !hideAllFilters,
            enableSorting: !hideAllFilters,
            enableColumnFilters: false,
            muiTableBodyRowProps: ({ row }) => ({
              sx: {
                backgroundColor: isChecked(row.original)
                  ? 'background.default'
                  : 'white',
              },
            }),
            renderTopToolbarCustomActions: () => (
              <Stack direction={'row'} alignItems={'center'}>
                {actionToolbar}
                <FontChangeComponent
                  onChangeFont={tableSettingsStateGroup.onFontSizeChange}
                  size={tableSettingsStateGroup.fontSize}
                />
              </Stack>
            ),
            muiTableHeadCellProps: () => ({
              sx: (theme) => ({
                ...muiTableHeadCellSX(theme),
                '& span, div': {
                  fontSize: tableSettingsStateGroup.fontSize,
                },
                '& .Mui-TableHeadCell-Content-Actions .MuiButtonBase-root:first-of-type':
                  {
                    height: '18px',
                  },
              }),
            }),
            muiTablePaperProps: () => ({
              sx: (theme) => ({
                ...muiTablePaperSX(theme),
                zIndex: '9999 !important',
              }),
            }),
            muiTableBodyCellProps: () => ({
              sx: (theme) => ({
                ...muiTableBodyCellSX(theme),
                fontSize: tableSettingsStateGroup.fontSize,
                '& p': {
                  fontSize: tableSettingsStateGroup.fontSize,
                  borderColor: theme.palette.table.tableBorder,
                },
              }),
            }),
          },
        }}
      />
    )
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps)
  }
)

export default ProTable
