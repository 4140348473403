import { memo } from 'react'
import SearchColumn from 'shared/UI/SearchColumn/SearchColumn'
import { useAppSelector } from 'shared/hooks/redux'
import { ListOfKeysForFilters } from 'widgets/UsersTableAll/model/filterControllerConfig'
import { TableFiltersSelectionType } from 'widgets/UsersTableAll/model/table/types'

const UsersFilterSortType = memo(
  ({
    tableId,
    onClose,
    filterId,
    listOfFilters,
  }: {
    tableId: number
    filterId: ListOfKeysForFilters
    listOfFilters: any
    onClose: () => void
  }) => {
    const filters = useAppSelector(
      (state) => state.tableSettings.tables[tableId]?.filters
    )

    return (
      <SearchColumn
        id={listOfFilters[filterId].id}
        placeholder={listOfFilters[filterId].label}
        getCallback={listOfFilters[filterId].callback}
        path={listOfFilters[filterId].path}
        onClose={onClose}
        searchValue={
          (filters?.[
            listOfFilters[filterId]
              ?.searchValue as keyof TableFiltersSelectionType
          ] as string) || ''
        }
        ignoreListValue={
          (filters?.[
            listOfFilters[filterId]
              .ignoreListValue as keyof TableFiltersSelectionType
          ] as any) || {}
        }
        listValue={
          (filters?.[
            listOfFilters[filterId].listValue as keyof TableFiltersSelectionType
          ] as any) || {}
        }
        pickAll={
          filters?.[
            listOfFilters[filterId].pickAll as keyof TableFiltersSelectionType
          ] as any
        }
        onChangeIgnoreListValue={
          listOfFilters[filterId].onChangeIgnoreListValue
        }
        onChangeAll={listOfFilters[filterId].onChangeAll}
        onChangePickAll={listOfFilters[filterId].onChangePickAll}
        onClear={listOfFilters[filterId].onClear}
        onFilter={listOfFilters[filterId].onFilter}
        onChangeListValue={listOfFilters[filterId].onChangeListValue}
        onChangeSearchValue={listOfFilters[filterId].onChangeSearchValue}
      />
    )
  }
)

export default UsersFilterSortType
