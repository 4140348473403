import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { LinearProgress } from '@mui/material'

type LoaderProps = {
  type?: 'circular'
  size?: number
}

export default function Loader({ type, size }: LoaderProps) {
  switch (type) {
    case 'circular':
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={size} />
        </Box>
      )
    default:
      return (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )
  }
}
