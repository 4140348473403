import { fetchUserAdditionalInfo } from './userAdditionalInfoThunks'
import {
  IPagination,
  IUserAdditionalInfo,
  UserAdditionalInfo,
} from './userAdditionalTypes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserAdditionalInfoState {
  userAdditionalInfo: IUserAdditionalInfo[]
  paginationData: IPagination | null
  isLoading: boolean
  error: string
}

const initialState: UserAdditionalInfoState = {
  userAdditionalInfo: [],
  paginationData: null,
  isLoading: false,
  error: '',
}

export const userAdditionalInfoSlice = createSlice({
  name: 'user-additional-info',
  initialState,
  reducers: {
    setAdditionalInfo: (state, action) => {
      state.userAdditionalInfo = action.payload
    },
    clearError: (state) => {
      state.error = ''
    },
  },
  extraReducers: {
    [fetchUserAdditionalInfo.fulfilled.type]: (
      state,
      action: PayloadAction<UserAdditionalInfo>
    ) => {
      state.isLoading = false
      state.error = ''
      state.userAdditionalInfo = action.payload.users
      state.paginationData = action.payload.paginationData
    },
    [fetchUserAdditionalInfo.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchUserAdditionalInfo.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const userAdditionalInfoActions = userAdditionalInfoSlice.actions
export default userAdditionalInfoSlice.reducer
