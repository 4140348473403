import { Box, CircularProgress } from '@mui/material'

export const Fallback = () => {
  return (
    <Box
      width={'100%'}
      height="100%"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress size={40} style={{ color: '#1a3652' }} />
    </Box>
  )
}
