import { useState, useEffect, useRef } from 'react'
import UIModal from '../../../../shared/UI/UIModal/UIModal'
import {
  UIFlexBetweenBox,
  UIFlexEndBox,
} from '../../../../shared/UI/UIFlexBox/UIFlexBox'
//redux
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux'
//mui
import Button from '@mui/material/Button'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { useLocation } from 'react-router'
import { parse, stringify } from 'query-string'
import {
  handleUpdateSnack,
  showSnack,
} from '../../../../store/snackbar/snackbarSlice'
import UsersAssignCourse from './UsersAssignCourse'
import ParamsAssignCourse from './ParamsAssignCourse'
import GroupAssignCourse from './GroupAssignCourse'
import DialogWindow from '../../../../shared/UI/DialogWindow/DialogWindow'
import {
  CircularProgress,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
} from '@mui/material'
import { useSortingControllerAssign } from './useSortingControllerAssign'
import { fetchGroup } from '../../../../store/groups/groupsThunks'
import { BIG_DATE } from 'shared/lib/consts'
import { useBindActionCreator } from 'shared/hooks/useBindActionCreator'
import { courseActions } from 'store/courseSlice/courseSlice'
import moment from 'moment-timezone'
import { dateValidate } from 'shared/lib/dateValidate'
import SelectedUsersAssignCourse from './SelectedUsersAssignCourse'
import _ from 'lodash'
import {
  IPagination,
  IUserAdditionalInfo,
} from 'store/userAdditionlInfo/userAdditionalTypes'
import { isAxiosError } from 'axios'
import { TableFiltersType } from 'widgets/UsersTableAll/model/table/types'
import { coreUpdater } from 'shared/api/core/coreApi'
import { ModalAlreadyAdded } from 'feature/ModalAlreadyAdded'
import { getAccessControlCourse } from 'shared/api/requests/accessControlCourse'
import {
  createAppointmentGroup,
  createAppointment,
} from 'shared/api/requests/appointment'

type ModalAssignCourseProps = {
  open: boolean
  materialId: string | number
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
}

const ModalAssignCourse: React.FC<ModalAssignCourseProps> = ({
  open,
  setOpen,
  materialId,
}) => {
  const allDataRef = useRef<{
    users: IUserAdditionalInfo[]
    pagination: IPagination
    ignoredList: any[]
    selectedUsers: any[]
    checkAll: boolean
  } | null>(null)

  const [filtersToSend, setFiltersToSend] = useState<Partial<TableFiltersType>>(
    {}
  )

  const { selectedTabCourse, selectedCourse } = useAppSelector(
    (state) => state.breadCrumb
  )
  const [openSettings, setOpenSettings] = useState(false)
  const [defaultSettings, setDefaultSettings] = useState<{
    baseDate: moment.Moment
    upToDate: moment.Moment
    accessDeniedAfterExpiration: boolean
  } | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [baseDateValue, setBaseDateValue] = useState<moment.Moment>(moment())
  const [upToDateValue, setUpToDateValue] = useState<moment.Moment>(
    moment().add(1, 'day')
  )
  const [formValues, setFormValues] = useState({
    department: '',
    group: '',
  })
  const [accessDeniedAfterExpiration, setAccessDenied] = useState(false)
  const [updated, setUpdated] = useState(false)

  const location = useLocation()
  const dispatch = useAppDispatch()

  const { userAdditionalInfo } = useAppSelector(
    (state) => state.userAdditionalInfo
  )
  const { groups } = useAppSelector((state) => state.groups)
  const { updateSnack } = useAppSelector((state) => state.snackbar)

  const activeStepToUse = useAppSelector((state) => state.course.activeStep)

  const controller = useSortingControllerAssign(userAdditionalInfo, groups)

  const [openAssigned, setOpenAssigned] = useState(false)
  const [alrAssigned, setAlrAssigned] = useState([])

  const [activeStep, setActiveStep] = useBindActionCreator(
    activeStepToUse,
    courseActions.setActiveStepRed
  )

  const steps = [
    {
      id: 0,
      name: 'Группы',
    },
    {
      id: 1,
      name: 'Пользователи',
    },
    {
      id: 2,
      name: 'Параметры',
    },
  ]

  const cancelSettings = () => {
    if (defaultSettings === null) return
    setBaseDateValue(moment(defaultSettings?.baseDate))
    setUpToDateValue(moment(defaultSettings?.upToDate))
    setAccessDenied(defaultSettings?.accessDeniedAfterExpiration)
    setUpdated(false)
    setOpenSettings(false)
  }

  const saveDecorator = () => {
    setUpdated(false)
    setOpenSettings(false)
    handleSave(false)
  }

  const onOk = () => {
    setOpen(false)
    setOpenAssigned(false)
  }

  const handleChangeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }
  const handleSave = async (checkUpdate: boolean = updated) => {
    if (checkUpdate) {
      setOpenSettings(true)
      return
    }

    const validate = dateValidate({
      startDate: baseDateValue,
      endDate: upToDateValue,
      accessDeniedAfterExpiration,
    })

    if (validate !== true) {
      dispatch(showSnack(validate))

      return
    }

    setIsLoading(true)

    try {
      if (!allDataRef.current) return
      const { selectedUsers, ignoredList, checkAll } = allDataRef.current

      let resGroup
      if (controller.assigneeGroup.length >= 1) {
        resGroup = await createAppointmentGroup({
          materialId: [Number(materialId)],
          groupId: controller.assigneeGroup,
          baseDate: baseDateValue.clone().utc().format(),
          upToDate: upToDateValue.clone().utc().format(),
          accessDeniedAfterExpiration,
        })
      }

      if (selectedUsers.length === 0 && !checkAll) {
        dispatch(
          showSnack({
            messageSnack: 'Выберите пользователей',
            typeSnack: 'warning',
          })
        )
        setIsLoading(false)
        return
      }

      const res = await createAppointment({
        materialIds: [Number(materialId)],
        userId: checkAll ? [] : selectedUsers.map((user) => user.userId),
        baseDate: baseDateValue.clone().utc().format(),
        upToDate: upToDateValue.clone().utc().format(),
        accessDeniedAfterExpiration,
        byFilter: checkAll,
        ignoredUserIds: ignoredList.map((user) => user.userId),
        filters: filtersToSend,
      })

      if (isAxiosError(res)) {
        dispatch(
          showSnack({
            messageSnack: 'Что-то пошло не так',
            typeSnack: 'error',
          })
        )
        setIsLoading(false)
      }

      const noAppointmentUsers = resGroup
        ? res.data.data.noAppointUsers.concat(resGroup.data.data.noAppointUsers)
        : res.data.data.noAppointUsers

      coreUpdater.appointmentUsers()
      setIsLoading(false)
      dispatch(showSnack({ messageSnack: 'Пользователи успешно назначены!' }))
      dispatch(handleUpdateSnack(!updateSnack))
      if (noAppointmentUsers.length > 0) {
        setAlrAssigned(noAppointmentUsers)
        setOpenAssigned(true)
      } else {
        setOpen(!open)
      }
    } catch (error: any) {
      dispatch(
        showSnack({
          messageSnack: error.response.data[0].status,
          typeSnack: 'error',
        })
      )
      setOpen(!open)
    }
  }

  const handleBackStep = () => {
    if (activeStep <= 0) {
      setOpen(false)
    }
    setActiveStep(activeStep - 1)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    dispatch(fetchGroup({}))
  }, [showSnack])

  useEffect(() => {
    getAccessControlCourse({
      materialId: materialId,
    }).then((resAccessControl) => {
      const baseDateMoment = resAccessControl.data.data.baseDate
        ? moment(resAccessControl.data.data.baseDate).clone()
        : moment().clone()
      const upToDateMoment = moment(resAccessControl.data.data.upToDate).clone()
      const diff = upToDateMoment.diff(BIG_DATE, 'years')
      const tomorrowDay = moment().add(1, 'day')
      const isTomorrow = resAccessControl.data.data.upToDate ? diff > -1 : true

      setBaseDateValue(baseDateMoment)
      setUpToDateValue(isTomorrow ? tomorrowDay : upToDateMoment)
      setAccessDenied(resAccessControl.data.data.accessDeniedAfterExpiration)
      setDefaultSettings({
        baseDate: baseDateMoment,
        upToDate: isTomorrow ? tomorrowDay : upToDateMoment,
        accessDeniedAfterExpiration:
          resAccessControl.data.data.accessDeniedAfterExpiration,
      })
    })
  }, [])

  useEffect(() => {
    if (!open) {
      allDataRef.current = null
      setActiveStep(1)
    }
  }, [open])

  const CourseStepContent = () => {
    switch (activeStep) {
      case 0: {
        return <GroupAssignCourse controller={controller} />
      }

      case 1: {
        const curParams = parse(location.search)
        const prevStr = stringify({ ...curParams, tab: selectedTabCourse })
        const prevUrl = `${location.pathname}?${prevStr}`
        return (
          <UsersAssignCourse
            allDataRef={allDataRef}
            getSave={(user: unknown) => user}
            multisearch={true}
            maxHeight={'calc(100vh - 360px)'}
            prevUrl={prevUrl}
            onFiltersChange={(filters: Partial<TableFiltersType>) =>
              setFiltersToSend(filters)
            }
          />
        )
      }
      case 2:
        return (
          <>
            <ParamsAssignCourse
              accessDeniedAfterExpiration={accessDeniedAfterExpiration}
              setAccessDenied={setAccessDenied}
              handleChangeValues={handleChangeValues}
              baseDateValue={baseDateValue}
              setBaseDateValue={setBaseDateValue}
              upToDateValue={upToDateValue}
              setUpToDateValue={setUpToDateValue}
              updated={updated}
              setUpdated={setUpdated}
              cancelSettings={cancelSettings}
            />
            <Divider sx={{ my: 2 }} />
            <SelectedUsersAssignCourse
              loading={isLoading}
              allDataRef={allDataRef}
              selectedUsers={allDataRef.current?.selectedUsers || []}
              checkAll={allDataRef.current?.checkAll}
              ignoredList={allDataRef.current?.ignoredList}
            />
          </>
        )
    }
  }

  return (
    <DialogWindow fullScreen open={open} setOpen={setOpen}>
      {selectedCourse?.name && (
        <DialogTitle>Курс «{selectedCourse?.name}»</DialogTitle>
      )}
      <UIModal
        open={openSettings}
        setOpen={setOpenSettings}
        title={'Параметры назначения были изменены!'}
        minHeight="150px"
      >
        <Stack direction={'row'} spacing={2} justifyContent="center">
          <Button variant="contained" onClick={saveDecorator}>
            Продолжить
          </Button>
          <Button variant="outlined" onClick={cancelSettings}>
            Отменить изменения
          </Button>
        </Stack>
      </UIModal>
      <ModalAlreadyAdded
        open={openAssigned}
        setOpen={setOpenAssigned}
        added={alrAssigned}
        onOk={onOk}
      />
      <DialogContent>
        <UIFlexBetweenBox alignItems={'center'}>
          <Stepper
            sx={{ mb: 2, width: '70%' }}
            nonLinear
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((step) => (
              <Step onClick={() => setActiveStep(step.id)} key={step.id}>
                <StepButton>{step.name}</StepButton>
              </Step>
            ))}
          </Stepper>
          <UIFlexEndBox alignItems={'center'} sx={{ mb: 2 }}>
            <Button
              onClick={handleClose}
              sx={{ mr: 2 }}
              size="large"
              variant="text"
            >
              Отмена
            </Button>
            <Button
              onClick={handleBackStep}
              sx={{ mr: 2 }}
              size="large"
              variant="outlined"
            >
              Назад
            </Button>
            {activeStep === 2 ? (
              <Button
                onClick={() => handleSave()}
                size="large"
                variant="contained"
                disabled={isLoading}
              >
                Сохранить
                {isLoading && (
                  <CircularProgress color="info" size={24} sx={{ ml: 2 }} />
                )}
              </Button>
            ) : (
              <Button
                onClick={() => setActiveStep(activeStep + 1)}
                size="large"
                variant="contained"
              >
                Далее
              </Button>
            )}
          </UIFlexEndBox>
        </UIFlexBetweenBox>

        {CourseStepContent()}
      </DialogContent>
    </DialogWindow>
  )
}

export default ModalAssignCourse
