import Button from '@mui/material/Button'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

type AddButtonProps = {
  children: React.ReactNode
  onClick?: any
}

const AddButton: React.FC<AddButtonProps> = ({ children, onClick }) => {
  return (
    <Button
      sx={{ ml: 1, mr: 1 }}
      id="createFolder"
      size="large"
      onClick={onClick}
      endIcon={<AddCircleOutlineIcon fontSize="small" />}
      variant="contained"
    >
      {children}
    </Button>
  )
}

export default AddButton
