import { MouseEvent, memo, useEffect, useMemo, useState } from 'react'
import { useGetAppointmentUsersQuery } from '../model/appointmentUsersApi'
import { AppointmentUserElement } from '../model/types'
import {
  Box,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  Stack,
  Table,
  TableHead,
  TableContainer,
  Paper,
} from '@mui/material'
import { UIPagination } from 'shared/UI/UIPagination/UIPagination'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { omit } from 'lodash'
import moment from 'moment'
import { useAppSelector } from 'shared/hooks/redux'
import Loader from 'shared/UI/Loader/Loader'

export type SelectedAppointmentUsers = Record<
  AppointmentUserElement['id'],
  AppointmentUserElement
>

export type AppointmentUsersTableData = {
  selected: SelectedAppointmentUsers
  ignore: SelectedAppointmentUsers
  selectedLength: number
  ignoreLength: number
  all: boolean
}

interface AppointmentUsersTableProps {
  materialId: string | number
  clearTrigger?: boolean
  onRowClick?: (row: AppointmentUserElement) => void
  onClickUserName?: (row: AppointmentUserElement) => void
  onSelectedChange?: (data: AppointmentUsersTableData) => void
}

export const AppointmentUsersTable = memo(
  ({
    materialId,
    clearTrigger,
    onRowClick,
    onClickUserName,
    onSelectedChange,
  }: AppointmentUsersTableProps) => {
    const rowPageCount = useAppSelector(
      (state) => state.breadCrumb.rowPageCount
    )
    const [pageNumber, setPageNumber] = useState(0) // потому что таблица ведет отсчет от 0 а не от 1, в запросе добавляем +1

    const { data, isLoading } = useGetAppointmentUsersQuery({
      materialId,
      pageNumber: pageNumber + 1,
      pageSize: rowPageCount,
    })

    const appointmentUsers = data?.data.appointment

    const [mount, setMount] = useState(false)

    const [selected, setSelected] = useState<
      Record<AppointmentUserElement['id'], AppointmentUserElement>
    >({})

    const [ignore, setIgnore] = useState<
      Record<AppointmentUserElement['id'], AppointmentUserElement>
    >({})

    const [all, setAll] = useState(false)

    const onSelect = (elm: AppointmentUserElement) => {
      if (all) {
        setIgnore(omit(ignore, elm.id))
      }

      setSelected({ ...selected, [elm.id]: elm })
    }

    const onDeselect = (elm: AppointmentUserElement) => {
      if (all) {
        setIgnore({ ...ignore, [elm.id]: elm })
      }

      const newSelected = omit(selected, elm.id)
      setSelected(newSelected)
    }

    const onSelectAll = (state: boolean) => {
      if (state) {
        const newSelected: Record<
          AppointmentUserElement['id'],
          AppointmentUserElement
        > = {}
        appointmentUsers?.forEach((elm) => (newSelected[elm.id] = elm))

        setSelected(newSelected)
        setIgnore({})
        setAll(true)
        return
      }

      setIgnore({})
      setSelected({})
      setAll(false)
    }

    const onUserClick = (
      ev: MouseEvent<HTMLDivElement>,
      elm: AppointmentUserElement
    ) => {
      ev.stopPropagation()
      onClickUserName?.(elm)
    }

    const formatDate = (row: any) => {
      const baseDate = moment(row.baseDate).format('DD.MM.yyyy HH:mm')
      const upToDate = moment(row.upToDate).format('DD.MM.yyyy HH:mm')

      return baseDate + ' - ' + upToDate
    }

    const selectedLength = useMemo(
      () => Object.keys(selected).length,
      [selected]
    )
    const ignoreLength = useMemo(() => {
      const ignoreListLength = Object.keys(ignore).length

      if (ignoreListLength === data?.data.totalCount) {
        onSelectAll(false)
      }

      return ignoreListLength
    }, [ignore])

    useEffect(() => {
      if (mount) {
        setSelected({})
        setIgnore({})
        setAll(false)
        return
      }
      setMount(true)
    }, [clearTrigger])

    useEffect(() => {
      onSelectedChange?.({
        selected,
        ignore,
        all,
        selectedLength,
        ignoreLength,
      })
    }, [selected, ignore, all])

    return (
      <>
        <TableContainer component={Paper}>
          {isLoading && <Loader />}
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                    indeterminate={!all ? selectedLength > 0 : ignoreLength > 0}
                    checked={all}
                    onChange={(_, checked) => onSelectAll(checked)}
                  />
                  Имя пользователя
                </TableCell>
                <TableCell align="left">Срок</TableCell>
                <TableCell align="left">Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointmentUsers && appointmentUsers.length >= 1 ? (
                appointmentUsers.map((row, index: number) => {
                  const isItemSelected = Boolean(selected[row.id])

                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover={!isLoading}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      onClick={() => !isLoading && onRowClick?.(row)}
                      selected={isItemSelected}
                      sx={{
                        cursor: isLoading ? 'default' : 'pointer',
                        transition: 'background .2s linear',
                        background: isLoading ? 'rgba(0,0,0,0.07)' : '',
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': {
                          backgroundColor: isLoading ? '' : '#f6f6f7',
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Box sx={{ display: 'flex' }}>
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(ev) =>
                              isItemSelected ? onDeselect(row) : onSelect(row)
                            }
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                          <AccountBoxIcon
                            fontSize="large"
                            color="disabled"
                            sx={{ mr: 2 }}
                          />
                          <Box
                            sx={{
                              display: 'inline',
                              '&:hover': { color: 'primary.dark' },
                            }}
                            onClick={(e) => onUserClick(e, row)}
                          >
                            {row.fullName}
                            <Typography sx={{ color: '#6F7582' }} fontSize={12}>
                              {row.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{formatDate(row)}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <Typography sx={{ p: 2 }} fontWeight={600} fontSize={16}>
                  {!isLoading && 'Пользователи на этот курс не назначены'}
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack spacing={2}>
          <UIPagination
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            total={data?.data.totalCount}
          />
        </Stack>
      </>
    )
  }
)
