import { useState, useEffect, ReactElement, memo } from 'react'

import { UIFlexStartBox } from '../../../../shared/UI/UIFlexBox/UIFlexBox'

//mui
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import UIMenu from '../../../../shared/UI/UIMenu/UIMenu'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux'
import { authUser } from '../../../../store/auth/authThunks'
import { selectAuthUser } from '../../../../store/auth/authSelector'
import { userPortal } from '../../../../shared/configs/env.config'
import { logoutUser, loginUser } from 'shared/api/requests/auth'

interface ILogoutMenu {
  items: {
    name: string | ReactElement
    onClick: () => void
  }[]
}

const UserInfo = () => {
  const dispatch = useAppDispatch()

  const AuthUser = useAppSelector(selectAuthUser)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickUIMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    dispatch(authUser())
  }, [])

  const logoutMenu: ILogoutMenu = {
    items: [
      {
        name: (
          <Box>
            <Typography variant="h1" fontSize={14} fontWeight={400}>
              {AuthUser?.fullName}
            </Typography>
            <Typography fontSize={18} color="text.disabled" fontWeight={400}>
              {AuthUser?.email}
            </Typography>
          </Box>
        ),
        onClick: () => {},
      },
      {
        name: 'Перейти на пользовательский портал',
        onClick: () => {
          window.open(userPortal)
        },
      },
      {
        name: 'Выйти',
        onClick: async () => {
          await logoutUser()
          await loginUser()
          // await loginUserOpenPopup()
        },
      },
    ],
  }

  return (
    <>
      <UIFlexStartBox>
        <IconButton onClick={handleClickUIMenu} sx={{ mr: 2 }}>
          <Avatar alt="avatar" />
        </IconButton>
      </UIFlexStartBox>
      <UIMenu
        selectedMenuContent={logoutMenu}
        handleClose={handleClose}
        open={openMenu}
        anchorEl={anchorEl}
      />
    </>
  )
}

export default memo(UserInfo)
