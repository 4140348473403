import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import {
  onCloseSnackbar,
  showSnack,
} from '../../../store/snackbar/snackbarSlice'

const CustomSnackBar = () => {
  const dispatch = useAppDispatch()
  const { openSnack, messageSnack, typeSnack } = useAppSelector(
    (state) => state.snackbar
  )

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnack}
        autoHideDuration={4000}
        onClose={() => {
          dispatch(onCloseSnackbar())
        }}
      >
        <Alert
          onClose={() => {
            dispatch(onCloseSnackbar())
          }}
          severity={typeSnack}
          sx={{ width: '100%' }}
        >
          {messageSnack}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CustomSnackBar
