import { lazy } from 'react'
import { RouteObject, Navigate } from 'react-router'

const CourseTabsPageAsync = lazy(
  () => import('../../pages/CourseTabsPage/CourseTabsPage')
)
const EducationalMaterialsPageAsync = lazy(
  () => import('../../pages/EducationalMaterialsPage/EducationalMaterialsPage')
)

const UsersManagementPageAsync = lazy(
  () => import('../../pages/UsersManagementPage/UsersManagementPage')
)
const GroupPageAsync = lazy(() => import('../../pages/GroupPage/GroupPage'))
const SettingsPageAsync = lazy(
  () => import('../../pages/SettingsPage/SettingsPage')
)
const UserInformationPageAsync = lazy(
  () => import('../../pages/UserInformationPage/UserInformationPage')
)
const DivisionPageAsync = lazy(
  () => import('../../pages/DivisionPage/DivisionPage')
)
const SelfRegistrationAsync = lazy(
  () => import('../../pages/SelfRegistrationPage/SelfRegistration')
)
const MeropriyatiyaTabsPageAsync = lazy(
  () => import('../../pages/MeropriyatiyaTabsPage/MeropriyatiyaTabsPage')
)
const ReportsPageAsync = lazy(
  () => import('../../pages/ReportsPage/ReportsPage')
)
const QuestionsAnswersPageAsync = lazy(
  () => import('../../pages/QuestionsAnswersPage/QuestionsAnswersPage')
)
const UserProfileSettingsPageAsync = lazy(
  () => import('../../pages/UserProfileSettingsPage/UserProfileSettingsPage')
)
const CourseResultUserAsync = lazy(
  () => import('pages/CourseResultUser/CourseResultUser')
)
const MeropriyatiyaPageAsync = lazy(
  () => import('../../pages/MeropriyatiyaPage/MeropriyatiyaPage')
)
const NotificationSettingsPageAsync = lazy(
  () => import('../../pages/NotificationSettingsPage/NotificationSettingsPage')
)
const TestResultAsync = lazy(() => import('pages/TestResult/TestResult'))

const LearnerProgressPageAsync = lazy(
  () => import('../../pages/LearnerProgressPage/LearnerProgressPage')
)
const KnowledgebasePageAsync = lazy(
  () => import('../../pages/KnowledgebasePage/KnowlegebasePage') as any
)

const RegulatoryDocumentsPageAsync = lazy(
  () => import('../../pages/RegulatoryDocumentsPage/RegulatoryDocumentsPage')
)

const UsefulMaterialsPageAsync = lazy(
  () => import('../../pages/UsefulMaterialsPage/UsefulMaterialsPage')
)

const EducationalMaterialsSubPageAsync = lazy(
  () =>
    import(
      '../../pages/EducationalMaterialsSubPage/EducationalMaterialsSubPage'
    )
)

const AssignmentsPageAsync = lazy(
  () => import('../../pages/AssignmentsPage/AssignmentsPage')
)

const TrainingApplicationsPageAsync = lazy(
  () => import('../../pages/TrainingApplicationsPage/TrainingApplicationsPage')
)

const CoursesQuestionsPageAsync = lazy(
  () => import('../../pages/CoursesQuestionsPage/CoursesQuestionsPage')
)

const MessagesPageAsync = lazy(
  () => import('../../pages/MessagesPage/MessagesPage')
)

const FeedbackPageAsync = lazy(
  () => import('../../pages/FeedbackPage/FeedbackPage')
)

const NewsPageAsync = lazy(() => import('../../pages/NewsPage/NewsPage'))

const NewsFeedPageAsync = lazy(
  () => import('../../pages/NewsFeedPage/NewsFeedPage')
)

const BannersPageAsync = lazy(
  () => import('../../pages/BannersPage/BannersPage')
)

const MaterialStoragePageAsync = lazy(
  () => import('../../pages/MaterialStoragePage/MaterialStoragePage')
)

export const  privateRoutes: RouteObject[] = [
  // {
  //   path: '/',
  //   element: <MainPage />,
  // },
  {
    path: 'education',
    element: <EducationalMaterialsPageAsync />,
  },
  {
    path: 'course-tabs/:materialId',
    element: <CourseTabsPageAsync />,
  },
  {
    path: 'meropriyatya-tabs',
    element: <MeropriyatiyaTabsPageAsync />,
  },
  {
    path: 'user-management',
    element: <UsersManagementPageAsync />,
  },
  {
    path: 'meropriyatiya',
    element: <MeropriyatiyaPageAsync />,
  },
  {
    path: 'knowledgebase',
    element: <KnowledgebasePageAsync />,
  },
  {
    path: 'knowledgebase/regulatory-documents',
    element: <RegulatoryDocumentsPageAsync />,
  },
  {
    path: 'knowledgebase/useful-materials',
    element: <UsefulMaterialsPageAsync />,
  },
  {
    path: 'knowledgebase/educational-materials',
    element: <EducationalMaterialsSubPageAsync />,
  },
  {
    path: 'questions-answers',
    element: <QuestionsAnswersPageAsync />,
  },
  {
    path: 'questions-answers/assignments',
    element: <AssignmentsPageAsync />,
  },
  {
    path: 'questions-answers/training-applications',
    element: <TrainingApplicationsPageAsync />,
  },
  {
    path: 'questions-answers/courses-questions',
    element: <CoursesQuestionsPageAsync />,
  },
  {
    path: 'questions-answers/messages',
    element: <MessagesPageAsync />,
  },
  {
    path: 'questions-answers/feedback',
    element: <FeedbackPageAsync />,
  },
  {
    path: 'news',
    element: <NewsPageAsync />,
  },
  {
    path: 'news/news-feed',
    element: <NewsFeedPageAsync />,
  },
  {
    path: 'news/banners',
    element: <BannersPageAsync />,
  },
  {
    path: 'group',
    element: <GroupPageAsync />,
  },
  {
    path: 'division/:divisionId',
    element: <DivisionPageAsync />,
  },
  {
    path: 'settings',
    element: <SettingsPageAsync />,
  },
  {
    path: 'reports',
    element: <ReportsPageAsync />,
  },
  {
    path: 'user-management/user-information',
    element: <UserInformationPageAsync />,
  },
  {
    path: 'user-management/user-information/:userId',
    element: <CourseResultUserAsync />,
  },
  {
    path: 'user-management/user-information/:userId/test/:materialId/:materialContentId',
    element: <TestResultAsync />,
  },
  {
    path: 'reports/learner-progress',
    element: <LearnerProgressPageAsync />,
  },
  {
    path: 'self-registration',
    element: <SelfRegistrationAsync />,
  },
  {
    path: 'notification-settings',
    element: <NotificationSettingsPageAsync />,
  },
  {
    path: 'userprofile-settings',
    element: <UserProfileSettingsPageAsync />,
  },
  {
    path: 'material-storage',
    element: <MaterialStoragePageAsync />,
  },
  {
    path: '*',
    element: <Navigate to="/education" replace />,
  },
]
