import { useCallback, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import UserInfoPopover from './UserInfoPopover'
import UsersTableAll, {
  AdditionalInfoResponse,
} from 'widgets/UsersTableAll/UsersTableAll'
import {
  IPagination,
  IUserAdditionalInfo,
} from 'store/userAdditionlInfo/userAdditionalTypes'

const TABLE_ID = 3

const UsersAssignCourse = ({
  controller,
  type,
  allDataRef,
  hideFilters = false,
  isLastStep = false,
  defaultSelected,
  onlySelectedShow = false,
  getSave,
  defaultCheckAll,
  defaultIgnoredList,
  hideSettings,
  outLoading,
  multisearch,
  prevUrl,
  onlySelected,
  onFiltersChange,
  clearSelectedTrigger,
  maxHeight,
}: any) => {
  const [users, setUsers] = useState<IUserAdditionalInfo[]>([])
  const [pagination, setPagination] = useState<IPagination | null>(null)
  const [checkAll, setCheckAll] = useState(
    allDataRef?.current?.checkAll || false
  )
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    defaultSelected || allDataRef.current?.selectedUsers || []
  )
  const [ignoredList, setIgnoredList] = useState<string[]>([])

  const [openUserInfo, setOpenUserInfo] = useState<null | HTMLElement>(null)
  const [selectedPopoverContent, setSelectedPopoverContent] =
    useState<any>(null)

  const onSelectedChange = (
    selectedAr: string[],
    ignoredList: string[],
    checkAll: boolean
  ) => {
    setCheckAll(checkAll)
    setIgnoredList(ignoredList)
    setSelectedUsers(selectedAr)
  }

  const onAfterRequest = (
    users: IUserAdditionalInfo[],
    pagination: IPagination
  ) => {
    setUsers(users)
    setPagination(pagination)
  }

  const transformResponseRequest = useCallback(
    (data: AdditionalInfoResponse) => {
      const selectedArray: AdditionalInfoResponse['users'] =
        allDataRef?.current.selectedUsers

      const paginationNewInfo: AdditionalInfoResponse['paginationInfo'] = {
        totalCount: selectedArray.length,
        totalPages: 1,
        hasNext: false,
        hasPrevious: false,
        isFirst: false,
        isLast: false,
        page: 1,
        pageSize: selectedArray.length,
      }

      return Promise.resolve({
        users: checkAll ? data.users : selectedArray,
        paginationInfo: checkAll ? data.paginationInfo : paginationNewInfo,
      })
    },
    [allDataRef, checkAll]
  )

  useEffect(() => {
    if (allDataRef) {
      allDataRef.current = {
        users,
        pagination,
        checkAll,
        selectedUsers,
        ignoredList,
      }
    }
  }, [allDataRef, checkAll, ignoredList, pagination, selectedUsers, users])

  const isSupervisor =
    type === 'addSupervisor' || type === 'addSupervisorToDivision'

  return (
    <>
      {!isLastStep && (
        <Typography fontWeight="bold" sx={{ mb: 3 }}>
          {isSupervisor ? 'Выберите руководителя' : 'Выберите учащихся'}
        </Typography>
      )}
      <UsersTableAll
        hideAllFilters={hideFilters}
        tableId={TABLE_ID}
        maxHeight={maxHeight}
        outLoading={outLoading}
        hideSettings={hideSettings}
        getSaveSelect={getSave}
        lazyOptions
        single={type === 'addSupervisor' || type === 'addSupervisorToDivision'}
        data={users}
        onFiltersChange={onFiltersChange}
        pagination={pagination}
        onAfterRequest={onAfterRequest}
        onSelectedChange={onSelectedChange}
        defaultSelected={defaultSelected || selectedUsers}
        onlySelectedShow={onlySelectedShow}
        transformDataAfterRequest={
          onlySelected ? transformResponseRequest : undefined
        }
        defaultCheckAll={
          defaultCheckAll || allDataRef?.current?.checkAll || false
        }
        defaultIgnoreList={
          defaultIgnoredList || allDataRef?.current?.ignoreList || []
        }
        prevUrl={prevUrl}
        onlySelected={onlySelected}
        clearSelectedTrigger={clearSelectedTrigger}
      />
      {selectedPopoverContent && (
        <UserInfoPopover
          anchorEl={openUserInfo}
          open={Boolean(openUserInfo)}
          handleClose={() => setOpenUserInfo(null)}
          content={selectedPopoverContent}
        />
      )}
    </>
  )
}

export default UsersAssignCourse
