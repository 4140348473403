export function getReminderIntervals(arr: any) {
  return arr.map((item: any) => {
    const timeInMinutes = item.reminderTime
    let unit, value

    if (timeInMinutes % 1440 === 0) {
      // Reminder time is in days
      unit = 'day'
      value = timeInMinutes / 1440
    } else if (timeInMinutes % 60 === 0) {
      // Reminder time is in hours
      unit = 'hour'
      value = timeInMinutes / 60
    } else {
      // Reminder time is in minutes
      unit = 'minute'
      value = timeInMinutes
    }

    return { ...item, unit, value }
  })
}
