import { configureStore } from '@reduxjs/toolkit'
import { isDev } from 'shared/configs/env.config'

import { rootReducer } from './reducers'
import { divisionsApi } from 'shared/api/divisions/divisionsApi'
import { coreApi } from 'shared/api/core/coreApi'
import { groupsApi } from 'shared/api/groups/groupsApi'

export const store = configureStore({
  reducer: rootReducer,
  devTools: isDev,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: [
          'payload.baseDate',
          'payload.upToDate',
          'payload.recommendedTime',
        ],
        ignoredPaths: [
          'course.selectValues.baseDate',
          'payload.baseDate',
          'course.selectValues.upToDate',
          'payload.upToDate',
          'course.information.recommendedTime',
          'materialSettings.information.recommendedTime',
        ],
      },
    }).concat([
      groupsApi.middleware,
      divisionsApi.middleware,
      coreApi.middleware,
    ]),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
