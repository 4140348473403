import React from 'react'
import { Box, Typography } from '@mui/material'
import UsersAssignCourse from './UsersAssignCourse'

const SelectedUsersAssignCourse = ({
  selectedUsers,
  checkAll,
  ignoredList,
  allDataRef,
  loading,
}: any) => {
  return (
    <Box>
      <Typography fontSize={24}>Выбранные пользователи</Typography>

      <Box mt={1}>
        <UsersAssignCourse
          allDataRef={allDataRef}
          defaultSelected={selectedUsers}
          defaultCheckAll={checkAll}
          defaultIgnoredList={ignoredList}
          outLoading={loading}
          onlySelectedShow
          hideFilters
          isLastStep
          getSave={(user: unknown) => user}
          onlySelected
        />
      </Box>
    </Box>
  )
}

export default SelectedUsersAssignCourse
