const getEnvironmentVariables = () => {
  return {
    apiUrl: process.env.REACT_APP_DEV_API_URL,
    importFileUrl: process.env.REACT_APP_DEV_IMPORT_FILE,
    scormFileUrl: process.env.REACT_APP_DEV_SCORM_FILE,
    scormParserUrl: process.env.REACT_APP_DEV_SCORM_PARSER,
    userPortal: process.env.REACT_APP_DEV_USER_PORTAL,
    isDev: true,
  } as const
}

export const {
  apiUrl,
  importFileUrl,
  scormFileUrl,
  scormParserUrl,
  userPortal,
  isDev,
} = getEnvironmentVariables()
