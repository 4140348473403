import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks/redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Typography } from '@mui/material'
import { UIFlexStartBox } from '../../../../shared/UI/UIFlexBox/UIFlexBox'

const GroupAssignCourse = ({ controller }: any) => {
  const { groups } = useAppSelector((state) => state.groups)

  const numSelected = controller.assigneeGroup.length
  const rowCount = groups?.length || 0
  return (
    <TableContainer component={Paper} sx={{ height: '85vh' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={controller.handleSelectAllClickGroups}
              />
              Название группы
            </TableCell>
            <TableCell align="left">Количество пользователей</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups?.length >= 1 ? (
            groups.map((row: any, index: number) => {
              const isItemSelected = controller.isSelectedGroup(row.id)
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  onClick={(event) =>
                    controller.handleClickEditAppointmentGroup(
                      event,
                      row,
                      isItemSelected
                    )
                  }
                  selected={isItemSelected}
                  sx={{
                    cursor: 'pointer',
                    transition: 'background .2s linear',
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': { backgroundColor: '#f6f6f7' },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: 'flex' }}>
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onClick={(ev) =>
                          controller.setAssigneeGroup([
                            ...controller.assigneeGroup,
                            row,
                          ])
                        }
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                      <UIFlexStartBox>
                        <Typography fontSize={14}>{row.name}</Typography>
                      </UIFlexStartBox>
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row.userCount}</TableCell>
                </TableRow>
              )
            })
          ) : (
            <Typography sx={{ p: 2 }} fontWeight={600} fontSize={16}>
              Пользователи на этот курс не назначены
            </Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default GroupAssignCourse
