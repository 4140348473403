import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// types
import { AUTH, IAuth, IAuthUser } from './authTypes'

const initialState: IAuth = {
  authUser: null,
}

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<IAuthUser>) => {
      state.authUser = action.payload
    },
  },
})

export default authSlice.reducer
export const { setAuthUser } = authSlice.actions
