import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetActiveContentResponse } from 'shared/api/requests/contentMaterial'
import { fetchActiveContents } from './contentThunks'
import { ContentMaterialActiveI, PaginationDataI } from './contentTypes'

interface ContentSchema {
  allContents: ContentMaterialActiveI[]
  paginationData: PaginationDataI | undefined
  loading: boolean
  error: string | undefined
}

const initialState: ContentSchema = {
  allContents: [],
  paginationData: undefined,
  loading: false,
  error: undefined,
}

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setAllContents: (
      state,
      action: PayloadAction<ContentMaterialActiveI[]>
    ) => {
      state.allContents = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActiveContents.pending, (state) => {
      state.loading = true
    })

    builder.addCase(
      fetchActiveContents.fulfilled,
      (state, action: PayloadAction<GetActiveContentResponse>) => {
        state.allContents = action.payload.materialContents
        state.paginationData = {
          page: action.payload.page,
          pageSize: action.payload.pageSize,
          totalCount: action.payload.totalCount,
          totalPages: action.payload.totalPages,
        }
        state.loading = false
      }
    )

    builder.addCase(fetchActiveContents.rejected, (state, action) => {
      state.error = action.payload as string
    })
  },
})

export const { actions: contentActions } = contentSlice
export const { reducer: contentReducer } = contentSlice
