import moment from 'moment-timezone'

export const TIME_ZONE = 'Europe/Moscow'

export const formatDateToMoscowTime = (
  date: string | Date,
  pattern?: string
) => {
  return moment(date).format(pattern || 'DD.MM.yyyy HH:mm')
}
