import { Box } from '@mui/material'
import { ButtonGroup, Button, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { blueGrey } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { Dispatch, FC, SetStateAction, memo } from 'react'

interface ITableCustomActionProps {
  // Размер выбранного размера шрифта
  size: number
  //  setState функция для изменеия размера
  onChangeFont: (fontSize: number) => void
}

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[50]),
  backgroundColor: blueGrey[50],
  borderColor: blueGrey[200],
  '&:hover': {
    backgroundColor: blueGrey[100],
    borderColor: blueGrey[300],
  },
}))

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 0,
      borderColor: blueGrey[200],
    },
    '&:hover fieldset': {
      borderColor: blueGrey[300],
    },
    '&.Mui-focused fieldset': {
      borderColor: blueGrey[500],
    },
    '& input': {
      textAlign: 'center',
      width: 60,
      color: blueGrey[700],
    },
  },
})

export const FontChangeComponent: FC<ITableCustomActionProps> = ({
  size,
  onChangeFont,
}) => {
  const handleChange = (newFontValue: string | number) => {
    const newFont = Math.max(Number(newFontValue), 1)
    if (newFont < 10 || newFont > 30) return
    onChangeFont(newFont)
  }

  return (
    <ButtonGroup>
      <StyledButton
        onClick={() => handleChange(size - 2)}
        disabled={size === 1}
        size="small"
      >
        <RemoveIcon fontSize="small" />
      </StyledButton>
      <StyledInput
        sx={{
          pointerEvents: 'none',
        }}
        size="small"
        value={`${size}т`}
      />
      <StyledButton size="small" onClick={() => handleChange(size + 2)}>
        <AddIcon fontSize="small" />
      </StyledButton>
    </ButtonGroup>
  )
}
