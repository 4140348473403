import { httpCore } from 'shared/configs/instance'
import {
  ContractorResponse,
  ContractorsProps,
  DepartmentProps,
  DepartmentResponse,
  // DivisionTypeProps,
  // DivisionTypeResponse,
  ManagerProps,
  ManagerResponse,
  RegionProps,
  RegionResponse,
  ResponseWrapper,
} from './types'

export const getDepartment = (
  data?: Partial<DepartmentProps> & Record<string, unknown>
) =>
  httpCore.post<ResponseWrapper<DepartmentResponse>>(
    'link-entity/bindings-department',
    data
  )

export const getContractor = (
  data?: Partial<ContractorsProps> & Record<string, unknown>
) =>
  httpCore.post<ResponseWrapper<ContractorResponse>>(
    'link-entity/bindings-contractor',
    data
  )
export const getManagement = (
  data?: Partial<ContractorsProps> & Record<string, unknown>
) =>
  httpCore.post<ResponseWrapper<ContractorResponse>>(
    'link-entity/regional-management',
    data
  )

  // export const getDivisionType = (
  //   data?: Partial<DivisionTypeProps> & Record<string, unknown>
  // ) =>
  //   httpCore.post<ResponseWrapper<DivisionTypeResponse>>(
  //     `division`,
  //     data
  //   );

export const getManager = (
  data?: Partial<ManagerProps> & Record<string, unknown>
) =>
  httpCore.post<ResponseWrapper<ManagerResponse>>(
    'link-entity/bindings-manager',
    data
  )

export const getRegion = (
  data?: Partial<RegionProps> & Record<string, unknown>
) =>
  httpCore.get<ResponseWrapper<RegionResponse>>('link-entity/region', {
    params: data,
  })
