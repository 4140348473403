import React, { FC } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material'
import { IOption } from 'widgets/UsersTableAll/model/table/types'

interface ISelectProps {
  value: IOption | null
  options: IOption[]
  onChangeSelect: (option: IOption | null) => void
  label: string
}

const UISelect: FC<ISelectProps> = ({
  value,
  options,
  onChangeSelect,
  label,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    const selecId = event.target.value
    const selectOption = options.find((option) => option.id === selecId) || null
    onChangeSelect(selectOption)
  }

  return (
    <>
      <FormControl
        fullWidth
        sx={{
          zIndex: 999999999,
        }}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={(value?.id as string) || ''}
          label={label}
          onChange={handleChange}
          slotProps={{
            root: {
              sx: {
                zIndex: 999999999,
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem> // Указываем ключ и значение как id опции
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default UISelect
