//уведомление

import { httpCore } from 'shared/configs/instance'

export const getNotificationTab = (data: any) =>
  httpCore.get(`notification/${data.courseId}/list-by-course`, data)

export const createNotificationTab = (data: any) =>
  httpCore.post(`notification`, data)

export const updateNotificationTab = (data: any) =>
  httpCore.put(`notification/${data.id}`, data)

//напоминания
export const getReminder = (data: any) => httpCore.get(`reminder/${data.id}`)

export const postReminder = (data: any) => httpCore.post(`reminder`, data)

//шаблоны
export const getNotificationTemplate = () =>
  httpCore.get(`notificationTemplate/list`)

export const postNotificationTemplate = (data: any) =>
  httpCore.post(`notificationTemplate`, data)

export const putNotificationTemplate = (data: any) =>
  httpCore.put(`notificationTemplate/${data.id}`, data)

export const deleteNotificationTemplate = (data: any) =>
  httpCore.delete(`notificationTemplate/${data.id}`)

export const assignNotifictionToMaterial = (data: {
  materialId: number
  notificationTemplateId: number
}) => httpCore.put(`material/notification/template`, data)

//email шаблон

export const getEmailTemplate = () => httpCore.get(`template/email`)

export const postEmailTemplate = (data: any) =>
  httpCore.post(`template/email`, data)
