import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'shared/lib/axiosBaseQuery'
import { store } from 'store/store'
import {
  ActiveMaterialElementType,
  GetActiveMaterialProps,
  PaginationParams,
  PaginationWrapperResponse,
  ProjectsGetResponse,
  ResponseTypeWrapper,
} from './types'

export const coreApi = createApi({
  reducerPath: 'appApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'MATERIAL_SETTINGS',
    'REMINDER_LIST',
    'NOTIFICATION',
    'APPOINTMENT_USERS',
    'ACTIVE_MATERIALS',
    'CHAPTER_ELEMENTS',
    'ACTIVE_PROJECTS',
  ],
  endpoints: (build) => ({
    getActiveMaterials: build.query<
      ResponseTypeWrapper<
        PaginationWrapperResponse & {
          materials: ActiveMaterialElementType[]
        } & {
          parentName: string
        }
      >,
      GetActiveMaterialProps
    >({
      query: (params) => ({
        url: 'material/active',
        method: 'get',
        params,
      }),

      providesTags: ['ACTIVE_MATERIALS'],
    }),

    getActiveProjects: build.query<
      ResponseTypeWrapper<ProjectsGetResponse>,
      PaginationParams | void
    >({
      query: (params) => ({
        url: 'project/active',
        method: 'get',
        params,
      }),
      providesTags: ['ACTIVE_PROJECTS'],
    }),
  }),
})

export const { useGetActiveMaterialsQuery, useGetActiveProjectsQuery } = coreApi

export const coreUpdater = {
  materialSettings: () =>
    store.dispatch(coreApi.util.invalidateTags(['MATERIAL_SETTINGS'])),
  reminderList: () =>
    store.dispatch(coreApi.util.invalidateTags(['REMINDER_LIST'])),
  notification: () =>
    store.dispatch(coreApi.util.invalidateTags(['NOTIFICATION'])),
  appointmentUsers: () =>
    store.dispatch(coreApi.util.invalidateTags(['APPOINTMENT_USERS'])),
  activeMaterials: () =>
    store.dispatch(coreApi.util.invalidateTags(['ACTIVE_MATERIALS'])),
  chapter: () =>
    store.dispatch(coreApi.util.invalidateTags(['CHAPTER_ELEMENTS'])),
  projects: () =>
    store.dispatch(coreApi.util.invalidateTags(['ACTIVE_PROJECTS'])),
}
