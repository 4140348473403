import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchUsersProfile } from './userProfileThunks'
import { IUserProfile, PaginationProps, UsersProfile } from './userProfileTypes'

interface UserProfileState {
  userProfiles: IUserProfile[]
  pagination: PaginationProps | null
  isLoading: boolean
  error: string
}

const initialState: UserProfileState = {
  userProfiles: [],
  pagination: null,
  isLoading: false,
  error: '',
}

export const userProfileSlice = createSlice({
  name: 'user-profile',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUsersProfile.fulfilled.type]: (
      state,
      action: PayloadAction<UsersProfile>
    ) => {
      state.isLoading = false
      state.error = ''
      state.userProfiles = action.payload.userProfiles
      state.pagination = action.payload.pagination
    },
    [fetchUsersProfile.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchUsersProfile.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const userProfileReducer = userProfileSlice.reducer
