import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const UserInfoPopover = ({ anchorEl, open, handleClose, content }: any) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography>
          Контрагент:{' '}
          <Typography display="inline" color="#0288d1">
            {content.contractors?.length >= 1
              ? content.contractors[0].contractorName
              : 'Нет контрагентов'}
          </Typography>
        </Typography>
        <Typography>
          {' '}
          ИНН контрагента:{' '}
          <Typography display="inline" color="#0288d1">
            {content.contractors?.length >= 1
              ? content.contractors[0].contractorTin
              : 'Нет контрагентов'}
          </Typography>
        </Typography>
        <Typography>
          Менеджер:{' '}
          <Typography display="inline" color="#0288d1">
            {content.managers?.length >= 1
              ? content.managers[0].managerName
              : 'Нет менеджера'}
          </Typography>
        </Typography>
        <Typography>
          Подразделение / Код АУ:
          <Typography display="inline" color="#0288d1">
            {content.departments?.length >= 1
              ? content.departments[0].departmentName
              : 'Нет подразделений'}
          </Typography>
        </Typography>
        <Typography>
          Адрес подразделения:{' '}
          <Typography display="inline" color="#0288d1">
            {content.departments?.length >= 1
              ? content.departments[0].departmentAddress
              : 'Нет адреса подразделения'}
          </Typography>
        </Typography>
        <Typography>
          Регион:{' '}
          <Typography display="inline" color="#0288d1">
            {content.regions?.length >= 1
              ? content.regions[0].regionName
              : 'Нет региона'}
          </Typography>
        </Typography>
        <Typography>
          Группы:{' '}
          <Typography display="inline" color="#0288d1">
            {content.groups?.length >= 1
              ? content.groups.map((item: any) => (
                  <Typography display="inline" color="#0288d1">
                    {item.groupName}
                  </Typography>
                ))
              : 'Нет групп'}
          </Typography>
        </Typography>
        <Typography>
          Номер телефона:{' '}
          <Typography display="inline" color="#0288d1">
            {content.userPhone ? content.userPhone : 'Нет телефона'}
          </Typography>
        </Typography>
      </Box>
    </Popover>
  )
}

export default UserInfoPopover
