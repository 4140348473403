import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  fetchByContentMaterials,
  fetchLearnMaterials,
} from './learnMaterialThunks'
import ILearnMaterial from './learnMaterialsTypes'

interface LearnState {
  learnMaterials: ILearnMaterial[]
  pageMaterial: number
  pageSizeMaterial: number
  totalPagesMaterial: number
  totalCountMaterial: number
  isLoading: boolean
  error: string
}

const initialState: LearnState = {
  learnMaterials: [],
  pageMaterial: 0,
  pageSizeMaterial: 20,
  totalPagesMaterial: 1,
  totalCountMaterial: 0,
  isLoading: false,
  error: '',
}

export const learnMaterialsSlice = createSlice({
  name: 'learn-materials',
  initialState,
  reducers: {
    setLearnMaterialsRed: (state, action) => {
      state.learnMaterials = action.payload
    },
  },
  extraReducers: {
    [fetchLearnMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<{
        materials: ILearnMaterial[]
        page: number
        pageSize: number
        totalPages: number
        totalCount: number
      }>
    ) => {
      state.isLoading = false
      state.error = ''
      state.learnMaterials = action.payload.materials
      state.pageMaterial = action.payload.page
      state.pageSizeMaterial = action.payload.pageSize
      state.totalPagesMaterial = action.payload.totalPages
      state.totalCountMaterial = action.payload.totalCount
    },
    [fetchLearnMaterials.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchLearnMaterials.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
      state.learnMaterials = []
      state.pageMaterial = 0
      state.totalPagesMaterial = 0
      state.totalCountMaterial = 0
    },

    [fetchByContentMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<{
        materials: ILearnMaterial[]
        page: number
        pageSize: number
        totalPages: number
        totalCount: number
      }>
    ) => {
      state.isLoading = false
      state.error = ''
      state.learnMaterials = action.payload.materials
      state.pageMaterial = action.payload.page
      state.pageSizeMaterial = action.payload.pageSize
      state.totalPagesMaterial = action.payload.totalPages
      state.totalCountMaterial = action.payload.totalCount
    },
    [fetchByContentMaterials.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchByContentMaterials.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
      state.learnMaterials = []
      state.pageMaterial = 0
      state.totalPagesMaterial = 0
      state.totalCountMaterial = 0
    },
  },
})

export const { actions: learnMaterialsActions } = learnMaterialsSlice
export default learnMaterialsSlice.reducer
