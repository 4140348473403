import { FC, forwardRef } from 'react'
import DatePicker from 'react-datepicker'
import { TextField, styled } from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import { customTheme } from 'shared/configs/theme'

const days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
const months = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

const russianLocale = {
  localize: {
    day: (n: number) => days[n],
    month: (n: number) => months[n],
  },
  formatLong: {
    date: () => 'DD.MM.YYYY',
  },
}
const format = 'dd/MM/yyyy'
const lastDayOfCentury = new Date(2999, 11, 31)

function formatDateString(inputDate: string): string {
  let resultValue = ''

  const isLetter = /[^0-9\/]/
  const correctDaysOrMonth = /^(\d{2})/
  const correctMonth = /^(0[1-9]|1[0-2])$/
  const correctYear = /^(\d{4})/

  if (inputDate.endsWith('/')) {
    return inputDate.slice(0, -1)
  }

  if (isLetter.test(inputDate)) return ''

  const splittedValue = inputDate.split('/')

  if (inputDate.length >= 10) {
    inputDate = inputDate.slice(0, 10)
    return inputDate
  }

  const days = splittedValue[0]
  const month = splittedValue[1]
  const year = splittedValue[2]

  const isCorrectDay = correctDaysOrMonth.test(days)
  const isCorrectMonth = correctMonth.test(month)
  const isCorrectYear = correctYear.test(year)

  if (inputDate.length === 2 && isCorrectDay) {
    resultValue = `${days}/`
    return resultValue
  }

  if (inputDate.length === 5 && isCorrectDay && isCorrectMonth) {
    resultValue = `${days}/${month}/`
    return resultValue
  }

  if (inputDate.length === 5 && isCorrectDay && !isCorrectMonth) {
    resultValue = `${days}/${12}/`
    return resultValue
  }

  if (
    inputDate.length <= 10 &&
    isCorrectDay &&
    isCorrectMonth &&
    isCorrectYear
  ) {
    resultValue = `${days}/${month}/${year}`
    return resultValue
  }

  return inputDate
}

const Wrapper = styled('div')({
  display: 'flex',
  gap: '12px',
  width: '100%',
  justifyContent: 'center',
  '& .react-datepicker__day': {
    borderRadius: '8px',
  },
  '& .react-datepicker__day--selected': {
    backgroundColor: customTheme.palette.primary.main,
  },
  '& .react-datepicker__close-icon::after': {
    fontSize: '24px',
    backgroundColor: 'transparent',
    color: '#000',
  },
  '& .react-datepicker__header': {
    backgroundColor: customTheme.palette.background.default,
  },
  '& .react-datepicker__close-icon': {
    '&::after': {
      backgroundColor: 'none',
    },
  },
  '& .react-datepicker__day--range-start': {
    backgroundColor: customTheme.palette.primary.main,
  },
  '& .react-datepicker__tab-loop': {
    position: 'absolute',
  },
})
const CustomInput = forwardRef((props: any, ref) => {
  const { value, ...otherProps } = props

  const formattedValue = formatDateString(value)

  return (
    <TextField size="small" value={formattedValue} {...otherProps} ref={ref} />
  )
})

interface IRangePickerProps {
  onStartChange: (date: Date | null) => void
  onEndChange: (date: Date | null) => void
  startValue: Date | null
  endValue: Date | null
}

const RangePicker2: FC<IRangePickerProps> = (props) => {
  const { startValue, endValue, onStartChange, onEndChange } = props

  return (
    <Wrapper>
      <DatePicker
        selected={startValue}
        onChange={(date) => onStartChange(date)}
        selectsStart
        startDate={startValue}
        endDate={endValue}
        customInput={<CustomInput />}
        dateFormat={format}
        isClearable
        maxDate={endValue || lastDayOfCentury}
        // @ts-ignore
        locale={russianLocale as Locale}
        placeholderText="DD/MM/YYYY"
      />
      <DatePicker
        selected={endValue}
        onChange={(date) => onEndChange(date)}
        selectsEnd
        startDate={startValue}
        endDate={endValue}
        minDate={startValue}
        customInput={<CustomInput />}
        maxDate={lastDayOfCentury}
        isClearable
        // @ts-ignore
        locale={russianLocale as Locale}
        dateFormat={format}
        placeholderText="DD/MM/YYYY"
      />
    </Wrapper>
  )
}

export default RangePicker2
