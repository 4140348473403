import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUsersAdditionalInfo } from 'shared/api/requests/additionalInfo/getUsersAdditionalInfo'

export const fetchUserAdditionalInfoForGroup = createAsyncThunk(
  'group/fetchUserAdditionalInfo',
  async (params: any, thunkAPI) => {
    try {
      const response = await getUsersAdditionalInfo(params)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
    }
  }
)
