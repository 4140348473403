import { parse, stringify } from 'query-string'
import {
  Location,
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from 'react-router'
import { useAppSelector } from './redux'

type NavigateFunction = (to: To | number, options?: NavigateOptions) => void

export const useNavigateWrapper = (
  defaultNavBac?: string
): {
  navigate: NavigateFunction
  deleteQueryFromUrl: (keys: string[]) => void
  location: Location
} => {
  const navigate = useNavigate()
  const location = useLocation()
  const paths = useAppSelector((state) => state.pathSlice.paths)

  const customNavigate: NavigateFunction = (to, options) => {
    if (typeof to === 'number') {
      const prev1 = location.state?.prev
      const prev2 = location.state?.prev2
      const prevUrl = prev1 || prev2

      if (prevUrl) {
        const options: NavigateOptions = { replace: true, state: {} }
        if (prev2) options.state.prev = prev2

        navigate(prevUrl, options)
      }
      if (!prevUrl) {
        if (paths.length <= 1 && defaultNavBac) {
          navigate(defaultNavBac)
          return
        }
        navigate(to)
      }
    } else {
      if (options?.state?.prev && options?.replace === undefined) {
        options.replace = true
      }
      navigate(to, options)
    }
  }

  const deleteQueryFromUrl = (keys: string[]) => {
    const params = parse(location.search)
    keys.forEach((key) => delete params[key])
    const newUrl = `${location.pathname}?${stringify(params)}`
    window.history.replaceState({}, document.title, newUrl)
  }

  return { navigate: customNavigate, deleteQueryFromUrl, location }
}
