export const downloadBlob = (
  blobPart: BlobPart,
  name: string,
  extension: string
) => {
  const fileName = name + '.' + extension

  const urlDownload = window.URL.createObjectURL(new Blob([blobPart]))
  const a = document.createElement('a')
  a.href = urlDownload
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  a.remove()
}
