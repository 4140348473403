// import { DivisionType } from './linkEntity/types';
import { IDivision } from 'shared/api/divisions/types'
import { httpCore } from 'shared/configs/instance'

export const getDivision = () => httpCore.get('division')
export const getDivisionTypeList =
  (parentId?: number) => (options: Record<string, unknown>) => {
    console.log('getDivisionTypeList :: parentId', parentId)

    const baseUrl = 'division/division-type/list'
    const searchParams = parentId ? `?parentDivisionId=${parentId}` : ''

    return httpCore.get(baseUrl + searchParams)
  }
export const getDivisionByIds = (params: { ids: string[] }) =>
  httpCore.get('division/filter', { params })

export const getDivisionById = (data: any) =>
  httpCore.get(`division/${data.divisionId}`)
export const getDropDownDivision = () => httpCore.get('division/dropDown')
export const createDivision = (data: any) => httpCore.post('division', data)
export const deleteDivision = (data: any) =>
  httpCore.delete(`division/${data.divisionId}`)
export const getUsersByDivision = (data: any) =>
  httpCore.get(`division/${data.divisionId}/userList`)

export const createRules = (data: {
  id: number
  // departmentIds: number[]
  // contractorIds: number[]
  management?: number[]
  divisionType: number
}) => httpCore.post('division/dynamic/rule', data)

export const updateDivision = (data: IDivision) =>
  httpCore.put(`division/${data.id}`, data)
export const deleteUsersFromDivision = (data: any) =>
  httpCore.delete('division/remove/user', { data: data })
export const addUsersToDivision = (data: any) =>
  httpCore.post('division/add/user', data)
