import React, { FC, useCallback } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { UISelect } from 'shared/UI/UISelect'
import { IOption } from 'widgets/UsersTableAll/model/table/types'
import {
  setUserAcessSend,
  setUserAcessUse,
  clearUserAcess,
} from 'widgets/UsersTableAll/model/table/tableSlice'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import { customTheme } from 'shared/configs/theme'

interface IBlockUserFilterProps {
  tableId: number
  onCancel: () => void
}

const options: IOption[] = [
  {
    id: '1',
    name: 'Показать активных пользователей',
  },
  {
    id: '2',
    name: 'Показать заблокированных пользователей',
  },
]

const sendValues = {
  '1': true,
  '2': false,
}

const BlockUserFilter: FC<IBlockUserFilterProps> = ({ tableId, onCancel }) => {
  const userAccess = useAppSelector(
    (state) => state.tableSettings.tables[tableId].filters?.userAccessFilter
  )

  const dispatch = useAppDispatch()

  const selectedOption =
    options.find((option) => option.id === userAccess) || null

  const handleChageSelect = useCallback(
    (newValue: IOption | null) => {
      if (!newValue?.id) return
      const filterAccess = newValue.id
      dispatch(setUserAcessUse({ tableId, filterAccess }))
    },
    [dispatch, tableId]
  )

  const handleSubmit = () => {
    const filterAccess = sendValues[userAccess as keyof typeof sendValues]
    dispatch(setUserAcessSend({ tableId, filterAccess }))
    onCancel()
  }

  const handleCancel = () => {
    dispatch(clearUserAcess(tableId))
    onCancel()
  }

  return (
    <Box
      sx={{
        width: 450,
        height: 270,
        backgroundColor: '#fff',
        boxShadow:
          '0px 12px 20px rgba(50,53,55,.11),0px 0px 20px rgba(50,53,55,.06)',
        borderRadius: '16px',
        position: 'relative',
        textAlign: 'center',
        pt: 1,
      }}
    >
      <Box
        sx={{
          p: '0 16px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            mb: 2,
          }}
        >
          Выберите вариант доступа
        </Typography>
        <UISelect
          label="Выберите вариант доступа"
          value={selectedOption}
          onChangeSelect={handleChageSelect}
          options={options}
        />
      </Box>

      <Stack
        sx={{
          width: '100%',
          background: customTheme.palette.background.default,
          borderRadius: '0 0 8px 8px',
          position: 'absolute',
          bottom: 0,
        }}
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
        gap={2}
      >
        <Button variant="contained" fullWidth onClick={handleSubmit}>
          Применить
        </Button>
        <Button variant="text" color={'error'} fullWidth onClick={handleCancel}>
          Отменить
        </Button>
      </Stack>
    </Box>
  )
}

export default BlockUserFilter
