import { combineReducers } from '@reduxjs/toolkit'

// reducers
import auth from './auth/authSlice'
import breadCrumb from './breadCrumb/breacrumbsSlice'
import groups from './groups/groupsSlice'
import { userProfileReducer } from './userProfile/userProfileSlice'
import learnMaterial from './learnMaterial/learnMaterialsSlice'
import learnMaterialContent from './learnMaterialContent/learnMaterialContentSlice'
import snackbar from './snackbar/snackbarSlice'
import uploadMaterial from './learnMaterial/uploadMaterialSlice'
import manager from './manager/managerSlice'
import smartGroup from './smartGroup/smartGroupSlice'
import groupTable from './groupTable/groupTableSlice'
import userAdditionalInfo from './userAdditionlInfo/userAdditionalInfoSlice'
import { searchMaterial } from './searchMaterial/searchMaterialSlice'
import { courseReducer as course } from './courseSlice/courseSlice'
import { contentReducer as content } from './contentSlice/contentSlice'
import { userInfoReducer as userInfo } from './userInfo/userInfoSlice'
import pathSlice from './pathSlice/pathSlice'
import { contextReducer } from './contextSlice/contextSlice'
import tableSettings from '../widgets/UsersTableAll/model/table/tableSlice'
import { divisionsApi } from 'shared/api/divisions/divisionsApi'
import { coreApi } from 'shared/api/core/coreApi'
import { groupsApi } from 'shared/api/groups/groupsApi'
import { materialSettingsReducer } from 'widgets/MaterialSettingsMain'
import { appointmentCRUDReducer } from 'entities/AppointmentUsersCRUD'
import { notificationReducer } from 'widgets/NotificationBlock'
import claims from './claimsSlice'

export const rootReducer = combineReducers({
  smartGroup,
  userAdditionalInfo,
  groupTable,
  auth,
  breadCrumb,
  learnMaterial,
  uploadMaterial,
  learnMaterialContent,
  userProfile: userProfileReducer,
  groups,
  snackbar,
  manager,
  searchMaterial,
  course,
  content,
  userInfo,
  pathSlice,
  context: contextReducer,
  tableSettings,
  materialSettings: materialSettingsReducer,
  appointmentCRUD: appointmentCRUDReducer,
  notificationBlock: notificationReducer,
  [groupsApi.reducerPath]: groupsApi.reducer,
  [divisionsApi.reducerPath]: divisionsApi.reducer,
  [coreApi.reducerPath]: coreApi.reducer,
  claims
})
