import { store } from 'store/store'

export const snackHelper = {
  success: (value: string) => {
    store.dispatch({
      type: 'snack/showSnack',
      payload: {
        messageSnack: value,
        typeSnack: 'success',
      },
    })
  },
  error: (value: string) => {
    store.dispatch({
      type: 'snack/showSnack',
      payload: {
        messageSnack: value,
        typeSnack: 'error',
      },
    })
  },
}
