import { SortTypeOf, StatusUserCourses } from 'store/userInfo/userInfoTypes'
import { AppointTypeFilter } from 'store/userInfo/userInfoSlice'
import { TableFiltersType } from 'widgets/UsersTableAll/model/table/types'
import { httpCore, httpCoreMultipart } from 'shared/configs/instance'


export const getUsers = (data: any) =>
  httpCore.get('user/filter', { params: data })

export const getClaims = () => httpCore.get('user/claim/list')


export const getUsersProfile = (data: any) =>
  httpCore.get('user/user-profile', { params: data })

export const getUserStatistics = (userId: string) =>
  httpCore.get('user/statistics', { params: { userId } })

export const putUserAccess = (data: {
  userIds: string[] | null
  value: boolean
  description: string
}) => httpCore.put('user/access', data)

export const getUserInfo = () => httpCore.get('user/info')

export const getUserInfoAll = (data: any) =>
  httpCore.get('user/info/all', { params: data })

//excel
export const postUsersExport = (data: any) =>
  httpCore.post('user/export', data, { responseType: 'blob' })

export const postUsersImport = (excelFile: any) =>
  httpCoreMultipart.post('user/import', excelFile)

//Table
export const getUserTable = (data: any) =>
  httpCore.get('user/setting/table/user', { params: data })
export const putUserTable = (data: any) =>
  httpCore.put('user/setting/table/user', { ...data.params })

export const getMaterialByUser = (data: {
  userId: string
  entityTypeId?: number
  pageNumber?: number
  pageSize?: number
  sort?: SortTypeOf
  appointTypes?: AppointTypeFilter[]
  filterStatus?: StatusUserCourses[] | []
}) => httpCore.post('material/active/user', data)

export interface GetGroupByUserResponseI {
  id: number
  name: string
  userCount: number
}

export interface GetGroupByUserResponseIWrapper {
  groups: GetGroupByUserResponseI[]
  pageNumber: number
  pageSize: number
  total: number
  totalCount: number
}

export const getGroupByUser = (params: {
  userId: string
  pageNumber?: number
  pageSize?: number
}) =>
  httpCore.get<{
    data: GetGroupByUserResponseIWrapper
    description: null | string
    error: boolean
  }>('group/user', { params })

export const reportUserCoursesData = (data: {
  userId: string
  entityTypeId?: number
  sort?: SortTypeOf
  appointTypes?: AppointTypeFilter[]
}) =>
  httpCore.post('material/active/user/report', data, {
    responseType: 'blob',
  })

export const getUserPositions = () =>
  httpCore.get('link-entity/user/position/list')

export const getUsersGroup = (data: { groupId: number; userIds?: string[] }) =>
  httpCore.post('group/user/list/info', data)

export const putChangeUserAccess = (data: {
  userIds: string[]
  byFilter: boolean
  filters: Partial<TableFiltersType>
  ignoredUserIds: string[]
  value: boolean
  description: string
}) => {
  return httpCore.put('user/access', data)
}
