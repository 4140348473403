import { styled } from '@mui/material/styles'
import { Fab } from '@mui/material'

export const FabStyled = styled(Fab)(({ theme }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  width: '26px',
  height: '26px',
  minHeight: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}))
