import { isAxiosError } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { showSnack } from 'store/snackbar/snackbarSlice'
import { getUsersAdditionalInfo } from 'shared/api/requests/additionalInfo/getUsersAdditionalInfo'

export const fetchUserAdditionalInfo = createAsyncThunk(
  'user/fetchUserAdditionalInfo',
  async (params: any, thunkAPI) => {
    try {
      const response = await getUsersAdditionalInfo(params)

      if (isAxiosError(response)) {
        thunkAPI.dispatch(
          showSnack({
            messageSnack: 'Не удалось загрузить пользователей',
            typeSnack: 'error',
          })
        )
        return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
      }
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
    }
  }
)
