import Typography from '@mui/material/Typography'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material'
import { useUsersTableModel } from './model/useUsersTableModel'
import { UIFlexEndBox } from 'shared/UI/UIFlexBox/UIFlexBox'
import { ReactNode, memo, useCallback, useMemo, useRef, useState } from 'react'
import ProTable from 'shared/UI/ProTable/ui/ProTable'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'

import {
  IPagination,
  IUserAdditionalInfo,
} from 'store/userAdditionlInfo/userAdditionalTypes'
import { getUsersAdditionalInfo } from 'shared/api/requests/additionalInfo/getUsersAdditionalInfo'
import { responseMapperUsers } from './model/userTableConsts'
import { HeaderFormatter } from 'shared/UI/HeaderFormatter/HeaderFormatter'
import { MRT_ColumnDef } from 'material-react-table'
import { defaultCell } from '@proapteka/table'
import { stringify } from 'query-string'
import { useNavigate } from 'react-router'
import FilterListOffIcon from '@mui/icons-material/FilterListOff'
import _, { omit } from 'lodash'
import { onClearAllFilters, setGeneralSearch } from './model/table/tableSlice'
import { useTableFiltersController } from './model/useFiltersController'
import { TableFiltersType } from './model/table/types'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import HighlightSearchText from 'shared/UI/HighlightSearchText/HighlightSearchText'

export type AdditionalInfoResponse = {
  users: IUserAdditionalInfo[]
  paginationInfo: IPagination
}

interface UsersTableAllProps {
  // filters?: FiltersPropsType
  single?: boolean
  outLoading?: boolean
  params?: Record<string, any>
  data?: IUserAdditionalInfo[]
  pagination?: IPagination | null
  onAfterRequest?: (res: IUserAdditionalInfo[], pag: IPagination) => void
  transformOriginData?: (arr: IUserAdditionalInfo[]) => any[]
  transformOriginDataDeps?: any[]
  fetchDeps?: any[]
  defaultSelected?: string[]
  defaultCheckAll?: boolean
  onlySelectedShow?: boolean
  defaultIgnoreList?: any[]
  hideSettings?: boolean
  isGroup?: boolean
  prevUrl?: string
  forceUpdate?: boolean

  // Unique id for saving data in global store
  tableId: number

  addColumns?: MRT_ColumnDef<any>[]

  transformDataAfterRequest?: (data: AdditionalInfoResponse) => Promise<{
    users: (IUserAdditionalInfo & { [key: string]: any })[]
    paginationInfo: IPagination
  }>

  transformResponseMapperUsers?: (data: AdditionalInfoResponse) => {
    users: (IUserAdditionalInfo & { [key: string]: any })[]
    paginationInfo: IPagination
  }

  // Возможность доставать фильтры снаружи компонента
  onFiltersChange?: (filters: Partial<TableFiltersType>) => void

  // Если это объект то там должен быть userId
  getSaveSelect?: (user: IUserAdditionalInfo) => any
  onSelectedChange?: (
    selectedArray: any[],
    ignoredList: string[],
    checkAll: boolean
  ) => void
  userTableSettingEnum?: number
  renderStartRightOfSettings?: (
    selectedLength: number,
    checkAll: boolean,
    selected: string[],
    ignoredList: string[],
    clearSelects: () => void,
    mutationFilters: Partial<TableFiltersType>
  ) => ReactNode
  renderRightOfSettings?: (
    selectedLength: number,
    checkAll: boolean,
    selected: string[],
    ignoredList: string[],
    clearSelects: () => void,
    mutationFilters: Partial<TableFiltersType>
  ) => ReactNode
  renderIconsRightOfSettings?: (
    selectedLength: number,
    checkAll: boolean,
    selected: string[],
    ignoredList: string[],
    clearSelects: () => void,
    mutationFilters: Partial<TableFiltersType>
  ) => ReactNode
  lazyOptions?: boolean
  // Дополнительные поля настроек порядка столбцом таблицы
  addColumnOrder?: string[]
  // Дополнительные поля настроек размеров столбцом таблицы
  addColumnSizing?: Record<string, number>
  // Дополнительные поля настроек видимости столбцом таблицы
  addColumnVisibility?: Record<string, boolean>
  // Если используем этот пропс ОБЯЗАТЕЛЬНО НУЖНО ПЕРЕДАТЬ transformDataAfterRequest !!!
  onlySelected?: boolean
  // Максимальная высота таблицы
  maxHeight?: string
  // При изменении состояние очищаются выбранные пользователи
  clearSelectedTrigger?: boolean

  // action действия в верхней части таблицы
  actionToolbar?: ReactNode
  // Убрать все фильтры
  hideAllFilters?: boolean
}

function UsersTableAll({
  params,
  renderRightOfSettings,
  onSelectedChange,
  renderStartRightOfSettings,
  renderIconsRightOfSettings,
  lazyOptions = false,
  onAfterRequest,
  fetchDeps,
  data,
  outLoading,
  single = false,
  defaultSelected,
  onlySelectedShow,
  getSaveSelect,
  defaultCheckAll,
  defaultIgnoreList,
  hideSettings = false,
  tableId,
  isGroup,
  forceUpdate,
  transformDataAfterRequest,
  transformResponseMapperUsers,
  addColumns,
  addColumnOrder,
  addColumnSizing,
  addColumnVisibility,
  onlySelected,
  prevUrl,
  maxHeight,
  actionToolbar,
  clearSelectedTrigger,
  hideAllFilters,
  onFiltersChange,
}: UsersTableAllProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [paginationInfo, setPaginationInfo] = useState<IPagination | null>(null)
  const [firstPageUsers, setFirstPageUsers] = useState<IUserAdditionalInfo[]>(
    []
  )

  const listOfFilters = useTableFiltersController(tableId)

  const {
    pagination,
    checkAll,
    selected,
    handleSelectAllClick,
    isSelected,
    handleClick,
    selectedLength,
    clearSelects,
    ignoreList,
  } = useUsersTableModel({
    onSelectedChange,
    lazyOptions,
    params,
    onAfterRequest,
    fetchDeps,
    data,
    pagination: paginationInfo,
    single,
    defaultSelected,
    onlySelectedShow,
    getSaveSelect,
    defaultCheckAll,
    defaultIgnoreList,
    hideSettings,
    // filters,
    tableId,
    clearSelectedTrigger,
    firstPageUsers,
  })

  const isWithoutRequestShowOnlySelected = useMemo(() => {
    return Boolean(onlySelected && transformDataAfterRequest && !checkAll)
  }, [onlySelected, transformDataAfterRequest, checkAll])

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const fullNameCellRef = useRef<HTMLParagraphElement | null>(null)

  const sortKeys = useAppSelector(
    (state) => state.tableSettings.tables[tableId]?.sortKeys
  )

  const filtersLocal = useAppSelector(
    (state) => state.tableSettings.tables[tableId]?.filters
  )

  const filtersToSend = useAppSelector(
    (state) => state.tableSettings.tables[tableId]?.filtersToSend
  )

  const generalSearch =
    useAppSelector(
      (state) => state.tableSettings.tables[tableId]?.generalSearch
    ) || ''

  const fontSize = useAppSelector(
    (state) => state.tableSettings.tables[tableId]?.fontSize
  )

  const handleChangeGeneralSearch = useCallback(
    (updater: any) => {
      const searchValue = typeof updater === 'function' ? updater() : updater

      dispatch(setGeneralSearch({ tableId, generalSearch: searchValue }))
    },
    [dispatch, tableId]
  )

  const handleOpenUserInformation = useCallback(
    (userId: string) => {
      const searchStr = stringify({ userId })
      navigate(`/user-management/user-information?${searchStr}`, {
        state: { prev: prevUrl },
      })
    },
    [navigate, prevUrl]
  )

  const handleClearFilters = useCallback(() => {
    dispatch(onClearAllFilters(tableId))
  }, [dispatch, tableId])

  const responseMapperUsersCallback = useCallback(
    (data: any) => {
      setIsLoading(false)
      setPaginationInfo(data.paginationInfo)

      const paginationInfo = data.paginationInfo as IPagination
      if (paginationInfo.page === 1) {
        setFirstPageUsers(data.users)
      }

      return transformResponseMapperUsers
        ? transformResponseMapperUsers(data)
        : responseMapperUsers(data)
    },
    [transformResponseMapperUsers]
  )

  const getUsersAdditionalInfoCallback = useCallback(
    async (data: any) => {
      setIsLoading(true)

      if (isWithoutRequestShowOnlySelected) {
        const data = await transformDataAfterRequest?.({
          users: [],
          paginationInfo: {} as IPagination,
        })
        return { data }
      }
      const response = await getUsersAdditionalInfo(data.params)
      const transformData = transformDataAfterRequest
        ? await transformDataAfterRequest?.(response.data)
        : response

      return transformDataAfterRequest
        ? { ...response, data: transformData }
        : response
    },
    [transformDataAfterRequest, isWithoutRequestShowOnlySelected]
  )

  // TODO REFACTOR
  const userColumnLabel: MRT_ColumnDef<any>[] =
    useMemo((): MRT_ColumnDef<any>[] => {
      return [
        {
          ...defaultCell(),
          accessorKey: 'selectAll',
          header: 'Выбор пользователей',
          Header: ({ column, table }) => {
            // if (hideAllFilters) return <></>
            return (
              <HeaderFormatter
                checkBoxProps={{
                  checkAll: checkAll,
                  onSelectAllClick: handleSelectAllClick,
                  numSelected: selected.length,
                  ignoreSelected: ignoreList.length,
                  originLoading: isLoading,
                }}
                variant="selectAll"
                isCheckbox
                headerTitle={column.columnDef.header}
                tableId={tableId}
                listOfFilters={listOfFilters}
              />
            )
          },
          Cell: ({ row }: { row: any; table: any }) => {
            const originalRow = row.original
            const labelId = `enhanced-table-checkbox-${originalRow.userId}`
            const isIgnored = isSelected(originalRow, 'ignored')

            const checked = isIgnored
              ? false
              : checkAll || isSelected(originalRow)

            return (
              <Checkbox
                color="primary"
                disabled={isLoading}
                checked={checked}
                onClick={(ev) =>
                  handleClick(ev, originalRow, isIgnored || false)
                }
                inputProps={{
                  'aria-labelledby': labelId,
                }}
              />
            )
          },
          enableColumnFilterModes: true,
          enableColumnDragging: false,
          enableColumnOrdering: false,
          enablePinning: false,
          enableHiding: false,
          enableColumnActions: false,
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'userFullName',
          header: 'Пользователь',
          Header: ({ column }) => (
            <HeaderFormatter
              // variant="userFullName"
              variant="fullName"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          enableColumnFilterModes: false,
          enableColumnDragging: false,
          enableColumnOrdering: false,
          enablePinning: false,
          enableHiding: false,
          enableColumnActions: false,
          minSize: 65,

          Cell: ({ row }) => {
            return (
              <Box
                sx={{
                  height:
                    fullNameCellRef.current?.offsetHeight || 'max-content',
                }}
              >
                <Typography
                  sx={{ transition: 'all 0.2s ease' }}
                  ref={fullNameCellRef}
                >
                  <Typography
                    sx={{
                      fontSize: fontSize - 2,
                      display: 'block',
                      width: 'max-content',
                      '&:hover': {
                        color: 'primary.dark',
                        transition: 'all 0.1s',
                        cursor: 'pointer',
                      },
                    }}
                    component={'span'}
                    onClick={() =>
                      handleOpenUserInformation(row.original.userId)
                    }
                  >
                    <HighlightSearchText
                      key={generalSearch}
                      text={row.original.userFullName}
                      search={generalSearch}
                    />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: fontSize - 2,
                      color: 'GrayText',
                    }}
                    component={'span'}
                  >
                    <HighlightSearchText
                      key={generalSearch}
                      text={row.original.userLogin}
                      search={generalSearch}
                    />
                  </Typography>
                </Typography>
              </Box>
            )
          },
        },

        {
          ...defaultCell(),
          accessorKey: 'userLogin',
          header: 'Логин',
          Header: ({ column }) => (
            <HeaderFormatter
              // variant="userLogin"
              variant="login"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          Cell: ({ row }) => {
            return (
              <Typography sx={{ transition: 'all 0.2s ease' }}>
                <HighlightSearchText
                  key={generalSearch}
                  text={row.original.userLogin}
                  search={generalSearch}
                />
              </Typography>
            )
          },
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'contractorName',
          header: 'Контрагент',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="contractorsName"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          Cell: ({ row }) => {
            return (
              <Typography sx={{ transition: 'all 0.2s ease' }}>
                <HighlightSearchText
                  key={generalSearch}
                  text={row.original.contractorName}
                  search={generalSearch}
                />
              </Typography>
            )
          },
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'managerName',
          header: 'Менеджер',
          Header: ({ column }) => (
            <HeaderFormatter
              // variant="managerName"
              variant="managersIds"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'groupName',
          header: 'Группы',
          Header: ({ column }) => (
            <HeaderFormatter
              // variant="groups"
              variant="groups"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'userPhone',
          header: 'Номер телефона',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="phone"
              headerTitle={column.columnDef.header}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          Cell: ({ row }) => {
            return (
              <Typography sx={{ transition: 'all 0.2s ease' }}>
                <HighlightSearchText
                  key={generalSearch}
                  text={row.original.userPhone}
                  search={generalSearch}
                />
              </Typography>
            )
          },
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'lastVisit',
          header: 'Последний вход',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="lastVisit"
              headerTitle={column.columnDef.header}
              tableId={tableId}
              withRangePicker
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'userEmail',
          header: 'Электронная почта',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="email"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          Cell: ({ row }) => {
            return (
              <Typography sx={{ transition: 'all 0.2s ease' }}>
                <HighlightSearchText
                  key={generalSearch}
                  text={row.original.userEmail}
                  search={generalSearch}
                />
              </Typography>
            )
          },
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'userFirstName',
          header: 'Имя',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="name"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          Cell: ({ row }) => {
            return (
              <Typography sx={{ transition: 'all 0.2s ease' }}>
                <HighlightSearchText
                  key={generalSearch}
                  text={row.original.userFirstName}
                  search={generalSearch}
                />
              </Typography>
            )
          },
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'userLastName',
          header: 'Фамилия',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="surname"
              withoutFilters={hideAllFilters}
              headerTitle={column.columnDef.header}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          Cell: ({ row }) => {
            return (
              <Typography sx={{ transition: 'all 0.2s ease' }}>
                <HighlightSearchText
                  key={generalSearch}
                  text={row.original.userLastName}
                  search={generalSearch}
                />
              </Typography>
            )
          },
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'userPatronymic',
          header: 'Отчество',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="patronymic"
              withoutFilters={hideAllFilters}
              headerTitle={column.columnDef.header}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          Cell: ({ row }) => {
            return (
              <Typography sx={{ transition: 'all 0.2s ease' }}>
                <HighlightSearchText
                  key={generalSearch}
                  text={row.original.userPatronymic}
                  search={generalSearch}
                />
              </Typography>
            )
          },
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'contractorTin',
          header: 'ИНН контрагента',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="contractorIds"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'departmentId',
          header: 'Код-АУ',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="departmentId"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'departmentAddress',
          header: 'Адрес подразделения',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="departmentAddress"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'supervisor',
          header: 'Руководитель',
          Header: ({ column }) => (
            <HeaderFormatter
              withoutFilters
              variant="fullName"
              headerTitle={column.columnDef.header}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'departmentDirector',
          header: 'Руководитель подразделения',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="departmentDirector"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'departmentName',
          header: 'Подразделение',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="departmentName"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'regionName',
          header: 'Регион',
          Header: ({ column }) => (
            <HeaderFormatter
              variant="regionIds"
              headerTitle={column.columnDef.header}
              withoutFilters={hideAllFilters}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        {
          ...defaultCell(),
          accessorKey: 'accessToService',
          header: 'Доступ к системе',
          Cell(props) {
            const accessToService = props.row.original.accessToService
            return accessToService ? null : (
              <Tooltip
                title={
                  props.row.original?.descriptionAccessBan ||
                  'Описание не указано'
                }
                arrow
              >
                <LockPersonIcon
                  sx={{ cursor: 'help', fontSize: fontSize + 6 }}
                />
              </Tooltip>
            )
          },
          Header: ({ column }) => (
            <HeaderFormatter
              withBlockUser
              variant="accessToService"
              headerTitle={column.columnDef.header}
              tableId={tableId}
              listOfFilters={listOfFilters}
            />
          ),
          minSize: 65,
        },
        ...(addColumns || []),
      ]
    }, [
      addColumns,
      checkAll,
      fontSize,
      handleClick,
      handleOpenUserInformation,
      handleSelectAllClick,
      hideAllFilters,
      isLoading,
      isSelected,
      listOfFilters,
      selected.length,
      tableId,
      generalSearch,
    ])

  const mutationFilters: Partial<TableFiltersType> = useMemo(() => {
    const fullNameIds = filtersToSend?.fullNameList || []
    const fullNameIgnore = filtersToSend?.fullNameIgnoreList || []

    const loginIds = filtersToSend?.loginList || []
    const loginIgnore = filtersToSend?.loginIgnoreList || []

    const surnameIds = filtersToSend?.surnameList || []
    const surnameIgnore = filtersToSend?.surnameIgnoreList || []

    const patrIds = filtersToSend?.patronymicList || []
    const patrIgnore = filtersToSend?.patronymicIgnoreList || []

    const phoneIds = filtersToSend?.phoneList || []
    const phoneIgnore = filtersToSend?.phoneIgnoreList || []

    const emailIds = filtersToSend?.emailList || []
    const emailIgnore = filtersToSend?.emailIgnoreList || []

    const nameIds = filtersToSend?.nameList || []
    const nameIgnore = filtersToSend?.nameIgnoreList || []

    const userLastVisitStart = filtersToSend?.lastVisit?.userLastVisitStart
    const userLastVisitEnd = filtersToSend?.lastVisit?.userLastVisitEnd
    const userAccessFilter = filtersToSend?.userAccessFilter

    const userIds: string[] = [
      ...(filtersLocal?.fullNamePickAll ? [] : fullNameIds),
      ...(filtersLocal?.loginPickAll ? [] : loginIds),
      ...(filtersLocal?.surnamePickAll ? [] : surnameIds),
      ...(filtersLocal?.patronymicPickAll ? [] : patrIds),
      ...(filtersLocal?.phonePickAll ? [] : phoneIds),
      ...(filtersLocal?.emailPickAll ? [] : emailIds),
      ...(filtersLocal?.namePickAll ? [] : nameIds),
    ]

    const userIgnoreIds: string[] = [
      ...fullNameIgnore,
      ...loginIgnore,
      ...surnameIgnore,
      ...patrIgnore,
      ...phoneIgnore,
      ...emailIgnore,
      ...nameIgnore,
    ]

    const departmentNameIds = filtersToSend?.departmentNameList || []
    const departmentNameignore = filtersToSend?.departmentNameIgnoreList

    const departmentIds = [
      ...(filtersLocal?.departmentIdPickAll
        ? []
        : filtersToSend?.departmentIds || []),
      ...(filtersLocal?.departmentAddressPickAll
        ? []
        : filtersToSend?.departmentAddressList || []),
      ...(filtersLocal?.departmentNamePickAll ? [] : departmentNameIds),
    ]

    const departmentIgnoreIds = [
      ...(filtersToSend?.departmentIgnoreIds || []),
      ...(filtersToSend?.departmentAddressIgnoreList || []),
      ...(departmentNameignore || []),
    ]

    const contractorsIds = (
      filtersLocal?.contractorNamePickAll
        ? []
        : filtersToSend?.contractorsIds || []
    )?.concat(
      filtersLocal?.contractorInnPickAll
        ? []
        : filtersToSend?.contractorInnList || []
    )

    const contractorsIgnoreIds = (
      filtersToSend?.contractorsIgnoreIds || []
    ).concat(filtersToSend?.contractorInnIgnoreList || [])

    const finalFilters = {
      ...omit(
        filtersToSend,
        'fullNameList',
        'fullNameIgnoreList',
        'loginList',
        'loginIgnoreList',
        'surnameList',
        'surnameIgnoreList',
        'patronymicList',
        'patronymicIgnoreList',
        'phoneList',
        'phoneIgnoreList',
        'emailList',
        'emailIgnoreList',
        'nameList',
        'nameIgnoreList',
        'departmentNameList',
        'departmentNameIgnoreList',
        'departmentAddressIgnoreList',
        'departmentAddressList',
        'contractorInnList',
        'contractorInnIgnoreList',
        'lastVisit',
        'userAccessFilter'
      ),
      userAccessFilter,
      departmentIds,
      departmentIgnoreIds,
      contractorsIds,
      contractorsIgnoreIds,
      userLastVisitStart,
      userLastVisitEnd,
      fullName: filtersLocal?.fullNamePickAll
        ? filtersToSend?.fullName
        : undefined,
      name: filtersLocal?.namePickAll ? filtersToSend?.name : undefined,
      surname: filtersLocal?.surnamePickAll
        ? filtersToSend?.surname
        : undefined,
      patronymic: filtersLocal?.patronymicPickAll
        ? filtersToSend?.patronymic
        : undefined,
      phone: filtersLocal?.phonePickAll ? filtersToSend?.phone : undefined,
      email: filtersLocal?.emailPickAll ? filtersToSend?.email : undefined,
      login: filtersLocal?.loginPickAll ? filtersToSend?.login : undefined,
      managerFullName: filtersLocal?.managerFullNamePickAll
        ? filtersToSend?.managerFullName
        : undefined,
      contractorInn: filtersLocal?.contractorInnPickAll
        ? filtersToSend?.contractorInn
        : undefined,
      contractorName: filtersLocal?.contractorNamePickAll
        ? filtersToSend?.contractorName
        : undefined,
      departmentId: filtersLocal?.departmentIdPickAll
        ? filtersToSend?.departmentId
        : undefined,
      departmentAddress: filtersLocal?.departmentAddressPickAll
        ? filtersToSend?.departmentAddress
        : undefined,
      departmentName: filtersLocal?.departmentNamePickAll
        ? filtersToSend?.departmentName
        : undefined,
      departmentDirector: filtersLocal?.departmentDirectorPickAll
        ? filtersToSend?.departmentDirector
        : undefined,
      regionName: filtersLocal?.regionNamePickAll
        ? filtersToSend?.regionName
        : undefined,
      groupName: filtersLocal?.groupNamePickAll
        ? filtersToSend?.groupName
        : undefined,
      divisionName: filtersLocal?.divisionNamePickAll
        ? filtersToSend?.divisionName
        : undefined,

      userIds: userIds,
      userIgnoreIds: userIgnoreIds,
    }

    return finalFilters
  }, [filtersToSend])

  const additionalToolbarInternalActionsAfter = useMemo(() => {
    return (
      <>
        {!hideAllFilters && (
          <Tooltip title="Очистить все фильтры">
            <IconButton onClick={handleClearFilters}>
              <FilterListOffIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    )
  }, [handleClearFilters, hideAllFilters])

  const requestParams = useMemo(() => {
    const data = {
      ...mutationFilters,
      ...params,
      sortKeys: sortKeys || [],
      generalSearch: generalSearch || '',
    }
    onFiltersChange?.(data)
    return data
  }, [generalSearch, mutationFilters, params, sortKeys])

  return (
    <Box
      flex={1}
      flexDirection="column"
      justifyContent={'space-between'}
      // height="100%"
    >
      {renderStartRightOfSettings?.(
        selectedLength,
        checkAll,
        selected,
        ignoreList,
        clearSelects,
        mutationFilters
      ) || <Box />}
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={2}
        sx={{ ml: 2, mb: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: 2,
          }}
        >
          <Typography>
            Найдено пользователей {pagination?.totalCount}
          </Typography>
          <Typography>Выбрано пользователей {selectedLength}</Typography>
        </Box>
        {renderRightOfSettings?.(
          selectedLength,
          checkAll,
          selected,
          ignoreList,
          clearSelects,
          mutationFilters
        )}
        {renderIconsRightOfSettings && (
          <UIFlexEndBox>
            {renderIconsRightOfSettings?.(
              selectedLength,
              checkAll,
              selected,
              ignoreList,
              clearSelects,
              mutationFilters
            )}
          </UIFlexEndBox>
        )}
      </Stack>
      <ProTable
        onSearchChange={handleChangeGeneralSearch}
        addColumnOrder={addColumnOrder}
        addColumnSizing={addColumnSizing}
        addColumnVisibility={addColumnVisibility}
        maxHeight={maxHeight}
        actionToolbar={actionToolbar}
        additionalToolbarInternalActionsAfter={
          additionalToolbarInternalActionsAfter
        }
        isSelected={isSelected}
        checkAll={checkAll}
        filters={requestParams}
        apiRequestFn={getUsersAdditionalInfoCallback}
        columnLabels={userColumnLabel}
        responseMapper={responseMapperUsersCallback}
        version={2}
        tableId={tableId}
        triggers={forceUpdate}
        hideAllFilters={hideAllFilters}
      />
    </Box>
  )
}

export default memo(UsersTableAll)
