import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import {
  mutableUpdateTableFilters,
  sendFilters,
  updateTableFilters,
} from './table/tableSlice'
import { TableFiltersSelectionType, TableFiltersType } from './table/types'
import { listCallbacks, listKeys } from './filterControllerConfig'

type TableFilterKey = keyof TableFiltersSelectionType

export const useTableFiltersController = (tableId: number) => {
  const dispatch = useAppDispatch()

  const onFilter = useCallback(
    (filtersKeys: {
      key: TableFilterKey
      listKey: TableFilterKey
      ignoreListKey: TableFilterKey
    }) => {
      dispatch(
        sendFilters({
          tableId,
          filtersKeys,
        })
      )
    },
    [dispatch, tableId]
  )

  const onClearByKeys = useCallback(
    ({
      key,
      listKey,
      ignoreListKey,
      pickAllKey,
    }: {
      key: TableFilterKey
      listKey: TableFilterKey
      ignoreListKey: TableFilterKey
      pickAllKey: TableFilterKey
    }) => {
      dispatch(
        updateTableFilters({
          tableId,
          filters: {
            [key]: undefined,
            [listKey]: undefined,
            [ignoreListKey]: undefined,
            [pickAllKey]: undefined,
          },
        })
      )
      dispatch(
        sendFilters({
          tableId,
          filtersKeys: {
            key,
            listKey,
            ignoreListKey,
          },
        })
      )
    },
    [dispatch, tableId]
  )

  const onMutableFilter = useCallback(
    (
      key: keyof TableFiltersSelectionType,
      value: TableFiltersSelectionType[keyof TableFiltersSelectionType]
    ) => {
      dispatch(mutableUpdateTableFilters({ tableId, key, value }))
    },
    [dispatch, tableId]
  )

  const onChangeAll = useCallback(
    ({
      valueKey,
      listKeyKey,
      ignoreListKey,
      pickAllKey,
    }: {
      valueKey: keyof TableFiltersSelectionType
      listKeyKey: keyof TableFiltersSelectionType
      ignoreListKey: keyof TableFiltersSelectionType
      pickAllKey: keyof TableFiltersSelectionType
    }) => {
      return ({
        value,
        listKey,
        ignoreList,
        pickAll,
      }: {
        value?: TableFiltersSelectionType['fullName']
        listKey?: TableFiltersSelectionType['fullNameList']
        ignoreList?: TableFiltersSelectionType['fullNameIgnoreList']
        pickAll?: TableFiltersSelectionType['fullNamePickAll']
      }) => {
        const filtersNew: Record<string, any> = {
          [listKeyKey]: listKey,
          [ignoreListKey]: ignoreList,
          [pickAllKey]: pickAll,
        }

        if (value !== undefined) filtersNew[valueKey] = value

        dispatch(
          updateTableFilters({
            tableId,
            filters: filtersNew,
          })
        )
      }
    },
    [dispatch, tableId]
  )

  const listOfFiltersDynamimc = useMemo(() => {
    const list: Record<string, any> = {}

    listKeys.forEach((elm) => {
      list[elm.objectKey] = {
        id: elm.key,
        label: elm.label,

        searchValue: elm.key,
        listValue: elm.listKey,
        ignoreListValue: elm.ignoreListKey,
        pickAll: elm.pickAll,

        onChangeSearchValue: (value: string | null | undefined) =>
          onMutableFilter(elm.key, value),

        onChangeListValue: (value: TableFiltersSelectionType['fullNameList']) =>
          onMutableFilter(elm.listKey, value),

        onChangeIgnoreListValue: (
          value: TableFiltersSelectionType['fullNameIgnoreList']
        ) => onMutableFilter(elm.ignoreListKey, value),

        onChangePickAll: (
          value: TableFiltersSelectionType['fullNamePickAll']
        ) => onMutableFilter(elm.pickAll, value),

        onChangeAll: onChangeAll({
          valueKey: elm.key,
          listKeyKey: elm.listKey,
          ignoreListKey: elm.ignoreListKey,
          pickAllKey: elm.pickAll,
        }),

        onClear: () =>
          onClearByKeys({
            key: elm.key,
            listKey: elm.listKey,
            ignoreListKey: elm.ignoreListKey,
            pickAllKey: elm.pickAll,
          }),

        onFilter: () =>
          onFilter({
            key: elm.key,
            listKey: elm.listKey,
            ignoreListKey: elm.ignoreListKey,
          }),

        callback:
          listCallbacks[elm.objectKey as keyof typeof listCallbacks].callback,
        path: listCallbacks[elm.objectKey as keyof typeof listCallbacks].path,
      }
    })

    return list
  }, [onChangeAll, onClearByKeys, onFilter, onMutableFilter])

  return listOfFiltersDynamimc
}

export function transformSelectionDataToSend(
  filters: TableFiltersSelectionType
): Partial<TableFiltersType> {
  const sendFilters: Partial<TableFiltersType> = {}

  Object.keys(filters).forEach((elm) => {
    const key = elm as keyof TableFiltersSelectionType

    if (typeof filters[key] === 'object') {
      //@ts-ignore
      sendFilters[key] = Object.keys(filters[key])
      return
    }
    //@ts-ignore
    sendFilters[key] = filters[key]
  })

  return sendFilters
}
