import { createSlice } from '@reduxjs/toolkit'

export interface CounterState {
  claims: string[]
}

const initialState: CounterState = {
  claims: [],
}

export const claimsSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    setClaims(state, action) {
      // Этот reducer будет вызываться, когда fetchClaimsSuccess action будет отправлен в Redux store
      state.claims = action.payload; // Обновляем claims с данными, полученными с сервера
    },
  },
})

// Action creators are generated for each case reducer function
export const { setClaims } = claimsSlice.actions

export default claimsSlice.reducer