import { Fab } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

export const CloseButton = styled(Fab)(() => ({
  position: 'absolute',
  borderRadius: '1px',
  top: '0px',
  right: '0px',
  width: '20px',
  height: '20px',
  minHeight: 'auto',
  backgroundColor: '#f6f6f7',
  color: '#6c737b',
  '&:hover': {
    backgroundColor: '#2e7d32',
    color: 'white',
  },
}))

export const CloseIconStyled = styled(CloseIcon)(() => ({
  transition: 'transform .2s linear',
  '&:hover': {
    transform: 'rotate(180deg)',
  },
}))
