import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import React, { useEffect, useState } from 'react'
import { IUserAdditionalInfo } from './../../../../store/userAdditionlInfo/userAdditionalTypes'

export const useSortingControllerAssign = (
  rowsUsers?: any,
  rowsGroups?: any
) => {
  useState<keyof IUserAdditionalInfo>('userFullName')
  const [selected, setSelected] = useState<any[]>([])
  const [assigneeGroup, setAssigneeGroup] = useState<any>([])
  const [checkAll, setCheckAll] = useState(false)

  const dispatch = useAppDispatch()

  const onClearAll = () => {
    setAssigneeGroup([])
    setSelected([])
    setCheckAll(false)
  }

  const handleSelectAllClickUsers = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelected = rowsUsers.map((n: any) => n)
      setSelected(newSelected)
      setCheckAll(true)
      return
    }
    setCheckAll(false)
    setSelected([])
  }

  useEffect(() => {
    if (checkAll) {
      setSelected(rowsUsers.map((n: any) => n))
    }
  }, [rowsUsers])

  const handleSelectAllClickGroups = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const newSelected = rowsGroups.map((n: any) => n.id)
      setAssigneeGroup(newSelected)
      return
    }
    setAssigneeGroup([])
  }

  const isSelected = (id: string) => selected.find((item) => item.userId === id)
  const isSelectedGroup = (name: string) => assigneeGroup.indexOf(name) !== -1

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected: any[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleClickEditAppointmentUser = (
    event: React.MouseEvent<unknown>,
    row: any,
    isItemSelected: boolean
  ) => {
    const selectedItem = selected.find((item) => item.userId === row.userId)
    let newSelected: any[] = []

    if (selectedItem) {
      newSelected = selected.filter((item) => item.userId !== row.userId)
    } else {
      newSelected = [...selected, row]
    }

    setSelected(newSelected)
  }

  const handleClickEditAppointmentGroup = (
    event: React.MouseEvent<unknown>,
    row: any,
    isItemSelected: boolean
  ) => {
    const selectedIndex = assigneeGroup.indexOf(row.id)
    let newSelected: any[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(assigneeGroup, row.id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(assigneeGroup.slice(1))
    } else if (selectedIndex === assigneeGroup.length - 1) {
      newSelected = newSelected.concat(assigneeGroup.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        assigneeGroup.slice(0, selectedIndex),
        assigneeGroup.slice(selectedIndex + 1)
      )
    }
    setAssigneeGroup(newSelected)
  }

  return {
    isSelected,
    isSelectedGroup,
    handleClick,
    handleClickEditAppointmentUser,
    handleClickEditAppointmentGroup,
    handleSelectAllClickUsers,
    handleSelectAllClickGroups,
    selected,
    setSelected,
    assigneeGroup,
    setAssigneeGroup,
    onClearAll,
    checkAll,
  }
}
