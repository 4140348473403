import axios, { AxiosProgressEvent } from 'axios'
import { importFileUrl } from '../../configs/env.config'

export const uploadFile = (
  data: { file: File; materialId: unknown },
  handleProgress: (progressEvent: AxiosProgressEvent) => void
) => {
  return axios.post(
    importFileUrl as string,
    { ...data },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      onUploadProgress: handleProgress,
    }
  )
}
