import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LearnState {
  paths: string[]
}

const initialState: LearnState = {
  paths: [],
}

export const pathSlice = createSlice({
  name: 'learn-materials',
  initialState,
  reducers: {
    addPath(state, action: PayloadAction<string>) {
      const lastPath = state.paths.at(-1)
      if (lastPath) state.paths = [lastPath, action.payload]
      else state.paths = [action.payload]
    },
  },
})

export const { actions: pathActions } = pathSlice
export default pathSlice.reducer
