import React, { useState, useRef, useEffect } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './helpers/canvasPriview'
import { useDebounceEffect } from './helpers/useDebounceEffect'

import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

import 'react-image-crop/dist/ReactCrop.css'
import UIModal from 'shared/UI/UIModal/UIModal'
import { Button, Stack, Tooltip } from '@mui/material'

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
  width: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: width || 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

interface ImgCropperProps {
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  file: File
  open: boolean
  callback: (file: File) => void
  width?: number
  height?: number
}

export const ImgCropper = ({
  open,
  setOpen,
  file,
  callback,
  width,
  height,
}: ImgCropperProps) => {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const blobUrlRef = useRef('')

  const standardSettings: Crop = {
    x: 0,
    y: 0,
    width: width || 300,
    height: height || 200,
    unit: 'px',
  }

  const [crop, setCrop] = useState<Crop | undefined>(standardSettings)
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [aspect, setAspect] = useState<number | undefined>(16 / 9)

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect, 330))
    }
  }

  function onDownloadCropClick(type: 'crop' | 'orig') {
    if (type === 'orig') {
      callback(file)
      return
    }

    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      blobUrlRef.current = URL.createObjectURL(blob)

      const croppedFile = new File([blob], file.name, { type: blob.type })

      callback(croppedFile)
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop]
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current

      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 350 / 350, 100))
    }
  }

  function handleStandard() {
    if (imgRef.current) {
      const { width, height } = imgRef.current

      const x = Math.abs(standardSettings.width - width) / 2
      const y = Math.abs(standardSettings.height - height) / 2

      setAspect(16 / 9)
      setCrop({ ...standardSettings, x: x, y: y })
    }
  }

  function onSelectFile(file: File) {
    if (file) {
      setCrop(undefined) // Makes crop preview update between images.
      const url = URL.createObjectURL(file)
      setImgSrc(url)
    }
  }

  useEffect(() => {
    onSelectFile(file)

    let timeout = setTimeout(() => {
      handleStandard()
    }, 400)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <UIModal open={open} setOpen={setOpen}>
      <div className="App">
        <div className="Crop-Controls">
          <Button
            onClick={handleToggleAspectClick}
            variant="outlined"
            sx={{ mb: 2 }}
          >
            {aspect ? 'Включить' : 'Отключить'} масштабирование
          </Button>
          <Button
            onClick={handleStandard}
            variant="outlined"
            sx={{ mb: 2, ml: 2 }}
          >
            По умолчанию
          </Button>
          <Tooltip title="Чтобы сохранить окончательный результат просто нажмите на фотографию">
            <Button
              variant="outlined"
              sx={{
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                minWidth: '0',
                mb: 2,
                ml: 2,
              }}
            >
              <QuestionMarkIcon />
            </Button>
          </Tooltip>
        </div>
        <Stack direction={'row'} spacing={2}>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
                style={{ maxHeight: '600px' }}
              />
            </ReactCrop>
          )}
          {!!completedCrop && (
            <div style={{ marginBottom: 2, display: 'none' }}>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
          )}
        </Stack>

        {!!completedCrop && (
          <>
            <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
              <Button
                onClick={() => onDownloadCropClick('crop')}
                variant="contained"
              >
                Загрузить обрезанный
              </Button>
              <Button
                onClick={() => onDownloadCropClick('orig')}
                variant="contained"
              >
                Загрузить оригинал
              </Button>
            </Stack>
          </>
        )}
      </div>
    </UIModal>
  )
}
