import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosProgressEvent } from 'axios'
import { MATERIAL } from './learnMaterialTypes'
import { uploadFile } from 'shared/api/requests/file'
import {
  getActiveMaterial,
  GetActiveMaterialProps,
} from 'shared/api/requests/material'
import {
  GetLinks,
  getMaterialsLinkByContentId,
} from 'shared/api/requests/contentMaterial'
import { selectedFolderChange } from 'store/breadCrumb/breacrumbsSlice'

export const fetchLearnMaterials = createAsyncThunk(
  '/courses',
  async (params: GetActiveMaterialProps, thunkAPI) => {
    try {
      const response = await getActiveMaterial(params)

      if (params.parentId && response.data?.data?.parentName) {
        thunkAPI.dispatch(
          selectedFolderChange({
            id: Number(params.parentId),
            name: response.data.data.parentName,
          })
        )
      }

      return response.data.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить материалы')
    }
  }
)

export const fetchByContentMaterials = createAsyncThunk(
  '/content/links',
  async (params: GetLinks, thunkAPI) => {
    try {
      const response = await getMaterialsLinkByContentId(params)
      return response.data.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить ссылки')
    }
  }
)

export const uploadFileMaterial = createAsyncThunk<
  any,
  {
    data: { file: File; materialId: unknown }
    handleProgress: (progressEvent: AxiosProgressEvent) => void
  }
>(
  `${MATERIAL}/uploadFile`,
  async ({ data, handleProgress }, { rejectWithValue, dispatch }) => {
    try {
      return await uploadFile(data, handleProgress)
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  }
)
