import * as React from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar } from './HeaderMini.styled'
import { UIFlexCenterBox } from '../../shared/UI/UIFlexBox/UIFlexBox'
import { useAppSelector } from '../../shared/hooks/redux'
import UserInfo from './components/UserInfo/UserInfo'
import Box from "@mui/material/Box";

type HeaderMiniProps = {
  open: boolean
  setOpen: (value: boolean) => void
}

const HeaderMini: React.FC<HeaderMiniProps> = ({ open, setOpen }) => {
  const breadcrumbs = useAppSelector((state) => state.breadCrumb.breadcrumbs)

  // const handleDrawerOpen = () => {
  //   setOpen(true)
  // }

  return (
    <AppBar
      sx={{
        height: '80px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#1a3652',
        borderRadius: 0,
      }}
      position="fixed"
      open={open}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          // onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >

          <Box style={{ width: '30px', overflow: 'hidden' }}>
            <img
              width="260"
              src="https://prolearning.dev.routeam.ru/static/media/logo.3711aba56b481e0d11d9.png"
              alt="Логотип"
            />
          </Box>

        </IconButton>
        <Typography variant="h6" noWrap component="div">
          {breadcrumbs}
        </Typography>
      </Toolbar>

      <UIFlexCenterBox>
        {/* <IconButton size="small" color="inherit">
          <SearchIcon />
        </IconButton>
        <IconButton sx={{ mr: '18px' }} size="small" color="inherit">
          <Badge variant="dot" color="secondary">
            <EmailIcon />
          </Badge>
        </IconButton> */}
        <UserInfo />
      </UIFlexCenterBox>
    </AppBar>
  )
}

export default HeaderMini
