import { httpCore } from 'shared/configs/instance'

export const createAppointment = (data: any) =>
  httpCore.post('appointment', data)

export const getAppointmentUsers = (data: any) =>
  httpCore.get(`appointment/${data.materialId}/by-course-id`, {
    params: data,
  })

export const deleteAppointmentUser = (data: {
  ids: Array<number | string>
  isAllDelete?: boolean
  materialId?: number
  ignoredIds?: (number | string)[]
}) => httpCore.delete(`appointment`, { data })

export const createAppointmentGroup = (data: any) =>
  httpCore.post('appointment/group', data)

// export const updateAppointmentUser = (data: any) => httpCore.put(`appointment/${data.id}`, data)
export const updateAppointmentUser = (data: any) =>
  httpCore.put(`appointment`, data)

export const reassignUser = (data: {
  materialIds: (number | string)[]
  userIds: string[]
  baseDate: string
  upToDate: string
  accessDeniedAfterExpiration: boolean
}) => httpCore.post('appointment/reassign', data)
