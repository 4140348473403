import { ResponseMapperHandler } from '@proapteka/table'
import { getFirstItemValue } from 'shared/lib/utils'
import { IUserAdditionalInfo } from 'store/userAdditionlInfo/userAdditionalTypes'
import { ListOfKeysForFilters } from './filterControllerConfig'
import moment from 'moment'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const formatDate = (date: string | Date) => {
  const formattedDate = moment(date).format(dateFormat)
  const isValid = moment(date, dateFormat).isValid()

  if (isValid) return formattedDate

  return date
}

export const responseMapperUsers: ResponseMapperHandler = (data: any) => {
  const mappedData = data.users.map((user: IUserAdditionalInfo) => {
    return {
      ...user,
      departmentId: getFirstItemValue(user.departments)?.departmentId,
      managerName: getFirstItemValue(user.managers)?.managerName,
      contractorName: getFirstItemValue(user.contractors)?.contractorName,
      contractorTin: getFirstItemValue(user.contractors)?.contractorTin,
      regionName: getFirstItemValue(user.regions)?.regionName,
      departmentAddress: getFirstItemValue(user.departments)?.departmentAddress,
      departmentDirector: getFirstItemValue(user.departments)
        ?.departmentDirector,
      departmentName: getFirstItemValue(user.departments)?.departmentName,
      groupName: user.groups.map((group) => group.groupName).join(', '),
      lastVisit: formatDate(user.lastVisit),
    }
  })

  return {
    paginationInfo: data.paginationInfo,
    data: mappedData,
  }
}

export const SORT_TABLE_KEYS: Record<ListOfKeysForFilters, number> = {
  fullName: 1,
  email: 2,
  login: 3,
  phone: 4,
  groups: 5,
  contractorIds: 6,
  contractorsName: 7,
  departmentName: 8,
  departmentAddress: 9,
  departmentDirector: 10,
  managersIds: 11,
  regionIds: 12,
  name: 14,
  surname: 15,
  patronymic: 16,
  lastVisit: 17,
  accessToService: 18,
  departmentId: 77,
}

export const CAN_SORT_TABLE: Record<ListOfKeysForFilters, boolean> = {
  fullName: true,
  email: true,
  login: true,
  phone: true,
  groups: false,
  contractorIds: false,
  contractorsName: false,
  departmentName: false,
  departmentAddress: false,
  departmentDirector: false,
  managersIds: false,
  regionIds: false,
  name: true,
  surname: true,
  patronymic: true,
  departmentId: true,
  lastVisit: true,
  accessToService: true,
}

const columnVisibility = {
  'mrt-row-drag': true,
  selectAll: true,
  userFullName: true,
  userEmail: true,
  userLogin: true,
  userPhone: true,
  lastVisit: true,
  supervisor: true,
  managerName: true,
  contractorTin: true,
  contractorName: true,
  defaultDepartmentId: true,
  departmentAddress: true,
  departmentDirector: true,
  departmentName: true,
  regionName: true,
  groupName: true,
  userFirstName: true,
  userLastName: true,
  userPatronymic: true,
}
const columnSizing = {
  userFullName: 150,
  userEmail: 150,
  userLogin: 150,
  userPhone: 150,
  lastVisit: 150,
  supervisor: 150,
  managerName: 150,
  contractorTin: 150,
  contractorName: 150,
  departmentId: 150,
  departmentAddress: 150,
  departmentDirector: 150,
  departmentName: 150,
  regionName: 150,
  groupName: 150,
  userFirstName: 150,
  userLastName: 150,
  userPatronymic: 150,
  selectAll: 100,
}
const columnOrder = [
  'selectAll',
  'userFullName',
  'userEmail',
  'userLogin',
  'userPhone',
  'lastVisit',
  'supervisor',
  'managerName',
  'contractorTin',
  'contractorName',
  'departmentId',
  'departmentAddress',
  'departmentDirector',
  'departmentName',
  'regionName',
  'groupName',
  'userFirstName',
  'userLastName',
  'userPatronymic',
]

export const REGISTRATION_REQUESTS_COLUMNS_CONFIGS = {
  columnSizing,
  columnOrder,
  columnVisibility,
}

export const accessSort = [
  'fullName',
  'email',
  'login',
  'phone',
  'firstName',
  'lastName',
  'patronymic',
]
