import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type AlertColor = 'success' | 'info' | 'warning' | 'error'

interface SnackbarProps {
  openSnack?: boolean
  messageSnack: string
  typeSnack: AlertColor
  updateSnack?: boolean
  updateSnackTwo?: boolean
}

const initialState: SnackbarProps = {
  openSnack: false,
  messageSnack: '',
  typeSnack: 'success',
  updateSnack: false,
  updateSnackTwo: false,
}

export const snackbarSlice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    showSnack(
      state,
      action: PayloadAction<{ messageSnack: string; typeSnack?: AlertColor }>
    ) {
      state.openSnack = !state.openSnack
      state.messageSnack = action.payload.messageSnack
      state.typeSnack = action.payload.typeSnack || 'success'
    },
    onCloseSnackbar(state) {
      state.openSnack = false
    },
    handleUpdateSnack(state, action: PayloadAction<boolean>) {
      state.updateSnack = action.payload
    },
    handleUpdateSnackTwo(state, action: PayloadAction<boolean>) {
      state.updateSnackTwo = action.payload
    },
  },
})

export default snackbarSlice.reducer
export const {
  showSnack,
  handleUpdateSnack,
  handleUpdateSnackTwo,
  onCloseSnackbar,
} = snackbarSlice.actions
