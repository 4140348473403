import { httpCore } from 'shared/configs/instance'

export const createMainTab = (data: {
  courseName: string
  descriptionCourse: string
  urlMiniature?: string
  urlCoverCourse: string
  materialId: any
}) => httpCore.post('course/information', data)

export const updateMainTab = (data: any) =>
  httpCore.put(`course/information/${data.id}`, data)

export const getMainTab = (data: { id: string | number }) =>
  httpCore.get(`course/${data.id}/information`)
