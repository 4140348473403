import { useState } from 'react'
import { UIFlexBetweenBox } from '../../../shared/UI/UIFlexBox/UIFlexBox'
import AddButton from '../../../shared/UI/Buttons/AddButton'
//mui
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { handleClames } from '../../../shared/lib/utils'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import { courseActions } from 'store/courseSlice/courseSlice'
import { useBindActionCreator } from 'shared/hooks/useBindActionCreator'
import { AppointmentUsersTableData } from 'feature/AppointmentUsersTable/ui/AppointmentUsersTable'
import { deleteAppointmentUser } from 'shared/api/requests/appointment'
import { showSnack } from 'store/snackbar/snackbarSlice'
import { coreUpdater } from 'shared/api/core/coreApi'
import { ModalUpdateAppointmentUser } from './CRUD/ModalUpdateAppointmentUser'
import ModalAssignCourse from 'components/Tabs/Appointments/ModalAssignCourse/ModalAssignCourse'
import { appointmentCRUDActions } from '../model/appointmentUsersSlice'

interface AppointmentsToolbarProps {
  onTriggerClearSelected: () => void
  materialId: number | string
  tableData: AppointmentUsersTableData | null
}

export const AppointmentsToolbarActions = ({
  onTriggerClearSelected,
  materialId,
  tableData,
}: AppointmentsToolbarProps) => {
  const dispatch = useAppDispatch()
  const openAssignCourseToUse = useAppSelector(
    (state) => state.appointmentCRUD.openAssignCourse
  )

  const [openAssignCourse, setOpenAssignCourse] = useBindActionCreator(
    openAssignCourseToUse,
    appointmentCRUDActions.setOpenAssignCourse
  )

  const [openModalEditAppointment, setOpenModalEditAppointment] =
    useState(false)

  const handleDeleteAppointmentUser = () => {
    if (!tableData) return

    const { selected, all, ignore } = tableData

    const selectedIds = Object.keys(selected)
    const ignoreIds = Object.keys(ignore)

    deleteAppointmentUser({
      ids: all ? [] : selectedIds,
      isAllDelete: all,
      ignoredIds: all ? ignoreIds : [],
      materialId: Number(materialId),
    })
      .then(() => {
        dispatch(showSnack({ messageSnack: 'Пользователь успешно удален' }))
        onTriggerClearSelected()
        coreUpdater.appointmentUsers()
      })
      .catch(() => {
        dispatch(
          showSnack({ messageSnack: 'Ошибка при удалении', typeSnack: 'error' })
        )
      })
  }

  const isDeleteBtnShow =
    (tableData && tableData?.selectedLength > 0) || tableData?.all

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 0, sm: 0 },
        backgroundColor: '#F3F3F3',
      }}
    >
      <UIFlexBetweenBox sx={{ width: '100%' }}>
        <Box>
          <Typography>Участники</Typography>
        </Box>
        <UIFlexBetweenBox>
          {isDeleteBtnShow && (
            <Button
              size="large"
              sx={{ mr: 2, textTransform: 'none' }}
              variant="outlined"
              onClick={handleDeleteAppointmentUser}
            >
              Удалить
            </Button>
          )}
          {handleClames('course.appointment.setCourse') &&
            tableData &&
            tableData.selectedLength >= 1 && (
              <Button
                size="large"
                sx={{ mr: 2, textTransform: 'none' }}
                variant="outlined"
                disabled={tableData.all}
                onClick={() =>
                  setOpenModalEditAppointment(!openModalEditAppointment)
                }
              >
                Параметры назначений
              </Button>
            )}
          <Button
            sx={{ textTransform: 'none', mr: 2 }}
            variant="outlined"
            size="large"
          >
            Экспорт в CSV
          </Button>
          {handleClames('course.appointment.setCourse') && (
            <AddButton onClick={() => setOpenAssignCourse(!openAssignCourse)}>
              Назначить
            </AddButton>
          )}
          <ModalAssignCourse
            open={openAssignCourse}
            setOpen={setOpenAssignCourse}
            materialId={materialId}
          />
        </UIFlexBetweenBox>
      </UIFlexBetweenBox>
      {tableData && (
        <ModalUpdateAppointmentUser
          open={openModalEditAppointment}
          setOpen={setOpenModalEditAppointment}
          title="Параметры назначений"
          selected={Object.values(tableData?.selected)}
          onAfterSave={onTriggerClearSelected}
          materialIds={[materialId]}
        />
      )}
    </Toolbar>
  )
}
