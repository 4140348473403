import { coreApi } from 'shared/api/core/coreApi'
import { ResponseTypeWrapper } from 'shared/api/core/types'
import { MaterialSettingsType } from './types'
import { store } from 'store/store'
import { materialSettingsAction } from './slice'
import moment from 'moment'

export const materialSettings = coreApi.injectEndpoints({
  endpoints(build) {
    return {
      getMaterialSettingsInfo: build.query<
        ResponseTypeWrapper<MaterialSettingsType>,
        number | string
      >({
        query: (materialId) => ({
          url: `course/${materialId}/information`,
          method: 'get',
        }),

        providesTags: ['MATERIAL_SETTINGS'],

        //@ts-ignore
        transformResponse(
          baseQueryReturnValue: ResponseTypeWrapper<
            Omit<MaterialSettingsType, 'recommendedTime'> & {
              recommendedTime: number
            }
          >,
          meta,
          arg
        ): Omit<MaterialSettingsType, 'recommendedTime'> & {
          recommendedTime: number
        } {
          const data = baseQueryReturnValue.data
          if (!data.id) return data

          const tags =
            data.tags.length > 0 ? data.tags : ['Введите тег и нажмите Enter']

          const recommendedTime = data.recommendedTime
            ? moment('2015-01-01').startOf('day').seconds(data.recommendedTime)
            : null

          store.dispatch(
            materialSettingsAction.setInformation({
              ...data,
              tags,
              recommendedTime,
            })
          )

          return data
        },
      }),
    }
  },
  overrideExisting: false,
})

export const { useGetMaterialSettingsInfoQuery } = materialSettings
