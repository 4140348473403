import { TextField, MenuItem } from '@mui/material'
import { getNotificationTemplate } from 'shared/api/requests/notifications'
import { useEffect, useState } from 'react'
import { TemplateElement, TemplateList } from '../model/types'

interface NotificationTemplateSelectorProps {
  value?: TemplateElement | null
  onChange?: (template: TemplateElement) => void
  onAfterRequest?: (templates: TemplateList) => void
}

export const NotificationTemplateSelector = ({
  value,
  onChange,
  onAfterRequest,
}: NotificationTemplateSelectorProps) => {
  const [templateList, setTemplateList] = useState<TemplateList>({})

  useEffect(() => {
    getNotificationTemplate().then((resNot) => {
      const template: TemplateList =
        resNot.data.data.notificationTemplate.reduce(
          (acc: TemplateList, cur: TemplateElement) => {
            acc[cur.id] = cur
            return acc
          },
          {}
        )
      onAfterRequest?.(template)
      setTemplateList(template)
    })
  }, [])

  return (
    <TextField
      id="outlined-select-currency"
      onChange={(ev) => onChange?.(templateList[Number(ev.target.value)])}
      value={value?.id || 'default'}
      select
      label="Выберите шаблон"
      sx={{ width: '100%' }}
    >
      <MenuItem
        sx={{ whiteSpace: 'normal', display: 'none' }}
        divider={true}
        value={'default'}
      >
        Выберите шаблон
      </MenuItem>
      {Object.values(templateList)?.map((option: any) => (
        <MenuItem
          sx={{ whiteSpace: 'normal' }}
          divider={true}
          key={option.id}
          value={option.id}
        >
          {option.name ? option.name : 'Без названия'}
        </MenuItem>
      ))}
    </TextField>
  )
}
