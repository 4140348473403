import { createSlice } from '@reduxjs/toolkit'
import { MaterialSettingsSchema } from './types'

const initialState: MaterialSettingsSchema = {
  information: {
    courseName: '',
    descriptionCourse: '',
    urlMiniature: '',
    materialId: undefined,
    recommendedTime: null,
    tags: [],
  },
}

export const materialSettingsSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setInformation: (state, action) => {
      state.information = action.payload
    },
  },
})

export const { actions: materialSettingsAction } = materialSettingsSlice
export const { reducer: materialSettingsReducer } = materialSettingsSlice
