import { AxiosResponse } from 'axios'
import { httpCore } from 'shared/configs/instance'
import {
  ContentMaterialActiveI,
  PaginationDataI,
} from 'store/contentSlice/contentTypes'

export const getActiveMaterialContent = (data: { materialId: number }) =>
  httpCore.get('material/content/active', { params: data })

export const deleteMaterialContent = (data: { materialContentId: number }) =>
  httpCore.delete(`material/content/${data.materialContentId}`)

export const getIdMaterialContent = (data: {
  id: number
  byMaterialId: boolean
}) => httpCore.get(`material/content/index`, { params: data })

export const createChapterCourse = (data: any) => httpCore.post('chapter', data)

export const getChapterCourse = (data: { materialContentId: any }) =>
  httpCore.get(`chapter/${data.materialContentId}`)

export const deleteChapterCourse = (data: any) =>
  httpCore.delete(`chapter/${data.id}`, data)

export interface PositionData {
  materialIds: number[]
  // materialContentIds: number[]
}

export const postMaterialContentPosition = (data: PositionData) =>
  httpCore.put(`material/position`, data)

export const updateDescription = (data: {
  id: number
  contentDescription: string
}) => httpCore.put(`material/content/description`, data)

export const updateName = (data: { id: number; contentName: string }) =>
  httpCore.put(`material/content/name`, data)

export interface ActiveAllContentBody {
  contentName?: string
  entityTypeId?: number
  usedInMaterialIds?: number[]
  userAddedIds?: string[]
  dateCreate?: string
  orderBy?: string
  pageNumber?: number
  pageSize?: number

  // materialIds: number[]
  // contentName: string
  // filter: {
  //   pageNumber: number
  //   pageSize: number
  // }
}

export interface GetActiveContentResponse extends PaginationDataI {
  materialContents: ContentMaterialActiveI[]
}

export interface GetActiveContentResponseWrapper {
  data: GetActiveContentResponse
  description: null | string
  isError: boolean
}

export const getActiveAllContent = (
  data?: ActiveAllContentBody
): Promise<AxiosResponse<GetActiveContentResponseWrapper>> =>
  httpCore.post(`material/content/active/all`, data)

export interface postAddContentToMaterialBody {
  materialContentIds: number[]
  materialId: number
  projectId?: number
  isByProjectId?: boolean
}

// TODO добавить поиск по контент материалам
// export const getSearchContentMaterial = (params: {
//   contentName: string
//   pageNumber?: number
//   pageSize?: number
//   projectId?: number
// }) => httpCore.post('material/content/active/all', { params })

export const postAddContentToMaterial = (data: postAddContentToMaterialBody) =>
  httpCore.post(`material/content/material`, data)

export interface DeleteContent {
  materialId: number
  materialContentIds: number[]
}

export const deleteContentFromCourse = (data: DeleteContent) =>
  httpCore.delete('material/content/course', { data })

export interface GetLinks {
  materialContentId: number
  pageNumber: number
  pageSize: number
}
export const getMaterialsLinkByContentId = (data: GetLinks) =>
  httpCore.get('material/content/parents', { params: data })
