import { useAppSelector } from 'shared/hooks/redux'
import { useState, useEffect, useMemo } from 'react'
import { useUsersTableControllerSelect } from './useUsersTableControllerSelect'
import {
  IPagination,
  IUserAdditionalInfo,
} from 'store/userAdditionlInfo/userAdditionalTypes'

interface UseUsersTableModelType {
  params?: Record<string, any>
  // userTableSettingEnum: number
  lazyOptions?: boolean
  fetchDeps?: any[]
  data?: IUserAdditionalInfo[]
  pagination?: IPagination | null
  single?: boolean
  onlySelectedShow?: boolean
  defaultSelected?: any[]
  defaultIgnoreList?: any[]
  defaultCheckAll?: boolean
  hideSettings?: boolean
  // filters: FiltersPropsType
  tableId: number
  // Trigger to clear selections users
  clearSelectedTrigger?: boolean

  firstPageUsers: IUserAdditionalInfo[]
  getSaveSelect?: (user: IUserAdditionalInfo) => any
  onAfterRequest?: (res: IUserAdditionalInfo[], pag: IPagination) => void
  onSelectedChange?: (
    selectedArray: string[],
    ignoredList: string[],
    checkAll: boolean
  ) => void
}

export const useUsersTableModel = ({
  onSelectedChange,
  data,
  pagination,
  single,
  defaultSelected,
  onlySelectedShow,
  getSaveSelect,
  defaultCheckAll,
  defaultIgnoreList,
  // filters,
  tableId,
  clearSelectedTrigger,
  firstPageUsers,
}: UseUsersTableModelType) => {
  const usersAdditional = useAppSelector(
    (state) => state.userAdditionalInfo.userAdditionalInfo
  )

  const {
    isSelected,
    handleSelectAllClick,
    selected,
    handleClick,
    checkAll,
    ignoreList,
    clearSelects,
  } = useUsersTableControllerSelect({
    rows: data || usersAdditional,
    single,
    defaultSelected,
    defaultCheckAll,
    onlySelectedShow,
    getSaveSelect,
    defaultIgnoreList,
    clearSelectedTrigger,
    firstPageUsers,
  })

  const selectedLength: number = useMemo(() => {
    return checkAll
      ? pagination?.totalCount
        ? pagination?.totalCount - ignoreList.length
        : 0
      : selected?.length
  }, [checkAll, ignoreList.length, pagination?.totalCount, selected?.length])

  // pagination ---------------------

  useEffect(() => {
    if (onSelectedChange) onSelectedChange(selected, ignoreList, checkAll)
  }, [checkAll, ignoreList, selected])

  return {
    users: data || usersAdditional,
    pagination,
    checkAll,
    clearSelects,
    selected,
    handleSelectAllClick,
    isSelected,
    handleClick,
    selectedLength,
    ignoreList,
  }
}
