import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IPagination,
  IUserAdditionalInfo,
  UserAdditionalInfo,
} from '../userAdditionlInfo/userAdditionalTypes'
import { fetchUserAdditionalInfoForGroup } from './groupTableThunks'

export interface IGroupTableItem {
  position: number
  name: string
  visible: boolean
}

interface GroupTableState {
  userAdditionalInfo: IUserAdditionalInfo[]
  paginationData: IPagination | null
  groupTableSettings: IGroupTableItem[]
  updateTable: boolean
  usersGroupList: any[]
  isLoading: boolean
  error: string
  isRules: boolean
  isUsers: boolean
  allCount: null | { countUsers: number; countIgnoredUsers: number }
}

const initialState: GroupTableState = {
  userAdditionalInfo: [],
  paginationData: null,
  groupTableSettings: [],
  usersGroupList: [],
  updateTable: false,
  isLoading: false,
  error: '',
  isRules: false,
  isUsers: false,
  allCount: null,
}

export const groupTableSlice = createSlice({
  name: 'group-table',
  initialState,
  reducers: {
    setGroupTableSettings: (state, action: PayloadAction<[]>) => {
      state.groupTableSettings = action.payload
    },
    setUpdateTable: (state, action: PayloadAction<boolean>) => {
      state.updateTable = action.payload
    },
    setUsersGroup: (state, action) => {
      state.usersGroupList = action.payload
    },
    setIsRules: (state, action) => {
      state.isRules = action.payload
    },
    setIsUsers: (state, action) => {
      state.isUsers = action.payload
    },
    setIsAllCount: (state, action) => {
      state.allCount = action.payload
    },
  },
  extraReducers: {
    [fetchUserAdditionalInfoForGroup.fulfilled.type]: (
      state,
      action: PayloadAction<UserAdditionalInfo>
    ) => {
      state.isLoading = false
      state.error = ''
      state.userAdditionalInfo = action.payload.users
      state.paginationData = action.payload.paginationData
    },
    [fetchUserAdditionalInfoForGroup.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchUserAdditionalInfoForGroup.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const {
  setGroupTableSettings,
  setUpdateTable,
  setUsersGroup,
  setIsRules,
  setIsUsers,
  setIsAllCount,
} = groupTableSlice.actions
export default groupTableSlice.reducer
