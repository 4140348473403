import { createAsyncThunk } from '@reduxjs/toolkit'
import { getGroups } from 'shared/api/requests/group'

export const fetchGroup = createAsyncThunk(
  '/group',
  async (params: any, thunkAPI) => {
    try {
      const response = await getGroups(params)
      return response.data.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
    }
  }
)
