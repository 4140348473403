import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  fetchGetUserTeam,
  fetchUserCourses,
  fetchUserGroups,
} from './userInfoThunks'
import { SortType, StatusUserCourses, UserCoursesI } from './userInfoTypes'
import { GetGroupByUserResponseI } from 'shared/api/requests/users'

export type AppointTypeFilter =
  | 'all'
  | 'active'
  | 'expired'
  | 'without_completed'
  | 'completed'
interface UserInfoI {
  groups: GetGroupByUserResponseI[] | [{ name: 'Нет групп' }]
  userGroups: GetGroupByUserResponseI[]
  userGroupsTotalCount: number
  pageNumberUserGroups: number

  userCourses: UserCoursesI | undefined
  pageNumberUserCourses: number
  userCoursesTotalCount: number
  completedCount: number
  sortBy?: SortType
  appointType?: AppointTypeFilter[]

  openAddUsersModal: boolean

  isLoadingUserCourses: boolean

  activeCount: number
  expiredCount: number
  appointmentCount: number
  team: {
    userId: string | null
    supervisorId: string | null
    supervisorName: string | null
    users: { id: string; fullName: string; email: string }[] | null
  }
}

const initialState: UserInfoI = {
  groups: [{ name: 'Нет групп' }],
  userGroups: [],
  userGroupsTotalCount: 0,
  pageNumberUserGroups: 0,

  userCourses: undefined,
  pageNumberUserCourses: 0,
  userCoursesTotalCount: 0,
  completedCount: 0,
  sortBy: undefined,

  appointType: ['without_completed'],

  openAddUsersModal: false,

  isLoadingUserCourses: false,
  activeCount: 0,
  expiredCount: 0,
  appointmentCount: 0,

  team: {
    userId: null,
    supervisorId: null,
    supervisorName: null,
    users: null,
  },
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setOpenAddUsersModal: (state, action: PayloadAction<boolean>) => {
      state.openAddUsersModal = action.payload
    },
    setAppointType: (state, action: PayloadAction<AppointTypeFilter[]>) => {
      state.appointType = action.payload
    },
    setPageNumberUserGroups: (state, action: PayloadAction<number>) => {
      state.pageNumberUserGroups = action.payload
    },
    setGroups: (state, action) => {
      state.groups = action.payload
    },

    setPageNumberUserCourses: (state, action: PayloadAction<number>) => {
      state.pageNumberUserCourses = action.payload
    },

    setSortBy: (state, action: PayloadAction<SortType>) => {
      state.sortBy = action.payload
    },

    clearTeam: (state) => {
      state.team = {
        userId: null,
        supervisorId: null,
        supervisorName: null,
        users: null,
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserGroups.fulfilled, (state, action) => {
      state.userGroups = action.payload.data.groups
      state.userGroupsTotalCount = action.payload.data.totalCount
    })

    builder.addCase(fetchUserCourses.pending, (state, action) => {
      state.isLoadingUserCourses = true
    })
    builder.addCase(fetchUserCourses.fulfilled, (state, action) => {
      state.userCourses = action.payload.data
      state.completedCount = action.payload.data.completedCount
      state.userCoursesTotalCount = action.payload.data.appointmentCount
      state.expiredCount = action.payload.data.expiredCount
      state.activeCount = action.payload.data.activeCount
      state.appointmentCount = action.payload.appointmentCount
      state.isLoadingUserCourses = false
    })
    builder.addCase(fetchUserCourses.rejected, (state, action) => {
      state.isLoadingUserCourses = false
    })
    builder.addCase(fetchGetUserTeam.fulfilled, (state, action) => {
      state.team = action.payload.data
    })
  },
})

export const { actions: userInfoActions } = userInfoSlice
export const { reducer: userInfoReducer } = userInfoSlice
