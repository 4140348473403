import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTeamById } from 'shared/api/requests/teams'
import {
  GetGroupByUserResponseIWrapper,
  getGroupByUser,
  getMaterialByUser,
  getUserInfo,
  reportUserCoursesData,
} from 'shared/api/requests/users'
import { downloadBlob } from 'shared/lib/downloadBlob'
import { showSnack } from 'store/snackbar/snackbarSlice'
import { RootState } from 'store/store'

export const fetchUserInfo = createAsyncThunk(
  'user/fetchAll',
  async (params: any, thunkAPI) => {
    try {
      const response = await getUserInfo()
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
    }
  }
)

export const fetchUserGroups = createAsyncThunk<
  { data: GetGroupByUserResponseIWrapper },
  string,
  { state: RootState }
>('user/fetchUserGroups', async (userId, thunkAPI) => {
  try {
    const store: RootState = thunkAPI.getState()
    const res = await getGroupByUser({
      userId: userId,
      pageNumber: store.userInfo.pageNumberUserGroups + 1,
      pageSize: store.breadCrumb.rowPageCount,
    })

    return res.data
  } catch (error) {
    thunkAPI.dispatch(
      showSnack({
        messageSnack: 'Ошибка загрузки групп пользователя',
        typeSnack: 'error',
      })
    )
    return thunkAPI.rejectWithValue('Error')
  }
})

export const fetchUserCourses = createAsyncThunk<
  any,
  string,
  { state: RootState }
>('user/fetchUserCourses', async (userId, thunkAPI) => {
  try {
    const store: RootState = thunkAPI.getState()
    const res = await getMaterialByUser({
      userId,
      pageNumber: store.userInfo.pageNumberUserCourses + 1,
      pageSize: store.breadCrumb.rowPageCount,
      // entityTypeId: 2,
      sort: store.userInfo.sortBy?.sort,
      appointTypes: store.userInfo.appointType,
    })

    return res.data
  } catch (error) {
    thunkAPI.dispatch(
      showSnack({
        messageSnack: 'Ошибка загрузки назначенных курсов пользователя',
        typeSnack: 'error',
      })
    )
    return thunkAPI.rejectWithValue('Error')
  }
})

export const fetchReportUserCourses = createAsyncThunk<
  any,
  { userId: string; username: string },
  { state: RootState }
>('user/fetchReportUserCourses', async ({ userId, username }, thunkAPI) => {
  try {
    const store: RootState = thunkAPI.getState()
    const res: any = await reportUserCoursesData({
      userId,
      // entityTypeId: 2,
      sort: store.userInfo.sortBy?.sort,
      appointTypes: store.userInfo.appointType,
    })

    if (!res.data) {
      throw new Error()
    }

    downloadBlob(res.data, username, 'xlsx')
  } catch (error) {
    thunkAPI.dispatch(
      showSnack({
        messageSnack: 'Ошибка экспорта назначенных курсов пользователя',
        typeSnack: 'error',
      })
    )
    return thunkAPI.rejectWithValue('Error')
  }
})

export const fetchGetUserTeam = createAsyncThunk<
  any,
  {
    supervisor?: string
    userId?: string
    id?: string
  },
  { state: RootState }
>('user/fetchGetUserTeam', async (data, thunkAPI) => {
  try {
    const response = await getTeamById(data)
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue('Error')
  }
})
