import { Box, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { memo } from 'react'

const EmptyTableFallback = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 'min(100vw, 2529px)',
        alignItems: 'center',
        position: 'fixed',
        '-webkit-box-pack': 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        height: '105px',
      }}
    >
      <Typography
        sx={{
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          boxShadow: 'none',
          borderRadius: '8px',
          fontWeight: 400,
          lineHeight: 1.43,
          display: 'flex',
          padding: '6px 16px',
          color: 'rgb(1, 67, 97)',
          background: 'transparent',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <InfoIcon />
        Нет данных по заданным фильтрам
      </Typography>
    </Box>
  )
}

export default memo(EmptyTableFallback)
