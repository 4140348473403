import Layout from '../widgets/Layout/Layout'
import PrivateRouter from './routes/PrivateRouter'
import CustomSnackBar from '../shared/UI/CustomSnackBar/CustomSnackBar'
import { useLocation } from 'react-router'
import { Suspense, useEffect, useState } from 'react'
import { searchBreadcrumbsByRoutes } from '../shared/lib/utils'
import { useAppDispatch } from '../shared/hooks/redux'
import { breadcrumbsChange } from '../store/breadCrumb/breacrumbsSlice'
import moment from 'moment-timezone'
import { TIME_ZONE } from 'shared/lib/format'
import { Fallback } from 'shared/UI/Fallback/Fallback'
import 'moment/locale/ru'
import { authController } from 'shared/configs/instance'
import { ruLocale } from 'shared/configs/locale'
import { AppProvider } from './providers/AppProvider'
import { OIDCAuthIFrame } from '@proapteka/oidc-auth-iframe'
import { getClaims } from 'shared/api/requests/users'
import { setClaims } from 'store/claimsSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'store/store'
import NoAdmin from './NoAdmin'

moment.updateLocale('ru', ruLocale)
moment.tz.setDefault(TIME_ZONE)

function App() {
  const {claims} = useSelector((state:RootState) => state.claims)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [claimsLoaded, setClaimsLoaded] = useState(false)

  useEffect(() => {
    const breadcrumb = searchBreadcrumbsByRoutes(location.pathname)
    dispatch(breadcrumbsChange(breadcrumb))
  }, [location,dispatch])

  useEffect(()=> {
    getClaims().then((r) => {
      dispatch(setClaims(r.data.data))
      setClaimsLoaded(true) // Устанавливаем состояние claimsLoaded в true после загрузки данных о претензиях
    })
  },[dispatch])

const hasAccess =claims.some(claim => claim ==='prolearning.frontend.admin_portal_access');

  return (
    <AppProvider>
          <CustomSnackBar />
          <Layout>
            <OIDCAuthIFrame
              authControllerInstance={authController}
              preloader={<Fallback />}
              >
              <Suspense fallback={<Fallback />}>
              {(claimsLoaded && hasAccess) ? ( // Проверяем, загружены ли данные о претензиях и имеет ли админ доступ
                <PrivateRouter />
              ): (
                (claimsLoaded && !hasAccess) ? <NoAdmin /> : null // Если данные загружены, но у админа нет доступа, отображаем компонент NoAdmin
              )}
              </Suspense>
            </OIDCAuthIFrame>
          </Layout>
    </AppProvider>
  )
}

export default App
