import { httpCore } from 'shared/configs/instance'

export const postTeam = (data?: { supervisor: string; userIds: string[] }) =>
  httpCore.post('user/team', data)

export const getTeamById = (params: {
  supervisor?: string
  userId?: string
  id?: string
}) => httpCore.get('user/team/supervisor', { params })

export const deleteTeamById = (data: { userIds: string[] }) => {
  return httpCore.delete(`user/team/users/remove-personal-supervisor`, { data })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error('DELETE request error:', error); // Логируем ошибку запроса
      throw error; // Перебрасываем ошибку для дальнейшей обработки
    });
};

export const postSupervisorDivision = (data: {
  supervisorId: string | null
  divisionId: number
}) => httpCore.put('position/division/supervisor', data)

export const postUserSuperVisor = (data: {
  supervisorId?: string
  userIds?: string[]
}) => httpCore.post('user/team/users/add-personal-supervisor', data)
