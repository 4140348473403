import { httpCore } from 'shared/configs/instance'

export const getGroups = (data: any) =>
  httpCore.get('group/filter', { params: data })

export const getGroupsByName = (params: { groupName?: string[] }) =>
  httpCore.get('group', { params: params })

export const getGroupById = (data: any) =>
  httpCore.get(`group/${data.groupId}`, { params: data })
export const createGroup = (data: any) => httpCore.post('group', data)
export const updateGroup = (data: any) => httpCore.put(`group/${data.id}`, data)
export const deleteGroup = (data: any) =>
  httpCore.delete(`group/${data.groupId}`)

export const createSmartGroup = (data: any) =>
  httpCore.post(`group/smart-filter`, data)
export const updateSmartGroup = (data: any) =>
  httpCore.put(`group/smart-filter`, data)
export const getSmartGroupById = (data: any) =>
  httpCore.get(`group/${data.groupId}/smart-filter`)

//Table Group
export const getGroupTable = (data: any) =>
  httpCore.get('user/setting/table/user', { params: data })
export const putGroupTable = (data: any) =>
  httpCore.put('user/setting/table/user/update', data)

//add group
export const addUsersToGroup = (data: any) =>
  httpCore.post('group/add/user', data)
export const deleteUsersToGroup = (data: any) =>
  httpCore.post('group/remove/user', data)
