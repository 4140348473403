import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { CloseButton, CloseIconStyled } from './CloseButton'
import { Fade, Slide, Typography } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'

type UIModalProps = {
  title?: string
  open: boolean
  setOpen: (value: boolean | ((prevVar: boolean) => boolean)) => void
  children: React.ReactNode
  display?: string
  closeButton?: boolean
  minHeight?: string
  minWidth?: string
  justifyContent?:
    | 'space-between'
    | 'space-around'
    | 'center'
    | 'flex-end'
    | 'flex-start'
}

const UIModal: React.FC<UIModalProps> = ({
  open,
  setOpen,
  children,
  title = '',
  display = 'block',
  closeButton = false,
  minHeight = '300px',
  minWidth = '650px',
  justifyContent = 'space-between',
}) => {
  const handleClose = () => setOpen(false)

  const style = {
    title: { fontWeight: 600, textAlign: 'center', mb: 2 },
    modal: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      minWidth: minWidth,
      maxWidth: '1000px',
      minHeight: minHeight,
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: '40px 60px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: justifyContent,
    },
  }

  return (
    <Modal
      sx={{ display: display }}
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style.modal}>
          {closeButton && (
            <CloseButton onClick={handleClose}>
              <CloseIconStyled />
            </CloseButton>
          )}
          {title && (
            <Typography
              sx={style.title}
              id="modal-modal-title"
              variant="h6"
              component="h5"
            >
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Fade>
    </Modal>
  )
}

export default UIModal
