import axiosBaseQuery from 'shared/lib/axiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { GroupsType } from './types'
import { showSnack } from 'store/snackbar/snackbarSlice'

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Groups'],
  endpoints(build) {
    return {
      getGroupsList: build.query<
        { data: GroupsType },
        { pageSize: number; pageNumber: number }
      >({
        query: (params) =>
          `group/filter?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
        providesTags: ['Groups'],
      }),
      deleteGroup: build.mutation<void, number>({
        query: (groupId) => ({
          url: `group/${groupId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Groups'],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(showSnack({ messageSnack: 'Группа успешно удалена' }))
          } catch (error) {
            dispatch(
              showSnack({
                messageSnack: 'Ошибка удаления группы',
                typeSnack: 'error',
              })
            )
          }
          await queryFulfilled
        },
      }),
      addGroup: build.mutation<void, string>({
        query: (name) => ({
          url: 'group',
          method: 'POST',
          data: { name },
        }),
        invalidatesTags: ['Groups'],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(showSnack({ messageSnack: 'Группа успешно создана' }))
          } catch (error) {
            dispatch(
              showSnack({
                messageSnack: 'Ошибка создания группы',
                typeSnack: 'error',
              })
            )
          }
        },
      }),
    }
  },
})

export const {
  useGetGroupsListQuery,
  useDeleteGroupMutation,
  useAddGroupMutation,
} = groupsApi
