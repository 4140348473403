import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import {
  Box,
  Checkbox,
  IconButton,
  TableSortLabel,
  Popover,
  SxProps,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import UsersFilterSortType from 'widgets/UsersTableAll/ui/Filters/UsersFilterSortType'
import {
  CAN_SORT_TABLE,
  SORT_TABLE_KEYS,
} from 'widgets/UsersTableAll/model/userTableConsts'
import {
  ListOfKeysForFilters,
  listKeys,
} from 'widgets/UsersTableAll/model/filterControllerConfig'
import { setSortKeys } from 'widgets/UsersTableAll/model/table/tableSlice'
import { TableFiltersType } from 'widgets/UsersTableAll/model/table/types'
import { RangePickerFilter } from 'feature/RangePickerFilter'
import { BlockuserFilter } from 'feature/BlockUserFilter'
import { FifteenMp } from '@mui/icons-material'

const slotProps = {
  paper: {
    sx: {
      minWidth: '500px',
      minHeight: `65vh`,
      maxWidth: '500px',
      maxHeight: '65vh',
      pt: 1,
      background: 'transparent',
      boxShadow: 'none',
    },
  },
}

const sxStyle: { [key: string]: SxProps } = {
  main: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  popover: {
    pt: 1,
    minWidth: 300,
    minHeight: 300,
    zIndex: 100000,
  },
  iconBtn: {
    position: 'absolute',
    right: '25px',
    padding: '3px',
    background: '#fff',
    borderRadius: '50%',
    '&:hover': {
      background: '#fff',
    },
  },
  selectAll: { position: 'absolute', top: '-15%', left: '0', zIndex: '-1' },
}

const useAccessValues = [true, false]

interface IHeaderFormatterProps {
  // title для столбца
  headerTitle: string
  //
  group?: boolean
  //
  division?: boolean
  //
  multisearch?: boolean
  //
  variant: ListOfKeysForFilters | 'selectAll'
  //
  isCheckbox?: boolean

  // Пропсы для чекбокса
  checkBoxProps?: {
    //
    checkAll: boolean
    //
    ignoreSelected: number
    numSelected: number
    // загрузка данных
    originLoading?: boolean
    // функция для выборки всех пользователей
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  }
  withoutFilters?: boolean

  tableId: number

  listOfFilters: any

  withRangePicker?: boolean

  withBlockUser?: boolean
}

type WithoutFilter = { withoutFilters: true; variant?: string } & Omit<
  IHeaderFormatterProps,
  'variant'
>

type WithFilter = { withoutFilters?: false } & IHeaderFormatterProps

const HeaderFormatterComponent: FC<WithoutFilter | WithFilter> = ({
  headerTitle,
  division = true,
  group = true,
  multisearch = false,
  variant,
  isCheckbox,
  checkBoxProps,
  withoutFilters,
  tableId,
  listOfFilters,
  withRangePicker,
  withBlockUser,
}) => {
  const dispatch = useAppDispatch()

  const filtersToSend = useAppSelector(
    (state) => state.tableSettings.tables[tableId]?.filtersToSend
  )

  const sortKeys = useAppSelector(
    (state) => state.tableSettings.tables[tableId]?.sortKeys
  )

  const [open, setOpen] = useState(false)

  const notListKeysCheck = useMemo(() => {
    if (variant === 'lastVisit') {
      return (
        filtersToSend?.lastVisit?.userLastVisitEnd ||
        filtersToSend?.lastVisit?.userLastVisitStart
      )
    }

    if (variant === 'accessToService') {
      return useAccessValues.includes(filtersToSend?.userAccessFilter!)
    }
    return false
  }, [filtersToSend, variant])

  const isFilteredCheck = useCallback(() => {
    const keyValue = listKeys.find((elm) => elm.objectKey === variant)

    const searchValue = keyValue?.key
        ? filtersToSend?.[keyValue?.key as keyof TableFiltersType]
        : undefined

    const listValue = keyValue?.listKey
        ? filtersToSend?.[keyValue.listKey as keyof TableFiltersType]
        : undefined

    const ignoreValue = keyValue?.ignoreListKey
        ? filtersToSend?.[keyValue.ignoreListKey as keyof TableFiltersType]
        : undefined

    const isListValue = Array.isArray(listValue) && listValue.length > 0
    const isIgnoreValue = Array.isArray(ignoreValue) && ignoreValue.length > 0

    // Проверяем не только наличие searchValue, но и наличие выбранных элементов (listValue или ignoreValue)
    if (!isListValue && !isIgnoreValue) {
      return false
    }

    // Если есть выбранные элементы, подсвечиваем фильтр
    return true
  }, [filtersToSend, variant])

  const createSortHeandle = () => {
    if (withoutFilters) return
    if (!variant || variant === 'selectAll') return
    dispatch(setSortKeys({ tableId, sortKey: SORT_TABLE_KEYS[variant] }))
  }

  const onClose = () => setOpen(false)

  const handleClick = () => setOpen((prev) => !prev)

  const anchorEl = useRef<HTMLButtonElement | null>(null)
  const id = open ? 'simple-popover' : undefined

  const isFiltered = useMemo(() => {
    return isFilteredCheck()
  }, [isFilteredCheck])

  const sorted = useMemo(() => {
    if (withoutFilters) return undefined
    if (!variant || variant === 'selectAll') return undefined
    return sortKeys
      ? sortKeys.find((elm) => elm.key === SORT_TABLE_KEYS[variant])
      : undefined
  }, [sortKeys, variant, withoutFilters])

  const isSort =
    !withoutFilters &&
    variant &&
    variant !== 'selectAll' &&
    CAN_SORT_TABLE[variant]

  return (
    <Box sx={sxStyle.main}>
      {isCheckbox && (
        <Checkbox
          color="primary"
          indeterminate={
            checkBoxProps?.checkAll
              ? checkBoxProps?.checkAll && checkBoxProps.ignoreSelected > 0
              : !checkBoxProps?.checkAll && checkBoxProps?.numSelected! > 0
          }
          checked={checkBoxProps?.checkAll}
          disabled={checkBoxProps?.originLoading}
          onChange={checkBoxProps?.onSelectAllClick}
          sx={sxStyle.selectAll}
        />
      )}

      {!isCheckbox && (
        <>
          {isSort && (
            <TableSortLabel
              active={sorted?.descending}
              direction={sorted?.descending ? 'asc' : 'desc'}
              onClick={createSortHeandle}
            >
              {headerTitle}
            </TableSortLabel>
          )}
          {!isSort && headerTitle}
          <IconButton
            onClick={handleClick}
            ref={anchorEl}
            disabled={withoutFilters}
            sx={sxStyle.iconBtn}
          >
            <FilterListIcon
              sx={{
                visibility: withoutFilters ? 'hidden' : 'unset',
              }}
              color={isFiltered || notListKeysCheck ? 'secondary' : 'action'}
            />
          </IconButton>
          <Popover
            open={open}
            onClose={handleClick}
            id={id}
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={sxStyle.popover}
            slotProps={slotProps}
          >
            {!withoutFilters &&
              !withRangePicker &&
              !withBlockUser &&
              variant !== 'selectAll' && (
                <UsersFilterSortType
                  tableId={tableId}
                  filterId={variant}
                  onClose={onClose}
                  listOfFilters={listOfFilters}
                />
              )}
            {}
            {!withoutFilters && withRangePicker && (
              <RangePickerFilter tableId={tableId} onCancel={onClose} />
            )}
            {!withoutFilters && withBlockUser && (
              <BlockuserFilter onCancel={onClose} tableId={tableId} />
            )}
          </Popover>
        </>
      )}
    </Box>
  )
}

export const HeaderFormatter = memo(
  HeaderFormatterComponent,
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps)
  }
)
