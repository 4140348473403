import React from 'react';

// Libs
import { Link as RouterLink } from 'react-router-dom';

// MUI
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

export type SubCategory = {
  // Название подраздела раздела
  name: string;

  // Url подраздела
  link: string;
};

export type SubCategoryListProps = {
  // Список подращделов
  subCategories: SubCategory[];

  // Переход на страницу подраздела
  handleClickLink: (link: string) => void;
};

export const SubCategoryList: React.FC<SubCategoryListProps> = ({ subCategories, handleClickLink }) => {
  return (
    <>
      {subCategories.map((subCategory) => (
        <ListItem
          disablePadding
          key={subCategory.name}
        >
          <ListItemButton
            sx={{ pl: 8 }}
            component={RouterLink}
            to={subCategory.link}
            onClick={() => handleClickLink(subCategory.link)}
          >
            <ListItemText>
              <Typography fontWeight={400} sx={{ mx: 3 }}>
                {subCategory.name}
              </Typography>
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
};
