import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface UserTableState {
  disabled: boolean
}

const initialState: UserTableState = {
  disabled: false,
}

export const contextSlice = createSlice({
  name: 'user-table',
  initialState,
  reducers: {
    setDisableContextMenu: (state, payload: PayloadAction<boolean>) => {
      state.disabled = payload.payload
    },
  },
})

export const { setDisableContextMenu } = contextSlice.actions
export const contextReducer = contextSlice.reducer
