import * as React from 'react';

// mui imports
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

// mui x imports
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// mui styled
import { PaperStyled } from '../../../shared/UI/PaperStyled/PaperStyled';
import { createMainTab, updateMainTab } from '../../../shared/api/requests/tabsForm';
import { showSnack } from '../../../store/snackbar/snackbarSlice';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux';
import MultiValueInput from '../../../shared/UI/MultiValueInput/MultiValueInput';
import { selectedCourseChange } from '../../../store/breadCrumb/breacrumbsSlice';
import { handleClames } from '../../../shared/lib/utils';
import { userPortal } from '../../../shared/configs/env.config';
import { BORDER_RADIUS } from 'shared/lib/consts';
import { useBindActionCreator } from 'shared/hooks/useBindActionCreator';
import moment, { Moment } from 'moment';
import { useGetMaterialSettingsInfoQuery } from '../model/api';
import { materialSettingsAction } from '../model/slice';
import { ImgUpdate } from './ImgUpdate';
import { LinkCopy } from 'shared/UI/LinkCopy/LinkCopy';
import { TimeValidationError } from '@mui/x-date-pickers';

export const MaterialSettingsMain = ({
  materialId = 0,
}: {
  materialId: string | number;
}) => {
  const dispatch = useAppDispatch();

  useGetMaterialSettingsInfoQuery(materialId);

  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const { selectedCourse } = useAppSelector((state) => state.breadCrumb);
  const information = useAppSelector((state) => state.materialSettings.information);

  const [errorTime, setErrorTime] = React.useState<TimeValidationError | null>(null);
  const [timePickerTouched, setTimePickerTouched] = React.useState(false);

  const [formValues, setFormValues] = useBindActionCreator<typeof information>(
    information,
    materialSettingsAction.setInformation
  );

  const handleChangeRecomendedTime = (newValue: Moment | null) => {
    setTimePickerTouched(true);
    setFormValues({
      ...formValues,
      recommendedTime: newValue,
    });
  };

  const handleChangeFormValues = (ev: any) => {
    setFormValues({ ...formValues, [ev.target.name]: ev.target.value });
  };

  const handleChangeTags = (tags: string[]) => {
    setFormValues({ ...formValues, tags: tags });
  };

  const handleClickFileUpload = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSaveForm = () => {
    if (!formValues) return;

    if (errorTime === 'invalidDate') {
      dispatch(
        showSnack({
          messageSnack: 'Рекомендуемое время введено некорректно',
          typeSnack: 'error',
        })
      );
      return;
    }

    dispatch(
      selectedCourseChange({ ...selectedCourse, name: formValues.courseName })
    );

    const updateOrCreate = formValues.materialId ? updateMainTab : createMainTab;

    const durat = formValues.recommendedTime
      ? moment.duration(formValues.recommendedTime.format('HH:mm'))
      : 0;
    const seconds = durat ? durat.asSeconds() : 0;

    updateOrCreate({
      urlCoverCourse: '',
      ...formValues,
      urlMiniature: undefined,
      //@ts-ignore
      recommendedTime: seconds,
    })
      .then((res) => {
        dispatch(showSnack({ messageSnack: 'Успешно' }));
      })
      .catch((e) => {
        dispatch(showSnack({ messageSnack: 'Ошибка', typeSnack: 'error' }));
      });
  };

  const handleOpenWindow = () => {
    window.open(userPortal);
  };

  return (
    <PaperStyled sx={{ borderRadius: BORDER_RADIUS }}>
      <Grid
        container
        marginBottom={4}
        rowSpacing={4}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={3}>
          <ImgUpdate inputRef={hiddenFileInput} materialId={materialId} />
        </Grid>

        <Grid item xs={7.5}>
          <TextField
            fullWidth
            value={formValues?.courseName}
            name="courseName"
            onChange={handleChangeFormValues}
            placeholder="Наименование"
            variant="outlined"
          />
          <Button
            sx={{ mt: 2 }}
            variant="outlined"
            onClick={handleClickFileUpload}
          >
            Загрузить фотографию
          </Button>
        </Grid>
        <Grid item xs={10}>
          <TextField
            fullWidth
            placeholder="Описание"
            value={formValues?.descriptionCourse}
            name="descriptionCourse"
            onChange={handleChangeFormValues}
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>

        <Grid item xs={4}>
          <TimePicker
            label="Рекомендуемое время МСК"
            value={moment(formValues?.recommendedTime) || null}
            onChange={handleChangeRecomendedTime}
            onError={(reason) => setErrorTime(reason)}
            slotProps={{
              textField: {
                fullWidth: true,
                error: timePickerTouched && Boolean(errorTime),
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <MultiValueInput
            element={{ name: 'tags', label: 'Тэги' }}
            state={formValues?.tags || []}
            setState={(tags) => handleChangeTags(tags)}
          />
        </Grid>
        <Grid item xs={4}>
          <LinkCopy link={userPortal} />
        </Grid>
        <Grid item xs={4}>
          <Button onClick={handleOpenWindow} variant="outlined">
            Перейти на пользовательский портал
          </Button>
        </Grid>
      </Grid>

      {handleClames('course.main.save') && (
        <Button onClick={handleSaveForm} variant="contained">
          Сохранить
        </Button>
      )}
    </PaperStyled>
  );
};
