import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

export const PaperStyled = styled(Paper)(({ theme }) => ({
  position: 'relative',
  zIndex: 10,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 30px 60px rgba(32, 56, 85, 0.15)',
  borderRadius: 0,
  padding: '14px 22px',
  marginTop: theme.spacing(2),
}))
