import * as React from 'react'
import DrawerMini from '../Drawer/DrawerMini'
import HeaderMini from '../Header/HeaderMini'
import { DrawerHeader } from '../Drawer/DrawerMini.styled'
//mui
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'

type ChildrenProps = {
  children: React.ReactNode | React.ReactNode[]
}

const Layout: React.FC<ChildrenProps> = ({ children }) => {
  const [openDrawer, setOpenDrawer] = React.useState(false)

  const setDrawerCallback = React.useCallback((state: boolean) => {
    setOpenDrawer(state)
  }, [])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderMini open={openDrawer} setOpen={setDrawerCallback} />
      <DrawerMini open={openDrawer} setOpen={setDrawerCallback} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: '24px 14px',
          minHeight: '100vh',
          width: '100%',
          overflow: 'auto',
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  )
}
export default Layout
