export const ERR_NETWORK = 'ERR_NETWORK'

export const getPageCount = (totalCount: number, limit: number) => {
  return Math.ceil(Number(totalCount) * limit)
}

export const defaultFilterParams = {
  searchAllContractors: false,
  searchAllRegions: false,
  searchAllManagers: false,
  searchAllGroups: false,
  searchAllDepartments: false,
  fullName: null,
  email: null,
  departmentAddress: null,
  departmentDirector: null,
  departmentIds: {},
  groups: [],
  contractorsIds: {},
  managersIds: {},
  regionIds: {},
  sortKeys: [
    {
      key: 1,
      descending: false,
    },
  ],
}

export const handleClames = (clameName: string) => {
  const clames = [
    'education.createProject',
    'education.projectManagement',
    'education.deleteProject',
    'education.createMaterial',
    'education.DeleteMaterial',
    'education.createFolder',
    'course.structure.createMaterialContent',
    'course.structure.deleteMaterialContent',
    'course.structure.passageSettings',
    'course.notifications.save',
    'course.main.save',
    'course.accessControl.save',
    'course.completion.save',
    'course.appointment.setCourse',
    'course.reviews.deleteReview',
    'userManagement.users.setCourse',
    'userManagement.users.addGroup',
    'userManagement.group.createGroup',
    'userManagement.group.deleteGroup',
    'userManagement.divisions.createDivision',
    'userManagement.divisions.deleteDivision',
    'group.addUsers',
    'group.deleteUsers',
    'group.rename',
    'division.addUsers',
    'division.deleteUsers',
    'meropriyatiy.add',
    'meropriyatiyTab.addSession',
    'knowledgebase.createFolder',
    'knowledgebase.createMaterial',
    'knowledgebase.deleteMaterial',
    'selfRegistration.createCatalog',
    'selfRegistration.deleteCatalog',
    'selfRegistration.updateCatalog',
    'questionsAnswers.response',
    'questionsAnswers.deleteAnswer',
    'prolearning.frontend.admin_portal_access',
    'prolearning.frontend.user_portal_access',
  ]

  return clames.includes(clameName)
}

export const searchBreadcrumbsByRoutes = (link: string) => {
  switch (link) {
    case '/education':
      return 'Учебные материалы'
    case '/user-management':
    case '/group':
    case '/division':
    case '/user-management/user-information':
      return 'Пользователи'
    case '/meropriyatiya':
    case '/meropriyatya-tabs':
      return 'Мероприятия'
    case '/knowledgebase':
    case '/knowledgebase/regulatory-documents':
    case '/knowledgebase/useful-materials':
    case '/knowledgebase/educational-materials':
      return 'Библиотека'
    case '/self-registration':
      return 'Каталоги'
    case '/questions-answers':
    case '/questions-answers/assignments':
    case '/questions-answers/training-applications':
    case '/questions-answers/courses-questions':
    case '/questions-answers/messages':
    case '/questions-answers/feedback':
      return 'Обратная связь'
    case '/news':
    case '/news/news-feed':
    case '/news/banners':
      return 'Новости'
    case '/reports':
      return 'Отчеты'
    case '/settings':
    case '/notification-settings':
    case '/userprofile-settings':
      return 'Настройки'
    case '/material-storage':
      return 'Хранилище материалов'
    default:
      return 'Учебные материалы'
  }
}

export const userInformationFieldsRequired = [
  {
    name: 'Фамилия',
    value: 'lastName',
    required: true,
  },
  {
    name: 'Имя',
    value: 'firstName',
    required: true,
  },
  {
    name: 'Отчество',
    value: 'patronymic',
    required: true,
  },
  {
    name: 'Логин',
    value: 'login',
    required: true,
  },
  {
    name: 'Телефон',
    value: 'phone',
    required: true,
  },
  {
    name: 'Email',
    value: 'email',
    required: true,
  },
]

// Функци для получение первого элемента в масииве с проверкой чтобы не падала страница если нету элемента
export const getFirstItemValue = <T>(array: T[] | undefined) =>
  (array?.[0] || '') as T

//   {
//     name: 'Главная',
//     link: '/',
//     icon: <HomeIcon color="disabled" fontSize="large" />,
//   },
//   {
//     name: 'Учебные материалы',
//     link: `/education`,
//     icon: <LibraryBooksIcon color="disabled" fontSize="large" />,
//   },
//   {
//     name: 'Пользователи',
//     link: '/user-management',
//     icon: <PeopleIcon color="disabled" fontSize="large" />,
//   },
//   {
//     name: 'Мероприятия',
//     link: '/meropriyatiya',
//     icon: <CalendarMonthIcon color="disabled" fontSize="large" />,
//   },
//   {
//     name: 'Библиотека',
//     link: '/knowledgebase',
//     icon: <SchoolIcon color="disabled" fontSize="large" />,
//   },
//   {
//     name: 'Саморегистрация',
//     link: '/self-registration',
//     icon: <HowToRegIcon color="disabled" fontSize="large" />,
//   },
//   {
//     name: 'Обратная связь',
//     link: '/questions-answers',
//     icon: <QuestionAnswerIcon color="disabled" fontSize="large" />,
//   },
//   {
//     name: 'Отчеты',
//     link: '/reports',
//     icon: <AssessmentIcon color="disabled" fontSize="large" />,
//   },
// ]
