import { createAsyncThunk } from '@reduxjs/toolkit'
import { getUsers, getUsersProfile } from '../../shared/api/requests/users'

export const fetchUsersProfile = createAsyncThunk(
  'user/fetchAll',
  async (params: any, thunkAPI) => {
    try {
      const response = await getUsersProfile(params)
      return response.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
    }
  }
)
