import { ThemeProvider } from '@emotion/react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { PathHistoryProvider } from 'app/providers/PathHistoryProvider/PathHistoryProvider'
import { ReactNode } from 'react'
import { customTheme } from 'shared/configs/theme'

export const AppProvider = ({ children }: { children: ReactNode }) => {
  return (
    <PathHistoryProvider>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <ThemeProvider theme={customTheme}>{children}</ThemeProvider>
      </LocalizationProvider>
    </PathHistoryProvider>
  )
}
