import * as React from 'react'

// Redux
import { useAppDispatch, useAppSelector } from '../../shared/hooks/redux'
import { breadcrumbsChange } from '../../store/breadCrumb/breacrumbsSlice'

// MUI
import Divider from '@mui/material/Divider'

// Style
import { Drawer, DrawerHeader } from './DrawerMini.styled'

// Icons
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import SchoolIcon from '@mui/icons-material/School'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AssessmentIcon from '@mui/icons-material/Assessment'
import SettingsIcon from '@mui/icons-material/Settings'
import FeedIcon from '@mui/icons-material/Feed';
import PermMediaIcon from '@mui/icons-material/PermMedia';

// Components
import CategoryList from "./components/CategoryList";


const drawerWidth = 337

const categories = [
  // {
  //   name: 'Главная',
  //   link: '/',
  //   icon: <HomeIcon color="disabled" fontSize="large" />,
  // },
  {
    name: 'Учебные материалы',
    link: `/education`,
    icon: <LibraryBooksIcon color="disabled" fontSize="large" />,
  },
  {
    name: 'Пользователи',
    link: '/user-management',
    icon: <GroupsIcon color="disabled" fontSize="large" />,
  },
  {
    name: 'Мероприятия',
    link: '/meropriyatiya',
    icon: <CalendarMonthIcon color="disabled" fontSize="large" />,
  },
  {
    name: 'Библиотека',
    link: '/knowledgebase',
    icon: <SchoolIcon color="disabled" fontSize="large" />,
    subCategories: [
      { name: 'Нормативные документы', link: '/knowledgebase/regulatory-documents' },
      { name: 'Полезные материалы', link: '/knowledgebase/useful-materials' },
      { name: 'Обучающие материалы', link: '/knowledgebase/educational-materials' },
    ],
  },
  {
    name: 'Обратная связь',
    link: '/questions-answers',
    icon: <QuestionAnswerIcon color="disabled" fontSize="large" />,
    subCategories: [
      { name: 'Задания', link: '/questions-answers/assignments' },
      { name: 'Заявки на обучение', link: '/questions-answers/training-applications' },
      { name: 'Вопросы по курсам', link: '/questions-answers/courses-questions' },
      { name: 'Сообщения', link: '/questions-answers/messages' },
      { name: 'Отзывы', link: '/questions-answers/feedback' },
    ],
  },
  {
    name: 'Новости',
    link: '/news',
    icon: <FeedIcon color="disabled" fontSize="large" />,
    subCategories: [
      { name: 'Лента новостей', link: '/news/news-feed' },
      { name: 'Баннеры', link: '/news/banners' },
    ],
  },
  {
    name: 'Отчеты',
    link: '/reports',
    icon: <AssessmentIcon color="disabled" fontSize="large" />,
  },
  {
    name: 'Настройки',
    link: '/settings',
    icon: <SettingsIcon color="disabled" fontSize="large" />,
  },
  {
    name: 'Хранилище материалов',
    link: '/material-storage',
    icon: <PermMediaIcon color="disabled" fontSize="large" />,
  },
]

const styles = {
  drawerParent: {
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  typo: {
    m: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '22px',
    color: '#fff',
  },
  drawer: {
    borderRadius: 0,
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
    },
  },
  drawerHeader: {
    height: '80px',
    backgroundColor: '#1a3652',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 600px)': {
      minHeight: '80px',
    },
  },
  listItemText: {
    color: '#1F1F1F',
    fontWeight: '300',
    fontSize: '16px',
  },
  activeItem: {
    fontWeight: 'bold',
  },
}

type DrawerMiniProps = {
  open: boolean
  setOpen: (value: boolean) => void
}

const DrawerMini: React.FC<DrawerMiniProps> = ({ open, setOpen }) => {
  const dispatch = useAppDispatch()
  const breadcrumbs = useAppSelector((state) => state.breadCrumb.breadcrumbs)
  const [openCategory, setOpenCategory] = React.useState<string | null>(null)
  // // Использование useRef для хранения ID таймера, позволяет сохранить мутируемое значение между рендерами
  // const leaveTimeoutId = React.useRef<NodeJS.Timeout | null>(null);

  const handleDrawerToggle = () => {
    setOpen(!open);
  }

  const handleClickLink = (name: string) => {
    // setOpen(true);
    dispatch(breadcrumbsChange(name))
  }

  const handleMouseLeave = () => {

    setOpen(false);
    setOpenCategory(null);

    // // Если таймер уже установлен, очищаем его
    // if (leaveTimeoutId.current) {
    //   clearTimeout(leaveTimeoutId.current);
    // }
    // // Устанавливаем новый таймер с задержкой 0.5 сек
    // leaveTimeoutId.current = setTimeout(() => {
    //   setOpen(false);
    //   setOpenCategory(null);
    // }, 500);
  };


  const handleClickCategory = (name: string) => {
    setOpen(true)
    setOpenCategory(openCategory === name ? null : name)
  }

  return (
      <Drawer
        variant="permanent"
        open={open}
        sx={styles.drawerParent}
        onMouseLeave={handleMouseLeave}
      >
        <DrawerHeader sx={styles.drawerHeader}>
          <img
            width="260"
            src="https://prolearning.dev.routeam.ru/static/media/logo.3711aba56b481e0d11d9.png"
            alt="Логотип"
          />
        </DrawerHeader>
        <Divider />
        <CategoryList
          open={open}
          categories={categories}
          openCategory={openCategory}
          breadcrumbs={breadcrumbs}
          handleClickLink={handleClickLink}
          handleClickCategory={handleClickCategory}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Divider />
      </Drawer>
  )
}

export default React.memo(DrawerMini)
