import * as React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function NoAdmin() {
  const rootRef = React.useRef<HTMLDivElement>(null);

  return (
    <Box
      sx={{position:'fixed',
        zIndex:9999,
        top:'80px',
        height: '100vh',
        flexGrow: 1,
        left:0,
        width:'100vw',
        transform: 'translateZ(0)',
        '@media all and (-ms-high-contrast: none)': {
          display: 'none',
        },
      }}
      ref={rootRef}
    >
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
        aria-labelledby="server-modal-title"
        sx={{
          marginTop:'-80px',
          display: 'flex',
          p: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        container={() => rootRef.current!}
      >
        <Box
          sx={{
            position: 'relative',
          borderRadius:'10px',
          width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: (theme) => theme.shadows[5],
            p: 4,
          }}
        >
          <Typography id="server-modal-title" variant="h6" component="h2" textAlign={'center'}>
            ДОСТУП ЗАПРЕЩЕН
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}