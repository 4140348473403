import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TemplateElement } from 'feature/NotificationTemplateSelector'

interface AppointmentUsersCRUDSchema {
  selectedTemplate: TemplateElement | null
}

const initialState: AppointmentUsersCRUDSchema = {
  selectedTemplate: null,
}

export const notificationBlock = createSlice({
  name: 'notificationBlock',
  initialState,
  reducers: {
    setSelectedTemplate: (state, action: PayloadAction<TemplateElement>) => {
      state.selectedTemplate = action.payload
    },
  },
})

export const { actions: notificationAction } = notificationBlock
export const { reducer: notificationReducer } = notificationBlock
