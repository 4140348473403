import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Slide,
  TextField,
  Typography,
} from '@mui/material'

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface DialogWindowProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  children: ReactNode
  func?: any
  title?: string
  fullScreen?: boolean
}

const DialogWindow = ({
  func,
  open,
  setOpen,
  children,
  title,
  fullScreen = false,
}: DialogWindowProps) => {
  return (
    <Dialog
      title={title}
      fullWidth
      fullScreen={fullScreen}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpen(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      {open && children}
    </Dialog>
  )
}

export default DialogWindow
