import { useCallback, useEffect, useMemo } from 'react'
import { useAppSelector, useAppDispatch } from './redux'
import { TableSettingsStateGroupType } from '@proapteka/table'
import { store } from 'store/store'
import { initTable } from 'widgets/UsersTableAll/model/table/tableSlice'
import {
  restoreTableConfigSettings,
  saveTableConfigSettings,
} from 'widgets/UsersTableAll/model/table/tableThunk'
import { MRT_ColumnPinningState, MRT_DensityState } from 'material-react-table'
import _ from 'lodash'

export type useTableSettingsStoreConnectorArgs = {
  tableId: number
  version: number
  initColumnOrder?: string[] | []
  initColumnSizing?: Record<string, number>
  initColumnVisibility?: Record<string, boolean>
  initColumnPinning: MRT_ColumnPinningState
  initFontSize?: number
  initDensity: MRT_DensityState
}

export const useTableSettingsStoreConnector = (
  args: useTableSettingsStoreConnectorArgs
) => {
  const dispatch = useAppDispatch()
  const {
    tableId,
    version,
    initColumnOrder = [],
    initColumnVisibility = {},
    initColumnSizing = {},
    initFontSize = 14,
    initColumnPinning,
    initDensity,
  } = args

  const columnOrder = useAppSelector((state) => state.tableSettings.tables[tableId]?.columnOrder)
  const columnPinning = useAppSelector((state) => state.tableSettings.tables[tableId]?.columnPinning)
  const columnSizing = useAppSelector((state) => state.tableSettings.tables[tableId]?.columnSizing)
  const columnVisibility = useAppSelector((state) => state.tableSettings.tables[tableId]?.columnVisibility)
  const density = useAppSelector((state) => state.tableSettings.tables[tableId]?.density)
  const fontSize = useAppSelector((state) => state.tableSettings.tables[tableId]?.fontSize)
  const isFetched = useAppSelector((state) => state.tableSettings.tables[tableId]?.isFetched) || false

  useEffect(() => {
    const tableSettings = store.getState().tableSettings.tables[tableId]

    if (!tableSettings) {
      dispatch(
        initTable({
          [tableId]: {
            id: tableId,
            v: version,
            isFetched: false,
            isNow: false,
            columnOrder: initColumnOrder,
            columnSizing: initColumnSizing,
            columnVisibility: initColumnVisibility,
            columnPinning: initColumnPinning,
            fontSize: 14,
            density: initDensity,
          },
        })
      )
      dispatch(restoreTableConfigSettings({ tableId, version }))
    }
  }, [])

  const onColumnSizingChange: TableSettingsStateGroupType['onColumnSizingChange'] =
    useCallback(
      ({ tableId, columnSizing }) => {
        dispatch(
          saveTableConfigSettings({ tableId, content: { columnSizing } })
        )
      },
      [dispatch]
    )

  const onColumnVisibilityChange: TableSettingsStateGroupType['onColumnVisibilityChange'] =
    useCallback(
      ({ tableId, columnVisibility }) => {
        dispatch(
          saveTableConfigSettings({ tableId, content: { columnVisibility } })
        )
      },
      [dispatch]
    )

  const onColumnOrderChange: TableSettingsStateGroupType['onColumnOrderChange'] =
    useCallback(
      ({ tableId, columnOrder }) => {
        dispatch(saveTableConfigSettings({ tableId, content: { columnOrder } }))
      },
      [dispatch]
    )

  const onColumnPinningChange = useCallback(
    ({
      tableId,
      columnPinning,
    }: {
      tableId: number
      columnPinning: MRT_ColumnPinningState
    }) => {
      if (
        columnPinning?.left?.length === 0 &&
        columnPinning?.right?.length === 0
      ) {
        dispatch(
          saveTableConfigSettings({
            tableId,
            content: { columnPinning: initColumnPinning },
          })
        )
        return
      }
      dispatch(saveTableConfigSettings({ tableId, content: { columnPinning } }))
    },
    [
      dispatch,
      // initColumnPinning
    ]
  )

  const onFontSizeChange = useCallback(
    (fontSize: number) => {
      dispatch(
        saveTableConfigSettings({
          tableId,
          content: {
            fontSize,
          },
        })
      )
    },
    [dispatch, tableId]
  )

  const onDensityChange: TableSettingsStateGroupType['onDensityChange'] =
    useCallback(
      ({ tableId, density }) => {
        dispatch(saveTableConfigSettings({ tableId, content: { density } }))
      },
      [dispatch]
    )

  const tableSettings = useMemo(() => {
    return {
      onColumnSizingChange,
      onColumnOrderChange,
      onColumnVisibilityChange,
      onColumnPinningChange,
      onDensityChange,
      columnSizing: columnSizing || initColumnSizing,
      columnVisibility: columnVisibility || initColumnVisibility,
      columnOrder: columnOrder || initColumnOrder,
      columnPinning: columnPinning || initColumnPinning,
      isFetched,
      density: density || initDensity,
      fontSize: fontSize || initFontSize,
      onFontSizeChange,
    }
  }, [
    columnOrder,
    columnPinning,
    columnSizing,
    columnVisibility,
    density,
    fontSize,
    isFetched,
    onColumnOrderChange,
    onColumnPinningChange,
    onColumnSizingChange,
    onColumnVisibilityChange,
    onDensityChange,
    onFontSizeChange,
  ])

  return tableSettings
}
