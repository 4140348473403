// import { getUsersAdditionalInfo } from 'shared/api/requests/additionalInfo/getUsersAdditionalInfo'
import { getUsersAdditionalInfo } from 'shared/api/requests/additionalInfo/getUsersAdditionalInfo'
import {
  UserAdditionalInfoProps,
  UserAdditionalInfoType,
} from 'shared/api/requests/additionalInfo/types'
import { getGroupsByName } from 'shared/api/requests/group'
import {
  getDepartment,
  getContractor,
  getManager,
  getRegion,
} from 'shared/api/requests/linkEntity/linkEntity'
import { TableFiltersSelectionType } from './table/types'

export type ParamsTableFilters = { pageSize: number; pageNumber: number }

export type ListOfKeysForFilters =
  | 'fullName'
  | 'managersIds'
  | 'contractorsName'
  | 'login'
  | 'email'
  | 'departmentAddress'
  | 'departmentDirector'
  | 'departmentName'
  | 'contractorIds'
  | 'regionIds'
  | 'groups'
  | 'phone'
  | 'name'
  | 'surname'
  | 'patronymic'
  | 'departmentId'
  | 'lastVisit'
  | 'accessToService'

const getUsers = (
  nameKeyValue: string,
  getName?: (user: any) => string,
  getId?: (user: any) => string | number
) => {
  return (args: Partial<UserAdditionalInfoProps>) =>
    getUsersAdditionalInfo(args).then((res) => {
      return {
        data: {
          data: {
            users: res.data.users.map((elm) => ({
              id: !getId ? elm.userId : getId(elm) || '',
              name: !getName
                ? elm?.[nameKeyValue as keyof UserAdditionalInfoType] || ''
                : getName(elm) || '',
              subName: nameKeyValue === 'userLogin' ? undefined : elm.userLogin,
            })),
            totalPages: res.data.paginationInfo.totalPages,
            pageNumber: res.data.paginationInfo.page,
            totalCount: res.data.paginationInfo.totalCount,
          },
        },
      }
    })
}

const getCodeAy = (isId: boolean) => {
  const key = isId ? 'id' : 'name'
  return (data: Record<string, unknown>) =>
    getDepartment({
      ...data,
      [key]: isId ? data.departmentId : data.departmentName,
      departmentId: undefined,
      departmentName: undefined,
    }).then((res) => {
      return {
        data: {
          data: {
            ...res.data.data,
            departments: res.data.data.departments.map((elm) => ({
              id: elm.id,
              name: isId ? elm.id : elm.name,
            })),
          },
        },
      }
    })
}

const getDepartmentAddress = (
  data: ParamsTableFilters & { departmentAddress: string }
) => {
  return getDepartment({
    ...data,
    address: data.departmentAddress,
    departmentAddress: undefined,
  }).then((res) => {
    return {
      data: {
        data: {
          ...res.data.data,
          departments: res.data.data.departments.map((elm) => ({
            id: elm.id,
            name: elm.address || 'Нет адреса',
          })),
        },
      },
    }
  })
}

const getDepartmentDirector = (
  data: ParamsTableFilters & { departmentDirector: string }
) => {
  return getDepartment({
    ...data,
    director: data.departmentDirector,
    departmentDirector: undefined,
  }).then((res) => {
    return {
      data: {
        data: {
          ...res.data.data,
          departments: res.data.data.departments.map((elm) => ({
            id: elm.id,
            name: elm.director || 'Нет руководителя подразделения',
          })),
        },
      },
    }
  })
}

const getContractorsTin = (
  data: ParamsTableFilters & { contractorInn: string }
) => {
  return getContractor({
    ...data,
    tin: data.contractorInn,
    contractorInn: undefined,
  }).then((res) => {
    return {
      data: {
        data: {
          ...res.data.data,
          contractors: res.data.data.contractors.map((elm) => ({
            id: elm.id,
            name: elm.tin,
          })),
        },
      },
    }
  })
}

export const listCallbacks = {
  fullName: {
    callback: getUsers('userFullName'),
    path: 'users',
  },

  managersIds: {
    callback: (data: ParamsTableFilters & { managerFullName: string }) =>
      getManager({
        ...data,
        name: data.managerFullName,
        managerFullName: undefined,
      }),
    path: 'managers',
  },

  contractorsName: {
    callback: (data: ParamsTableFilters & { contractorName: string }) => {
      return getContractor({
        ...data,
        name: data.contractorName,
        contractorName: undefined,
      })
    },
    path: 'contractors',
  },

  contractorIds: {
    callback: getContractorsTin,
    path: 'contractors',
  },

  login: {
    callback: getUsers('userLogin'),
    path: 'users',
  },

  email: {
    callback: getUsers('userEmail'),
    path: 'users',
  },

  departmentAddress: {
    callback: getDepartmentAddress,
    path: 'departments',
  },

  departmentDirector: {
    callback: getDepartmentDirector,
    path: 'departments',
  },

  departmentId: {
    callback: getCodeAy(true),
    path: 'departments',
  },

  departmentName: {
    callback: getCodeAy(false),
    path: 'departments',
  },

  regionIds: {
    callback: (data: ParamsTableFilters & { regionName: string }) =>
      getRegion({ ...data, name: data.regionName, regionName: undefined }),
    path: 'regions',
  },

  groups: {
    callback: (params: ParamsTableFilters & { groupName: string }) => {
      return getGroupsByName({
        ...params,
        groupName: params.groupName ? [params.groupName] : undefined,
      })
    },
    path: 'groups',
  },

  phone: {
    callback: getUsers('userPhone'),
    path: 'users',
  },

  name: {
    callback: getUsers('userFirstName'),
    path: 'users',
  },
  surname: {
    callback: getUsers('userLastName'),
    path: 'users',
  },
  patronymic: {
    callback: getUsers('userPatronymic'),
    path: 'users',
  },
}

export const listKeys: {
  key: keyof TableFiltersSelectionType
  listKey: keyof TableFiltersSelectionType
  ignoreListKey: keyof TableFiltersSelectionType
  pickAll: keyof TableFiltersSelectionType
  objectKey: ListOfKeysForFilters
  label: string
}[] = [
  {
    key: 'fullName',
    listKey: 'fullNameList',
    ignoreListKey: 'fullNameIgnoreList',
    pickAll: 'fullNamePickAll',
    objectKey: 'fullName',
    label: 'Поиск',
  },
  {
    key: 'managerFullName',
    listKey: 'managersIds',
    ignoreListKey: 'managersIgnoreIds',
    pickAll: 'managerFullNamePickAll',
    objectKey: 'managersIds',
    label: 'Поиск по менеджеру',
  },
  {
    key: 'contractorName',
    listKey: 'contractorsIds',
    ignoreListKey: 'contractorsIgnoreIds',
    objectKey: 'contractorsName',
    pickAll: 'contractorNamePickAll',
    label: 'Поиск по контрагенту',
  },
  {
    key: 'login',
    listKey: 'loginList',
    ignoreListKey: 'loginIgnoreList',
    pickAll: 'loginPickAll',
    objectKey: 'login',
    label: 'Поиск по логину',
  },
  {
    key: 'email',
    listKey: 'emailList',
    ignoreListKey: 'emailIgnoreList',
    pickAll: 'emailPickAll',
    objectKey: 'email',
    label: 'Поиск по email',
  },
  {
    key: 'departmentAddress',
    listKey: 'departmentAddressList',
    ignoreListKey: 'departmentAddressIgnoreList',
    pickAll: 'departmentAddressPickAll',
    objectKey: 'departmentAddress',
    label: 'Поиск по адресу подразделения',
  },
  {
    key: 'departmentDirector',
    listKey: 'departmentDirectorList',
    ignoreListKey: 'departmentDirectorIgnoreList',
    objectKey: 'departmentDirector',
    pickAll: 'departmentDirectorPickAll',
    label: 'Поиск по руководителю подразделения',
  },
  {
    key: 'departmentName',
    listKey: 'departmentNameList',
    ignoreListKey: 'departmentNameIgnoreList',
    pickAll: 'departmentNamePickAll',
    objectKey: 'departmentName',
    label: 'Подразделения / Код АУ',
  },
  {
    key: 'departmentId',
    listKey: 'departmentIds',
    ignoreListKey: 'departmentIgnoreIds',
    pickAll: 'departmentIdPickAll',
    objectKey: 'departmentId',
    label: 'Код АУ',
  },
  {
    key: 'contractorInn',
    listKey: 'contractorInnList',
    ignoreListKey: 'contractorInnIgnoreList',
    pickAll: 'contractorInnPickAll',
    objectKey: 'contractorIds',
    label: 'ИНН',
  },
  {
    key: 'regionName',
    listKey: 'regionIds',
    ignoreListKey: 'regionIgnoreIds',
    objectKey: 'regionIds',
    pickAll: 'regionNamePickAll',
    label: 'Регион',
  },
  {
    key: 'groupName',
    listKey: 'groups',
    ignoreListKey: 'groupsIgnoreList',
    objectKey: 'groups',
    pickAll: 'groupNamePickAll',
    label: 'Группы',
  },
  {
    key: 'phone',
    listKey: 'phoneList',
    ignoreListKey: 'phoneIgnoreList',
    pickAll: 'phonePickAll',
    objectKey: 'phone',
    label: 'Номер телефона',
  },

  // DOP
  {
    key: 'name',
    listKey: 'nameList',
    ignoreListKey: 'nameIgnoreList',
    objectKey: 'name',
    pickAll: 'namePickAll',
    label: 'Имя',
  },
  {
    key: 'surname',
    listKey: 'surnameList',
    ignoreListKey: 'surnameIgnoreList',
    objectKey: 'surname',
    pickAll: 'surnamePickAll',
    label: 'Фамилия',
  },
  {
    key: 'patronymic',
    listKey: 'patronymicList',
    ignoreListKey: 'patronymicIgnoreList',
    pickAll: 'patronymicPickAll',
    objectKey: 'patronymic',
    label: 'Отчество',
  },
]
