import { AxiosProgressEvent } from 'axios'
import { httpCore, httpCoreMultipart } from 'shared/configs/instance'
import { snackHelper } from 'shared/lib/showSnack'

export type GetActiveMaterialProps = {
  folderId?: number
  projectId?: number | undefined
  courseId?: number
  isKnowledge?: boolean
  parentId?: number | string
  entityTypeId?: number
  filterName?: string
  skip?: number
  take?: number
  sortParams?: unknown
  pageNumber?: number
  pageSize?: number
  isFavorite?: boolean
}

export type CreateMaterialApiProps = {
  projectId?: number
  parentId?: number
  name: string
  appointment?: boolean
  isKnowledgeBase?: boolean
  folderId?: number
  file?: any
  isCatalog?: boolean
  entityTypeId?: number
}

export type UploadFileMaterial = {
  parentId?: number
  entityTypeId: number
  projectId?: number
  file: File
}

export type UpdateFileMaterial = {
  materialContentId: number
  file: File
  changeName: boolean
}

export type UpdateMaterialType = {
  id: number
  parentId: number
  name: string
  entityTypeId: number
  projectId: number
  isFavorite: boolean
  description: string
}

export type UpdateFavoriteMaterialType = {
  isFavorite: boolean
  materialIds: number[]
}

export type UpdateFixedMaterialType = {
  isFixed: boolean
  materialIds: number[]
}

export const getMaterialContent = (data: { folderId?: number }) =>
  httpCore.get('material', { data })

export const getActiveMaterial = (data: GetActiveMaterialProps) =>
  httpCore.get(`material/active`, { params: data })

export const createMaterialApi = (data: CreateMaterialApiProps) =>
  httpCoreMultipart.post('material', data)

export const deleteMaterialApi = (data: { materialId: number }) =>
  httpCore.delete(`material/${data.materialId}`)

export const updateMaterial = (data: UpdateMaterialType) =>
  httpCore.put(`material/${data.id}`, data)

export const updateFavoriteMaterial = (data: UpdateFavoriteMaterialType) =>
  httpCore.put(`material/favorite/`, data)

export const updateFixedMaterial = (data: UpdateFixedMaterialType) =>
  httpCore.put(`material/fixed/`, data)

export const getCourseOfCatalog = (data: any) =>
  httpCore.get(`material/courses/available`, { params: data })

export const getCourseOfCatalogAll = (data: any) =>
  httpCore.get(`material/courses/allCourses`, { params: data })

// being developed
export const getSearchMaterial = (params: {
  filterName: string
  pageNumber?: number
  pageSize?: number
  projectId?: number
}) => httpCore.get('/material/active', { params })

export const uploadFileMaterial = async (
  data: UploadFileMaterial,
  callback?: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    return httpCoreMultipart.post('/material/upload/file', data, {
      onUploadProgress: callback,
    })
  } catch (error) {
    return error
  }
}

export const updateFileMaterial = async (
  data: UpdateFileMaterial,
  callback?: (progressEvent: AxiosProgressEvent) => void
) => {
  try {
    const res = httpCoreMultipart.post('/material/content/file', data, {
      onUploadProgress: callback,
    })
    snackHelper.success('Контент материал успешно обновлен')
    return res
  } catch (error) {
    snackHelper.error('Ошибка обновление контент материала')
    return error
  }
}

export const getCourseById = (materialId: string) =>
  httpCore.get(`material/${materialId}`)

// Групповые действия -------------------------

export interface MultipleUpdateI {
  materials: {
    id: number
    isFavorite: boolean
    parentId: number
    name: string
    entityTypeId: number
    projectId: number
  }[]
}

export const multipleUpdate = (data: MultipleUpdateI) =>
  httpCore.put('material/array', data)

export const multipleDelete = (data: { materialIds: number[] }) =>
  httpCore.delete('material/array', { data })

export const multipleCopy = (data: { materialIds: number[] }) =>
  httpCore.post('material/copy', data)
