import React, { useState, FC, useEffect } from 'react'
import { Chip, ListItem, Paper, TextField } from '@mui/material'
import { BORDER_RADIUS } from 'shared/lib/consts'

interface MultiValueInputProps {
  element: { label: string; name: string }
  state: string[]
  setState: (tags: string[]) => void
}

const MultiValueInput: FC<MultiValueInputProps> = ({
  element,
  state,
  setState,
}) => {
  const [value, setValue] = useState<string>('')

  const onKeyPress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      value !== (null || '') &&
        !state.includes(value) &&
        setState([...state, value])
      setValue('')
    }
  }

  const handleDelete = (chipToDelete: string) => () => {
    setState(state.filter((chip: string) => chip !== chipToDelete))
  }

  return (
    <>
      <TextField
        id={element.name}
        label={element.label}
        name={element.name}
        type="text"
        fullWidth
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        onKeyDown={onKeyPress}
        sx={{ '& .MuiOutlinedInput-root': { borderRadius: BORDER_RADIUS } }}
      />
      <Paper
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          listStyle: 'none',
          m: 0,
        }}
        component="ul"
      >
        {state &&
          state.map((data: string) => {
            return (
              <ListItem key={data} sx={{ p: 0.5, width: 'auto' }}>
                <Chip label={data} onDelete={handleDelete(data)} />
              </ListItem>
            )
          })}
      </Paper>
    </>
  )
}

export default MultiValueInput
