import { coreApi } from 'shared/api/core/coreApi'
import { ResponseTypeWrapper } from 'shared/api/core/types'
import { ReminderResponse } from './types'

export const reminderApi = coreApi.injectEndpoints({
  endpoints(build) {
    return {
      getReminders: build.query<
        ResponseTypeWrapper<ReminderResponse>,
        number | string
      >({
        query: (notificationId) => ({
          url: `reminder/${notificationId}`,
          method: 'get',
        }),

        providesTags: ['REMINDER_LIST'],
      }),
    }
  },
  overrideExisting: false,
})

export const { useGetRemindersQuery } = reminderApi
