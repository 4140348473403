import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGroups, IValuesForGroup } from './smartGroupTypes'

interface IInitialState {
  openDrawer: boolean
  openAddUsersModal: boolean
  groups: IGroups[]
  conditions?: IValuesForGroup[]
}

const initialState: IInitialState = {
  openDrawer: false,
  openAddUsersModal: false,
  groups: [
    {
      id: 0,
      values: { id: 0, localId: '0', key: 0, value: [], nesting: 0 },
    },
  ],
  conditions: [],
}

export const smartGroupSlice = createSlice({
  name: 'smart-group',
  initialState,
  reducers: {
    setOpenAddUsersModalRed(state, action: PayloadAction<boolean>) {
      state.openAddUsersModal = action.payload
    },
    setDrawer(state, action: PayloadAction<boolean>) {
      state.openDrawer = action.payload
    },
    setGroups(state, action: PayloadAction<any[]>) {
      state.groups = action.payload
    },
    setConditions(state, action: PayloadAction<any[]>) {
      state.conditions = action.payload
    },
    clearGroups(state) {
      state.groups = [
        {
          id: 0,
          values: { id: 0, localId: '0', key: 0, value: [], nesting: 0 },
        },
      ]
    },
  },
})

export const {
  setDrawer,
  setGroups,
  setConditions,
  clearGroups,
  setOpenAddUsersModalRed,
} = smartGroupSlice.actions
export default smartGroupSlice.reducer
