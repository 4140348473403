import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AutoRules, CatalogI, SelectValueI } from './courseTypes'

interface DepartmentState {
  activeStep: number

  // Updaters
  accessControlTabUpdater: boolean

  // access control tab states
  selectCatalogs: SelectValueI | SelectValueI[]
  catalogs: CatalogI[]
  selectValues: any
  divisions: any
  autoAssignCourse: any

  // appointment tab

  listRules: AutoRules[]

  isCourseDateRange: boolean
}

const initialState: DepartmentState = {
  activeStep: 0,

  accessControlTabUpdater: true,

  autoAssignCourse: 'init',

  // access control tab states
  selectCatalogs: [],
  selectValues: 'init',
  catalogs: [],
  divisions: [],

  listRules: [],

  isCourseDateRange: true,
}

export const courseSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setIsCourseDateRange: (state, action: PayloadAction<boolean>) => {
      state.isCourseDateRange = action.payload
    },
    setAutoAssignCourse: (state, action) => {
      state.autoAssignCourse = action.payload
    },
    setActiveStepRed: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },

    setListRules: (state, action: PayloadAction<AutoRules[]>) => {
      state.listRules = action.payload
    },

    clearUpdaters: (state) => {
      state.accessControlTabUpdater = true

      // access control tab states
      state.selectCatalogs = []
      state.selectValues = 'init'
      state.catalogs = []
      state.divisions = []
    },

    setAccessControlTabUpdater: (state, action: PayloadAction<boolean>) => {
      state.accessControlTabUpdater = action.payload
    },
    setSelectCatalogs: (
      state,
      action: PayloadAction<SelectValueI | SelectValueI[]>
    ) => {
      state.selectCatalogs = action.payload
    },
    setCatalogs: (state, action: PayloadAction<CatalogI[]>) => {
      state.catalogs = action.payload
    },
    setSelectValues: (state, action) => {
      state.selectValues = action.payload
    },
    setDivisions: (state, action) => {
      state.divisions = action.payload
    },
  },
})

export const { actions: courseActions } = courseSlice
export const { reducer: courseReducer } = courseSlice
