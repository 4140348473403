import { httpCore } from 'shared/configs/instance'

export const createAccessControlCourse = (data: any) =>
  httpCore.post('course/access-control', data)

export const updateAccessControlCourse = (data: any) =>
  httpCore.put(`course/access-control/${data.id}`, data)

export const getAccessControlCourse = (data: { materialId: any }) =>
  httpCore.get(`course/${data.materialId}/access-control`)

export const getCatalogsByIdCourse = (materialId: any) =>
  httpCore.get(`catalog/material/${materialId}`)

export const deleteMaterialsFromCatalog = (data: any) =>
  httpCore.post('catalog/remove/material', data)

export const updateSelfRegistration = (data: {
  materialId: number
  selfRegistrationForCourse: boolean
}) => httpCore.put('course/access-control/self-registration', data)
