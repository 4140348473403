import {
  UIFlexBetweenBox,
  UIFlexStartBox,
} from '../../../../shared/UI/UIFlexBox/UIFlexBox'
//mui
import { DateTimePicker } from '@mui/x-date-pickers'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material'
import moment, { Moment } from 'moment'

const UIFlexBetweenBoxCondition = styled(UIFlexBetweenBox)(
  ({ updated }: { updated: boolean }) => {
    return {
      p: 0,
      width: '30%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      opacity: updated ? 1 : 0,
      visibility: updated ? 'visible' : 'hidden',
      transition: 'all 0.1s ease',
    }
  }
)

const ParamsAssignCourse = ({
  baseDateValue,
  setBaseDateValue,
  upToDateValue,
  setUpToDateValue,
  accessDeniedAfterExpiration,
  setAccessDenied,
  updated,
  setUpdated,
  cancelSettings,
}: any) => {
  const blockWidth = '40%'

  const onBaseDate = (newValue: any) => {
    if (!updated) setUpdated(true)
    setBaseDateValue(newValue)
  }

  const onUpToDate = (newValue: Date | Moment | null) => {
    if (!updated) setUpdated(true)
    setUpToDateValue(newValue)
  }

  const onAccessChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (!updated) setUpdated(true)
    setAccessDenied(ev.target.checked)
  }

  return (
    <Stack spacing={2}>
      <Typography fontWeight="bold" sx={{ mb: 3 }}>
        Параметры Назначения
      </Typography>
      <UIFlexStartBox sx={{ p: 0, width: blockWidth }}>
        <Typography minWidth={'300px'}>Дата начала обучения МСК</Typography>
        <DateTimePicker value={moment(baseDateValue)} onChange={onBaseDate} />
      </UIFlexStartBox>
      <UIFlexStartBox sx={{ p: 0, width: blockWidth }}>
        <Typography minWidth={'300px'}>Дата окончания обучения МСК</Typography>
        <DateTimePicker value={moment(upToDateValue)} onChange={onUpToDate} />
      </UIFlexStartBox>
      <UIFlexStartBox sx={{ p: 0, width: blockWidth }}>
        <Typography>
          <Checkbox
            sx={{ p: 0, pr: 1 }}
            checked={accessDeniedAfterExpiration}
            onChange={onAccessChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          Запретить доступ после окончания срока выполнения
        </Typography>
      </UIFlexStartBox>
    </Stack>
  )
}

export default ParamsAssignCourse
