import { createAsyncThunk } from '@reduxjs/toolkit'
import { RejectedWithValueActionFromAsyncThunk } from '@reduxjs/toolkit/dist/matchers'
import {
  ActiveAllContentBody,
  getActiveAllContent,
  GetActiveContentResponse,
} from 'shared/api/requests/contentMaterial'

export const fetchActiveContents = createAsyncThunk<
  GetActiveContentResponse,
  ActiveAllContentBody
>('content/fetchActiveContents', async (data, thunkAPI) => {
  try {
    const response = await getActiveAllContent(data)
    return response.data.data
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
  }
})
