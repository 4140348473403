import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchSearchMaterial } from './searchMaterialThunk'
import { SearchMaterialItem } from './searchMaterialTypes'

interface LearnState {
  searchMaterials: SearchMaterialItem[] | null
  pageMaterial: number
  pageSizeMaterial: number
  totalPagesMaterial: number
  isLoading: boolean
  error: string
  focus: boolean
  searchValue: string
  prevValue: string
  totalCount: number
}

const initialState: LearnState = {
  searchMaterials: null,
  pageMaterial: 0,
  pageSizeMaterial: 20,
  totalPagesMaterial: 1,
  isLoading: false,
  error: '',
  focus: false,
  searchValue: '',
  prevValue: '',
  totalCount: 0,
}

export const searchMaterialSlice = createSlice({
  name: 'learn-materials',
  initialState,
  reducers: {
    setSearchMaterials: (state, action) => {
      state.searchMaterials = action.payload
    },
    clearSearch: (state) => {
      state.searchMaterials = null
      state.totalCount = 0
    },
    onFocus: (state) => {
      state.focus = true
    },
    onBlur: (state) => {
      state.focus = false
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    setPrevValue: (state, action) => {
      state.prevValue = action.payload
    },
  },
  extraReducers: {
    [fetchSearchMaterial.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false
      state.error = ''
      state.searchMaterials = action.payload.data.materials
      state.pageMaterial = action.payload.data.page
      state.pageSizeMaterial = action.payload.data.pageSize
      state.totalPagesMaterial = action.payload.data.totalPages
      state.totalCount = action.payload.data.totalCount
    },
    [fetchSearchMaterial.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchSearchMaterial.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
      state.searchMaterials = null
      state.pageMaterial = 0
      state.totalPagesMaterial = 0
      state.totalCount = 0
    },
  },
})

export const searchMaterial = searchMaterialSlice.reducer
export const searchActions = searchMaterialSlice.actions
