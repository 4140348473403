import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ActiveProjectElement } from 'shared/api/core/types'

type SelectedFolderProps = {
  name: string
  id?: number
  createdAt?: any
  entityType?: string
  notificationTemplateId?: number
}

export type CourseTabsValueType =
  | 'structure'
  | 'main'
  | 'notification'
  | 'access-control'
  | 'end'
  | 'appointment'

interface BreadcrumbsProps {
  breadcrumbs: string
  selectedProject: ActiveProjectElement | null
  selectedFolder: SelectedFolderProps | null
  selectedCourse: SelectedFolderProps | null
  selectedTabProject: any
  selectedTabCourse: CourseTabsValueType
  selectedTabUsers: any
  selectedTabSettings: number
  rowPageCount: number
}

const initialState: BreadcrumbsProps = {
  breadcrumbs: 'Учебные материалы',
  selectedProject: null,
  selectedFolder: null,
  selectedCourse: null,
  selectedTabProject: 1,
  selectedTabCourse: 'main',
  selectedTabUsers: 0,
  selectedTabSettings: 0,
  rowPageCount: 20,
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setRowPageCount(state, action) {
      state.rowPageCount = action.payload
    },
    breadcrumbsChange(state, action) {
      state.breadcrumbs = action.payload
    },
    selectedProjectChange(state, action) {
      state.selectedProject = action.payload
    },
    selectedFolderChange(state, action) {
      state.selectedFolder = action.payload
    },
    selectedFolderClear(state) {
      state.selectedFolder = null
    },
    selectedCourseChange(state, action) {
      state.selectedCourse = action.payload
    },
    selectedTabProjectChange(state, action) {
      state.selectedTabProject = action.payload
    },
    selectedTabCourseChange(state, action: PayloadAction<CourseTabsValueType>) {
      state.selectedTabCourse = action.payload
    },
    selectedTabUsersChange(state, action) {
      state.selectedTabUsers = action.payload
    },
    selectedTabSettingsChange(state, action) {
      state.selectedTabSettings = action.payload
    },
  },
})

export default breadcrumbsSlice.reducer
export const {
  setRowPageCount,
  breadcrumbsChange,
  selectedProjectChange,
  selectedFolderChange,
  selectedCourseChange,
  selectedTabProjectChange,
  selectedTabCourseChange,
  selectedTabUsersChange,
  selectedFolderClear,
  selectedTabSettingsChange,
} = breadcrumbsSlice.actions
