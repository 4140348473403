import { createTheme } from '@mui/material/styles'
import { ruRU } from '@mui/material/locale'

// assets
import InterRegularTTF from 'shared/assets/fonts/Inter-Regular.ttf'
import { BORDER_RADIUS } from 'shared/lib/consts'

export const customTheme = createTheme(
  {
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), local('Inter-Regular'), url(${InterRegularTTF}) format("truetype");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
      },
      MuiTextField: {
        defaultProps: { autoComplete: 'off' },
        styleOverrides: {
          root: {
            borderRadius: BORDER_RADIUS * 4,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: BORDER_RADIUS * 4,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: `${BORDER_RADIUS * 4}px`,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            zIndex: 999999,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: BORDER_RADIUS * 4,
            textTransform: 'none',
          },
        },
      },
    },
    palette: {
      table: {
        tableBg: '#F7FAFC',
        tableBorder: '#E2E8F0',
      },
      background: {
        default: '#f3f3f3',
      },
      primary: {
        main: '#1a3652',
        light: 'rgb(150 223 154 / 50%)',
        dark: '#49854c',
      },
      secondary: {
        main: '#d84315',
        light: '#ff7543',
        dark: '#9f0000',
      },
      text: {
        primary: '#000000',
        secondary: '#000000',
      },
    },
  },
  ruRU
)
