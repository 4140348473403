import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { uploadFileMaterial } from './learnMaterialThunks'
import ILearnMaterial from './learnMaterialsTypes'

interface LearnState {
  isLoading: boolean
  error: string
}

const initialState: LearnState = {
  isLoading: false,
  error: '',
}

export const uploadMaterialSlice = createSlice({
  name: 'learn-materials-upload',
  initialState,
  reducers: {},
  extraReducers: {
    [uploadFileMaterial.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false
      state.error = ''
    },
    [uploadFileMaterial.pending.type]: (state) => {
      state.isLoading = true
    },
    [uploadFileMaterial.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default uploadMaterialSlice.reducer
