import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchManager } from './managerThunks'
import { IManager, Managers, PaginationProps } from './managerTypes'

interface ManagerState {
  managers: IManager[]
  paginationInfo: PaginationProps | null
  isLoading: boolean
  isError: string
}

const initialState: ManagerState = {
  managers: [],
  paginationInfo: null,
  isLoading: false,
  isError: '',
}

export const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchManager.fulfilled.type]: (state, action: PayloadAction<Managers>) => {
      state.isLoading = false
      state.isError = ''
      state.managers = action.payload.managers
      state.paginationInfo = action.payload.paginationInfo
    },
    [fetchManager.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchManager.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false
      state.isError = action.payload
    },
  },
})

export default managerSlice.reducer
