export interface TimeObject {
  value: number
  unit: 'day' | 'hour' | 'minute'
  id: number
  [key: string]: any
}

export const getMinutesReminder = (timeArr: TimeObject[]): string[] => {
  const unitConversions: Record<string, number> = {
    day: 1440,
    hour: 60,
    minute: 1,
  }

  const resultArr: string[] = []

  timeArr.forEach((obj) => {
    const unit = obj.unit.toLowerCase()
    const value = obj.value
    const conversionFactor = unitConversions[unit]
    const totalMinutes = value * conversionFactor
    resultArr.push(totalMinutes.toString())
  })

  return resultArr
}
