import {
  Stack,
  Box,
  Typography,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
} from '@mui/material'
import { FabStyled } from 'shared/UI/FabStyled/FabStyled'
import { PaperStyled } from 'shared/UI/PaperStyled/PaperStyled'
import { UIFlexBetweenBox } from 'shared/UI/UIFlexBox/UIFlexBox'
import { handleClames } from 'shared/lib/utils'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import { useEffect, useState } from 'react'
import { postReminder } from 'shared/api/requests/notifications'
import { getReminderIntervals } from 'shared/lib/getReminderIntervals'
import { getMinutesReminder } from 'shared/lib/getMinutesReminder'
import { useAppDispatch } from 'shared/hooks/redux'
import { showSnack } from 'store/snackbar/snackbarSlice'
import { useGetRemindersQuery } from '../model/reminderApi'
import { ReminderElement } from '../model/types'

interface ReminderCRUDProps {
  className?: string
  notificationId?: string | number
}

export const ReminderCRUD = ({ notificationId }: ReminderCRUDProps) => {
  const dispatch = useAppDispatch()

  const { data } = useGetRemindersQuery(
    notificationId === undefined ? '' : notificationId,
    {
      skip: notificationId === undefined,
    }
  )

  const [reminderList, setReminderList] = useState<ReminderElement[]>([])

  const handleAddNotification = () => {
    if (reminderList.length >= 5) {
      return
    }
    const startIndex = {
      value: 1,
      unit: 'day' as ReminderElement['unit'],
      id: 1,
    }
    const lastIdx = reminderList.length - 1
    const value =
      reminderList.length >= 1
        ? { ...startIndex, id: reminderList[lastIdx].id + 1 }
        : startIndex
    setReminderList([...reminderList, value])
  }

  const handleChangeReminderValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => {
    const changedValue = reminderList.map((item) => {
      return {
        ...item,
        value: item.id === id ? Number(event.target.value) : item.value,
      }
    })
    setReminderList(changedValue)
  }

  const handleChangeReminderUnit = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => {
    const changedValue = reminderList.map((item) => {
      return {
        ...item,
        unit:
          item.id === id
            ? (event.target.value as ReminderElement['unit'])
            : item.unit,
      }
    })
    setReminderList(changedValue)
  }

  const handleDeleteNotification = (id: number) => {
    const deleteNotification = reminderList.filter(
      (item, index) => id !== item.id
    )
    setReminderList(deleteNotification)
  }

  const handleSaveReminder = async () => {
    try {
      if (notificationId === undefined)
        throw new Error('There is no notification')

      const reminderTime = getMinutesReminder(reminderList)

      await postReminder({ notificationId, reminderTime })

      dispatch(showSnack({ messageSnack: 'Напоминание успешно сохранено' }))
    } catch (error) {
      dispatch(
        showSnack({
          messageSnack: 'Не удалось сохранить напоминания, попробуйте еще раз',
          typeSnack: 'error',
        })
      )
    }
  }

  useEffect(() => {
    if (!notificationId) return

    if (data) {
      const newReminder = getReminderIntervals(data.data.reminder)
      setReminderList(newReminder)
    }
  }, [data, notificationId])

  return (
    <PaperStyled>
      <Stack spacing={2} mb={2}>
        <Typography fontSize={14} fontWeight={500}>
          Напоминание
        </Typography>
        <Typography fontSize={12}>
          Напомнить пользователям о приближении срока завершения курса за
        </Typography>
        <Box display="flex" textAlign="center">
          <FabStyled onClick={handleAddNotification}>
            <AddIcon />
          </FabStyled>
          <Typography fontSize={16}>Добавить напоминание</Typography>
        </Box>
        <Box sx={{ width: '25%' }}>
          {reminderList.map((item, index) => (
            <UIFlexBetweenBox key={index}>
              <TextField
                onChange={(ev) => handleChangeReminderValue(ev, item.id)}
                size="small"
                type="number"
                value={item.value}
                sx={{ width: '40%', mr: 2 }}
                inputProps={{ min: '0' }}
              />
              <TextField
                value={item.unit}
                size="small"
                select
                fullWidth
                sx={{ mr: 1 }}
                onChange={(ev) => handleChangeReminderUnit(ev, item.id)}
              >
                <MenuItem value="minute">минут</MenuItem>
                <MenuItem value="hour">часов</MenuItem>
                <MenuItem value="day">дней</MenuItem>
              </TextField>
              <Typography fontSize={12}>до срока завершения</Typography>
              <Tooltip title="Удалить элемент">
                <IconButton onClick={() => handleDeleteNotification(item.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </UIFlexBetweenBox>
          ))}
        </Box>
      </Stack>
      {handleClames('course.notifications.save') && (
        <Button onClick={handleSaveReminder} variant="contained">
          Сохранить
        </Button>
      )}
    </PaperStyled>
  )
}
