import { createAsyncThunk } from '@reduxjs/toolkit'
import { getSearchMaterial } from 'shared/api/requests/material'

export const fetchSearchMaterial = createAsyncThunk<
  any,
  {
    filterName: string
    pageNumber?: number
    pageSize?: number
    projectId?: number
  }
>('search/fetchSearchMaterial', async (params, thunkApi) => {
  try {
    const response = await getSearchMaterial(params)
    return response.data
  } catch (error) {
    return thunkApi.rejectWithValue('Не удалось ничего найти')
  }
})

// TODO добавить поиск по контент материалам, перенести в отдельный слайс
// export const fetchSearchActiveContent = createAsyncThunk<
//   any,
//   { contentName: string; pageNumber?: number; pageSize?: number, projectId?: number }
// >('search/fetchSearchContentMaterial', async (params, thunkApi) => {
//   try {
//     const response = await getSearchContentMaterial(params)
//     return response.data
//   } catch (error) {
//     return thunkApi.rejectWithValue('Не удалось ничего найти')
//   }
// })
