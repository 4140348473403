import axiosBaseQuery from 'shared/lib/axiosBaseQuery'
import { createApi } from '@reduxjs/toolkit/query/react'
import { showSnack } from 'store/snackbar/snackbarSlice'
import { CreateDivisionArgType, IDivision } from './types'
import { createRules } from 'shared/api/requests/division'

export const divisionsApi = createApi({
  reducerPath: 'divisionsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Divisions'],
  endpoints(build) {
    return {
      getDivisions: build.query<
        { data: IDivision[] },
        { pageSize: number; pageNumber: number }
      >({
        query: (params) =>
          `division?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
        providesTags: ['Divisions'],
      }),

      getDivisionsById: build.query<any, string>({
        query: (divisionId) => `division/${divisionId}`,
        providesTags: ['Divisions'],
      }),

      createDivision: build.mutation<{ data: number }, CreateDivisionArgType>({
        query: (data) => ({
          url: 'division',
          method: 'POST',
          data: data,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            const res = await queryFulfilled
            // await createRules({
            //   //   id: res.data.data,
            //   //   // departmentIds: arg.departmentIds,
            //   management?: arg.management,
            //   id: 0,
            //   divisionType: 0
            // })
            dispatch(showSnack({ messageSnack: 'Отдел успешно создан' }))
            dispatch(divisionsApi.util.invalidateTags(['Divisions']))
          } catch (error) {
            dispatch(
              showSnack({
                messageSnack: 'Ошибка создания отдела',
                typeSnack: 'error',
              })
            )
          }
        },
      }),
      updateDivision: build.mutation<
        { data: number },
        CreateDivisionArgType & { id: number }
      >({
        query: (data) => ({
          url: `division`,
          method: 'PUT',
          data: data,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            // if (arg.departmentIds.length > 0 || arg.contractorIds.length > 0) {
            if (arg.divisionType !== null && arg.divisionType !== undefined && arg.management.length > 0) {
              await createRules({
                id: arg.id,
                // departmentIds: arg.departmentIds,
                management: arg.management,
                divisionType: arg.divisionType,
              })
            }
            dispatch(showSnack({ messageSnack: 'Отдел успешно обновлен' }))
            dispatch(divisionsApi.util.invalidateTags(['Divisions']))
          } catch (error) {
            dispatch(
              showSnack({
                messageSnack: 'Ошибка обновления отдела',
                typeSnack: 'error',
              })
            )
          }
        },
      }),
      deleteDivision: build.mutation<void, number>({
        query: (id) => ({
          url: `division/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Divisions'],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(showSnack({ messageSnack: 'Отдел успешно обновлен' }))
          } catch (error) {
            dispatch(
              showSnack({
                messageSnack: 'Ошибка обновления отдела',
                typeSnack: 'error',
              })
            )
          }
        },
      }),
      delete: build.mutation<void, number>({
        query: (id) => ({
          url: `division/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Divisions'],
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled
            dispatch(showSnack({ messageSnack: 'Отдел успешно обновлен' }))
          } catch (error) {
            dispatch(
                showSnack({
                  messageSnack: 'Ошибка обновления отдела',
                  typeSnack: 'error',
                })
            )
          }
        },
      }),
    }
  },
})

export const {
  useGetDivisionsQuery,
  useGetDivisionsByIdQuery,
  useCreateDivisionMutation,
  useUpdateDivisionMutation,
  useDeleteDivisionMutation,
} = divisionsApi
