// mui imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// mui styled
import { PaperStyled } from '../../../shared/UI/PaperStyled/PaperStyled'

//mui icon
import { Collapse, Skeleton } from '@mui/material'
import {
  createNotificationTab,
  updateNotificationTab,
  assignNotifictionToMaterial,
} from '../../../shared/api/requests/notifications'
import { useAppDispatch, useAppSelector } from '../../../shared/hooks/redux'
import { showSnack } from '../../../store/snackbar/snackbarSlice'
import { useBindActionCreator } from 'shared/hooks/useBindActionCreator'
import { ReminderCRUD } from 'entities/ReminderCRUD'
import { NotificationTemplateSelector } from 'feature/NotificationTemplateSelector/ui/NotificationTemplateSelector'
import { TemplateList } from 'feature/NotificationTemplateSelector'
import { useGetNotificationQuery } from '../model/notificationApi'
import { coreUpdater } from 'shared/api/core/coreApi'
import { notificationAction } from '../model/notificationSlice'

export const NotificationBlock = ({
  materialId,
}: {
  materialId: string | number
}) => {
  const dispatch = useAppDispatch()

  const { data: notification } = useGetNotificationQuery(materialId as string)

  const selectedTemplateToUse = useAppSelector(
    (state) => state.notificationBlock.selectedTemplate
  )
  const selectedCourse = useAppSelector(
    (state) => state.breadCrumb.selectedCourse
  )

  const [selectedTemplate, setSelectedTemplate] = useBindActionCreator<
    typeof selectedTemplateToUse
  >(selectedTemplateToUse, notificationAction.setSelectedTemplate)

  const handleSaveTemplate = async () => {
    if (!selectedTemplate) return
    if (!notification) return
    if (!materialId) return

    if (!selectedTemplate.template) return

    const paramsNotification = {
      name: selectedTemplate.template,
      isNotificated: true,
      courseId: Number(materialId),
      arrangementId: 0,
      isUserAddToCourse: true,
      isCourseExpires: true,
    }
    const { reminder, ...notificationWithoutReminder } = notification

    try {
      const updateParams = {
        ...notificationWithoutReminder,
        name: selectedTemplate.template,
      }

      if (notification?.id) await updateNotificationTab(updateParams)
      if (!notification?.id) await createNotificationTab(paramsNotification)

      const assignParams = {
        materialId: Number(materialId),
        notificationTemplateId: selectedTemplate.id,
      }

      await assignNotifictionToMaterial(assignParams)

      dispatch(
        showSnack({
          messageSnack: 'Настройки уведомлений успешно созданы',
        })
      )
      coreUpdater.notification()
    } catch (error) {
      dispatch(
        showSnack({
          messageSnack: 'Ошибка создания настроек уведомления',
        })
      )
    }
  }

  const onAfterRequestGetTemplates = (templates: TemplateList) => {
    if (!selectedCourse?.notificationTemplateId) return
    if (!notification) return
    const foundTemplate = templates[selectedCourse?.notificationTemplateId]

    if (foundTemplate) {
      setSelectedTemplate(
        notification.name
          ? { ...foundTemplate, template: notification.name }
          : foundTemplate
      )
      return
    }

    const templateKeys = Object.keys(templates)
    const defaultTemplate = templates?.[Number(templateKeys[0])] || {}

    setSelectedTemplate(
      notification.name
        ? { defaultTemplate, template: notification.name }
        : defaultTemplate
    )
  }

  return (
    <>
      <PaperStyled>
        <Stack sx={{ maxWidth: '700px', width: '100%' }} spacing={2}>
          <Typography fontSize={14} fontWeight={500}>
            Уведомления о назначении{' '}
            {!notification?.id && (
              <span style={{ color: 'red' }}>(уведомления не сохранены)</span>
            )}
          </Typography>

          <Collapse
            in={Boolean(notification?.id !== undefined)}
            timeout="auto"
            unmountOnExit
          >
            <NotificationTemplateSelector
              value={selectedTemplate}
              onChange={(template) => setSelectedTemplate(template)}
              onAfterRequest={onAfterRequestGetTemplates}
            />
          </Collapse>

          <FormControlLabel
            control={<Checkbox defaultChecked edge="start" disableRipple />}
            label={
              <Typography gutterBottom fontSize={12}>
                Отправлять уведомление о назначении курса
              </Typography>
            }
          />

          <Box>
            <TextField
              value={selectedTemplate?.template}
              onChange={(ev) =>
                setSelectedTemplate({
                  ...selectedTemplate,
                  template: ev.target.value,
                })
              }
              placeholder="Уведомление"
              fullWidth
              variant="outlined"
              multiline
              rows={12}
            />
          </Box>
        </Stack>
        <Button onClick={handleSaveTemplate} variant="contained" sx={{ mt: 2 }}>
          Сохранить
        </Button>
      </PaperStyled>

      <Collapse in={Boolean(notification?.id)} timeout="auto" unmountOnExit>
        <ReminderCRUD notificationId={notification?.id} />
      </Collapse>
    </>
  )
}
