import { useAppDispatch } from 'shared/hooks/redux'
import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router'
import { pathActions } from 'store/pathSlice/pathSlice'

export const PathHistoryProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(pathActions.addPath(location.pathname + '?' + location.search))
  }, [location.pathname, location.search])

  return <>{children}</>
}
