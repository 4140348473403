export const AUTH = 'AUTH'

export interface IAuthUser {
  userId: string
  email: string
  fullName: string
}
export interface IAuth {
  authUser: IAuthUser | null
}
