import { AnyAction, bindActionCreators, Dispatch } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import { useAppDispatch } from './redux'

export function useBindActionCreator<T = unknown>(
  value: T,
  action: AnyAction,
  initValue?: T,
  deps: any[] = []
): readonly [value: T, setter: any] {
  const dispatch = useAppDispatch()

  return useMemo(
    () => [
      value === 'init' ? (initValue as T) : value,
      bindActionCreators(action, dispatch),
    ],
    [dispatch, value, ...deps]
  )
}
