import { getAuthUser } from '../../shared/api/requests/auth'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

// constants
import { AUTH } from './authTypes'

// actions
import { setAuthUser } from './authSlice'

export const authUser = createAsyncThunk(
  `${AUTH}/user`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAuthUser()
      dispatch(setAuthUser(response.data))
      return response.data
    } catch (error: any) {
      const err: AxiosError = error
      return rejectWithValue(err.message)
    }
  }
)
