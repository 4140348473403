import { httpCore } from 'shared/configs/instance'
import { ResponseUserAdditionalInfo, UserAdditionalInfoProps } from './types'

export const getUsersAdditionalInfo = (
  data?: Partial<UserAdditionalInfoProps>
) => {
  if (data?.multiSearch) data.multiSearch = data.multiSearch.trim()
  // if (data.params) data = { ...data.params }
  return httpCore.post<ResponseUserAdditionalInfo>('user/additionalInfo', data)
}
