import { Button, Checkbox, Stack, TextField, Typography } from '@mui/material'

import { DateTimePicker } from '@mui/x-date-pickers'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { BIG_DATE } from 'shared/lib/consts'
import moment from 'moment-timezone'
import { dateValidate } from 'shared/lib/dateValidate'
import { isAxiosError } from 'axios'
import { coreUpdater } from 'shared/api/core/coreApi'
import {
  updateAppointmentUser,
  reassignUser,
} from 'shared/api/requests/appointment'
import { UIFlexBetweenBox, UIFlexEndBox } from 'shared/UI/UIFlexBox/UIFlexBox'
import UIModal from 'shared/UI/UIModal/UIModal'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import { handleUpdateSnack, showSnack } from 'store/snackbar/snackbarSlice'
import { AppointmentUserElement } from 'feature/AppointmentUsersTable'

type SelectedType = Pick<
  AppointmentUserElement,
  'baseDate' | 'upToDate' | 'userId' | 'accessDeniedAfterExpiration'
>

type ModalUpdateAppointmentUserProps = {
  open: boolean
  title?: string
  setOpen: Dispatch<SetStateAction<boolean>>
  onAfterSave?: () => void
  materialIds: (string | number)[]
  isEdit?: boolean
}

type ModalUpdateAppointmentUserPropsWithNoUserId =
  ModalUpdateAppointmentUserProps & {
    userIds?: string[]
    selected: SelectedType[]
  }

type ModalUpdateAppointmentUserPropsWithUserId =
  ModalUpdateAppointmentUserProps & {
    userIds: string[]
    selected: Omit<SelectedType, 'userId'>[]
  }

type ModalProps =
  | ModalUpdateAppointmentUserPropsWithNoUserId
  | ModalUpdateAppointmentUserPropsWithUserId

export const ModalUpdateAppointmentUser = ({
  open,
  setOpen,
  title,
  selected,
  materialIds,
  onAfterSave,
  userIds,
  isEdit = true,
}: ModalProps) => {
  const dispatch = useAppDispatch()

  const updateSnack = useAppSelector((state) => state.snackbar.updateSnack)

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [loading, setLoading] = useState(false)

  const [baseDateValue, setBaseDateValue] = useState<moment.Moment | null>(
    moment(selected[0]?.baseDate)
  )
  const [upToDateValue, setUpToDateValue] = useState<moment.Moment | null>(
    () => {
      const upToDate = moment(selected[0]?.upToDate).clone()
      const diff = upToDate.diff(BIG_DATE, 'years')
      const isTomorrow = selected[0]?.upToDate ? diff > -1 : true
      return isTomorrow ? moment().add(1, 'day') : upToDate
    }
  )

  const [accessDeniedAfterExpiration, setAccessDenied] = useState<any>(
    selected[0]?.accessDeniedAfterExpiration
  )

  const handleSave = async () => {
    const validate = dateValidate({
      startDate: baseDateValue,
      endDate: upToDateValue,
      accessDeniedAfterExpiration,
    })

    if (validate !== true) {
      dispatch(showSnack(validate))
      return
    }

    setLoading(true)

    try {
      const data = {
        materialIds: materialIds,
        userIds: userIds ? userIds : selected.map((item: any) => item.userId),
        baseDate: baseDateValue!.clone().utc().format(),
        upToDate: upToDateValue!.clone().utc().format(),
        accessDeniedAfterExpiration,
      }

      if (isEdit) {
        const res = await updateAppointmentUser(data)
        if (isAxiosError(res)) throw new Error()
      }

      if (!isEdit) {
        const res = await reassignUser(data)
        if (isAxiosError(res)) throw new Error()
      }

      dispatch(
        showSnack({
          messageSnack: isEdit
            ? 'Параметры назначений успешно изменены'
            : 'Пользователь успешно переназначен',
        })
      )

      coreUpdater.appointmentUsers()
      timeoutRef.current = setTimeout(() => {
        dispatch(handleUpdateSnack(!updateSnack))
        setOpen(false)
        setLoading(false)
        if (onAfterSave) onAfterSave()
      }, 500)
    } catch (error: any) {
      dispatch(
        showSnack({
          messageSnack: isEdit
            ? 'Ошибка изменения параметров назначения, попробуйте еще раз'
            : 'Ошибка при переназначении, попробуйте еще раз',
          typeSnack: 'error',
        })
      )
      setLoading(false)
    }
  }

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    },
    []
  )

  return (
    <UIModal open={open} setOpen={setOpen} title={title} minWidth="700px">
      <Stack spacing={2}>
        {isEdit === false && (
          <Typography sx={{ mb: 3 }}>
            Внимание: при повторном назначении текущий прогресс по курсу
            переместится в историю назначений
          </Typography>
        )}
        {(isEdit || isEdit === undefined) && (
          <Typography fontWeight="bold" sx={{ mb: 3 }}>
            Параметры Назначения
          </Typography>
        )}
        <UIFlexBetweenBox>
          <Typography>Дата начала обучения МСК</Typography>
          <DateTimePicker
            value={moment(baseDateValue)}
            onChange={(newValue) => setBaseDateValue(newValue)}
            ampm={false}
          />
        </UIFlexBetweenBox>
        <UIFlexBetweenBox>
          <Typography>Дата окончания обучения МСК</Typography>
          <DateTimePicker
            value={moment(upToDateValue)}
            onChange={(newValue) => setUpToDateValue(newValue)}
            ampm={false}
          />
        </UIFlexBetweenBox>
        <Typography>
          <Checkbox
            checked={accessDeniedAfterExpiration}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setAccessDenied(ev.target.checked)
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
          Запретить доступ после окончания срока выполнения
        </Typography>
        <UIFlexEndBox>
          <Button onClick={handleSave} variant="contained" disabled={loading}>
            Сохранить
          </Button>
        </UIFlexEndBox>
      </Stack>
    </UIModal>
  )
}
