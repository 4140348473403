import { AlertColor } from '@mui/material'
import moment from 'moment-timezone'

export const dateValidate = ({
  startDate,
  endDate,
  accessDeniedAfterExpiration,
}: {
  startDate: moment.Moment | null
  endDate: moment.Moment | null
  accessDeniedAfterExpiration: boolean
}):
  | {
      messageSnack: string
      typeSnack?: AlertColor
    }
  | true => {
  if (!startDate) {
    return {
      messageSnack: 'Выберите дату начала курса',
      typeSnack: 'error',
    }
  }

  if (!endDate) {
    return {
      messageSnack: 'Выберите дату окончания курса',
      typeSnack: 'error',
    }
  }

  const diff = startDate.diff(endDate, 'hours')

  if (diff > -19) {
    return {
      messageSnack:
        'Дата окончания курса должна быть отложена, не раньше чем на 1 день после даты начала',
      typeSnack: 'error',
    }
  }

  return true
}
