import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchGroup } from './groupsThunks'
import { Groups } from './groupsTypes'

const initialState: any = {
  groups: undefined,
  pageNumber: 1,
  pageSize: 20,
  totalPages: 20,
  totalCount: 0,
  isLoading: false,
  error: '',
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    changePageGroup(state, action) {
      state.pageNumber = action.payload
    },
  },
  extraReducers: {
    [fetchGroup.fulfilled.type]: (state, action: PayloadAction<Groups>) => {
      state.groups = action.payload.groups
      state.pageNumber = action.payload.pageNumber
      state.pageSize = action.payload.pageSize
      state.totalPages = action.payload.total
      state.totalCount = action.payload.totalCount
      state.isLoading = false
      state.error = ''
    },
    [fetchGroup.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchGroup.rejected.type]: (state, action: PayloadAction<any>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export default groupsSlice.reducer
export const { changePageGroup } = groupsSlice.actions
