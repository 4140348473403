import { coreApi } from 'shared/api/core/coreApi'
import { NotificationElement } from './types'

export const notificationApi = coreApi.injectEndpoints({
  endpoints(build) {
    return {
      getNotification: build.query<NotificationElement, number | string>({
        query: (materialId) => ({
          url: `notification/${materialId}/list-by-course`,
          method: 'get',
        }),
        providesTags: ['NOTIFICATION'],
      }),
    }
  },
  overrideExisting: false,
})

export const { useGetNotificationQuery } = notificationApi
