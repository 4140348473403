import { Box, Button, Typography } from '@mui/material'
import UIModal from 'shared/UI/UIModal/UIModal'
import { Dispatch, SetStateAction } from 'react'

export const ModalAlreadyAdded = ({
  title,
  open,
  setOpen,
  added,
  onOk,
}: {
  title?: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  added: string[]
  onOk(): void
}) => {
  return (
    <UIModal
      open={open}
      setOpen={setOpen}
      title="Предупреждение"
      justifyContent="flex-start"
      minWidth="300px"
      minHeight="200px"
    >
      <Typography sx={{ overflowY: 'auto', maxHeight: '500px' }}>
        {title ||
          'Данные пользователи не были назначены, так как были назначены ранее:'}{' '}
        <strong>{added.join(', ')}</strong>
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" sx={{ mt: 6 }} onClick={onOk}>
          Ок
        </Button>
      </Box>
    </UIModal>
  )
}
