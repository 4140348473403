import { Box, Stack, Tooltip } from '@mui/material'
import { customTheme } from 'shared/configs/theme'
import Checkbox from '@mui/material/Checkbox'
import { IOption } from '../SearchColumn/SearchColumn'
import { memo } from 'react'

const SearchSelectItem = memo(
  ({
    option,
    onSelect,
    selectedValue,
    indeterminate,
  }: {
    option: IOption
    onSelect: (option: IOption) => void
    selectedValue: Record<string | number, IOption>
    indeterminate?: boolean
  }) => {
    const label =
      option.name.length > 30 ? option.name.slice(0, 30) + '...' : option.name

    const subLabel =
      option.subName && option.subName.length > 30
        ? option.subName.slice(0, 30) + '...'
        : option.subName

    const checked =
      selectedValue?.[option.id as keyof typeof selectedValue] || false

    return (
      <Box
        onClick={() => onSelect(option)}
        sx={{
          width: '100%',
          // height: '20px',
          transition: 'all 0.3s',
          borderRadius: 2,
          minHeight: '38px',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          textWrap: 'nowrap',
          paddingInline: '12px',
          padding: '4px',
          gap: '8px',
          '&:hover': {
            background: customTheme.palette.background.default,
            cursor: 'pointer',
          },
        }}
      >
        <Checkbox checked={!!checked} indeterminate={indeterminate} />
        <Tooltip title={option.name}>
          <Stack
            direction={'column'}
            alignItems={'flex-start'}
            sx={{
              '& .subtitle-searchSelectItem': {
                color: 'gray',
                fontSize: '14px',
              },
            }}
          >
            <span>{label}</span>
            {subLabel && (
              <span className={'subtitle-searchSelectItem'}>{subLabel}</span>
            )}
          </Stack>
        </Tooltip>
      </Box>
    )
  }
)

export default SearchSelectItem
