import { useState } from 'react'
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

interface LinkCopyProps {
  link?: string
}

export const LinkCopy = ({ link = '' }: LinkCopyProps) => {
  const [copied, setCopied] = useState(false)

  const handleClickCopyText = () => {
    link && navigator.clipboard.writeText(link)
    setCopied(true)
  }

  return (
    <OutlinedInput
      disabled
      value={copied ? 'Ссылка скопирована' : link}
      fullWidth
      placeholder="Ссылка на просмотр"
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickCopyText}
          >
            <Tooltip title="Копировать ссылку">
              <ContentCopyIcon />
            </Tooltip>
          </IconButton>
        </InputAdornment>
      }
    />
  )
}
