import axios from 'axios'
import { apiUrl } from '../../configs/env.config'

export const uploadImageMainTab = (data: {
  courseId: any
  imageType: '1' | '2'
  image: File
}) => {
  return axios.post(
    (apiUrl + 'course/upload/image') as string,
    { ...data },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    }
  )
}
