const tableConfig = {
  // Показывать блок пагинации
  isShowTotalCount: true,
  // dev показывать фильтры в шапке таблицы
  isShowFilter: true,
  // Пытаться извлеч тотал каунт из ответа бэка
  // isGetTotal: true
}

// при использовании функционала сохранения изменений на бэке, нужен контроль версий если необходимо внести изменения в кастомизированный конфиг пользователем

// Определение колонок таблицы, конфигурация каждой колонки, применение реформатторов и тд. (Функция реформатора выполняется всего 1 раз на этапе инициализации конфига)

// Конфиг последовательности колонок по умолчанию
const columnOrder = [
  'selectAll',
  'userFullName',
  'userEmail',
  'userLogin',
  'userPhone',
  'lastVisit',
  'supervisor',
  'managerName',
  'contractorTin',
  'contractorName',
  'departmentId',
  'departmentAddress',
  'departmentDirector',
  'departmentName',
  'regionName',
  'groupName',
  'userFirstName',
  'userLastName',
  'userPatronymic',
  'id',
  'accessToService',
]

// Конфиг видимости колонок по умолчанию
const columnVisibility = {
  'mrt-row-drag': true,
  selectAll: true,
  id: true,
  userFullName: true,
  userEmail: true,
  userLogin: true,
  userPhone: true,
  lastVisit: false,
  supervisor: false,
  managerName: false,
  contractorTin: false,
  contractorName: false,
  departmentId: false,
  departmentAddress: false,
  departmentDirector: false,
  departmentName: false,
  regionName: false,
  groupName: false,
  userFirstName: false,
  userLastName: false,
  userPatronymic: false,
  accessToService: true,
}

// Конфиг ширины колонок по умолчанию
const columnSizing = {
  userFullName: 150,
  userEmail: 150,
  userLogin: 150,
  userPhone: 150,
  lastVisit: 150,
  supervisor: 150,
  managerName: 150,
  contractorTin: 150,
  contractorName: 150,
  departmentId: 150,
  departmentAddress: 150,
  departmentDirector: 150,
  departmentName: 150,
  regionName: 150,
  groupName: 150,
  userFirstName: 150,
  userLastName: 150,
  userPatronymic: 150,
  accessToService: 120,
  selectAll: 50,
}

const columnPinning = {
  left: ['selectAll', 'userFullName'],
  right: [],
}

const defaultFontSize = 14
const density = 'comfortable' as const
// Структуризация конфигов для удобного прокидывания в пропсы таблицы
export { tableConfig as REGISTRATION_REQUESTS_TABLE_CONFIGS }
export const REGISTRATION_REQUESTS_COLUMNS_CONFIGS = {
  columnSizing,
  columnOrder,
  columnVisibility,
  columnPinning,
  defaultFontSize,
  density
}
