import React, { ReactElement } from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { PopoverProps, Tooltip } from '@mui/material'

const styles = {
  pl: 2,
  pr: 5,
  pt: 1,
  pb: 1,
  ':hover': {
    backgroundColor: '#f6f6f7',
  },
}

export type ItemsPropsChildren = {
  name: string | ReactElement
  onClick: () => void
  tooltipText?: string
  isVisible?: boolean
  selected?: boolean
}

export type ItemsProps = {
  items: ItemsPropsChildren[]
  icon?: JSX.Element
}

const testMenu = {
  items: [{ name: 'Тест', onClick: () => console.log('click') }],
  icon: <Checkbox />,
}

type MenuProps = {
  anchorEl: PopoverProps['anchorEl']
  open: boolean
  handleClose: () => void
  selectedMenuContent?: ItemsProps
}

const UIMenu: React.FC<MenuProps> = ({
  anchorEl,
  open,
  handleClose,
  selectedMenuContent = testMenu,
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {selectedMenuContent.items.map(
        (item: ItemsPropsChildren, index: number) =>
          (item.isVisible === undefined || item.isVisible) && (
            <Box key={index}>
              <Divider />
              {item.tooltipText ? (
                <Tooltip title={item.tooltipText}>
                  <MenuItem
                    onClick={item.onClick}
                    key={item + String(index)}
                    selected={item?.selected}
                    sx={styles}
                  >
                    <Box sx={{ display: 'flex', mr: 1 }}>
                      {selectedMenuContent.icon}
                    </Box>
                    {item.name}
                  </MenuItem>
                </Tooltip>
              ) : (
                <MenuItem
                  onClick={item.onClick}
                  key={item + String(index)}
                  selected={item?.selected}
                  sx={styles}
                >
                  <Box sx={{ display: 'flex', mr: 1 }}>
                    {selectedMenuContent.icon}
                  </Box>
                  {item.name}
                </MenuItem>
              )}
            </Box>
          )
      )}
    </Menu>
  )
}

export default UIMenu
