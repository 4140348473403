import {
  Box,
  TextField,
  Skeleton,
  Autocomplete,
  Chip,
  IconButton,
  Stack,
  AutocompleteRenderGetTagProps,
  Button,
} from '@mui/material'
import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import SearchSelectItem from '../SearchSelectItem/SearchSelectItem'
import { customTheme } from 'shared/configs/theme'
import { debounce, omit } from 'lodash'
import ClearIcon from '@mui/icons-material/Clear'
import InfiniteScroll from 'react-infinite-scroll-component'

interface ISearchColumnProps {
  path: string
  placeholder: string
  id: string
  searchValue?: string
  listValue?: Record<IOption['id'], IOption>
  ignoreListValue?: Record<IOption['id'], IOption>
  pickAll?: boolean
  onClose: () => void
  onFilter: () => void
  onClear: () => void
  getCallback?: (...args: any) => any
  onChangePickAll?: (value: boolean) => void
  onChangeSearchValue?: (value: string) => void
  onChangeListValue?: (value: Record<IOption['id'], IOption>) => void
  onChangeIgnoreListValue?: (value: Record<IOption['id'], IOption>) => void
  onChangeAll?: (data: {
    value?: string
    listKey?: Record<IOption['id'], IOption>
    ignoreList?: Record<IOption['id'], IOption>
    pickAll?: boolean
  }) => void
}

export type IOption = {
  id: string | number
  name: string
  subName?: string
}

const SearchColumn: FC<ISearchColumnProps> = memo((props) => {
  const {
    id,
    path,
    placeholder,
    searchValue,
    listValue,
    ignoreListValue,
    pickAll,

    onClose,
    getCallback,
    onFilter,
    onClear,
    onChangeSearchValue,
    onChangeListValue,
    onChangeIgnoreListValue,
    onChangePickAll,
    onChangeAll,
  } = props

  const [searchValueLocal, setSearchValueLocal] = useState<string>('')
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState<IOption[]>([])
  const [pickAllLocal, setPickAllLocal] = useState(false)

  const [totalCount, setTotalCount] = useState<{
    lastValue: string
    count: number
  }>({
    lastValue: '',
    count: 0,
  })

  const [ignoreList, setIgnoreList] = useState<Record<IOption['id'], IOption>>(
    {}
  )

  const [paginationData, setPaginationData] = useState<{
    pageNumber: number
    totalPages: number
    totalCount: number
  } | null>(null)

  const searchValueRef = useRef<any>()
  searchValueRef.current = searchValueLocal

  const handleSelectOption = useCallback(
    (newOption: IOption) => {
      const isSelected = listValue?.[newOption.id]

      if (pickAllLocal) {
        if (!isSelected) {
          setIgnoreList(omit(ignoreList, newOption.id))
          onChangeIgnoreListValue?.(omit(ignoreListValue, newOption.id))
        } else {
          setIgnoreList({ ...ignoreList, [newOption.id]: newOption })
          onChangeIgnoreListValue?.({
            ...ignoreListValue,
            [newOption.id]: newOption,
          })
        }
      }

      if (isSelected) {
        onChangeListValue?.(omit(listValue, newOption.id))
      } else {
        onChangeListValue?.({ ...listValue, [newOption.id]: newOption })
      }
    },
    [
      ignoreList,
      ignoreListValue,
      listValue,
      onChangeIgnoreListValue,
      onChangeListValue,
      pickAllLocal,
    ]
  )

  const handleSelectAll = (state: boolean) => {
    if (state) {
      setIgnoreList({})
      setPickAllLocal(true)
      setTotalCount({
        count: paginationData?.totalCount || 0,
        lastValue: searchValueLocal,
      })

      const newValues: Record<string, IOption> = {}
      options.forEach((elm) => (newValues[elm.id] = elm))

      onChangeAll?.({
        listKey: newValues,
        pickAll: true,
        ignoreList: {},
      })
      return
    }

    setPickAllLocal(false)
    setIgnoreList({})

    onChangeAll?.({
      listKey: {},
      pickAll: false,
      ignoreList: {},
    })

    setTotalCount({ lastValue: '', count: 0 })
  }

  const onClearAll = () => {
    setSearchValueLocal('')
    handleSelectAll(false)
    onChangeSearchValue?.('')
  }

  const onCancelFilters = () => onClear()

  const handleSearch = (searchValue: string) => {
    if (pickAllLocal) {
      handleSelectAll(false)
    }

    setSearchValueLocal(searchValue)
  }

  const onOk = () => {
    onChangeSearchValue?.(searchValueLocal)
    onFilter()
    onClose()
  }

  const onCancel = () => {
    setSearchValueLocal('')
    setPickAllLocal(false)
    onCancelFilters()
    onClose()
  }

  const getInfiniteData = () => {
    if (!getCallback) return

    setLoading(true)
    getCallback({
      [id]: searchValueLocal,
      pageSize: 20,
      pageNumber: paginationData?.pageNumber
        ? paginationData.pageNumber + 1
        : 1,
    }).then((response: any) => {
      const totalPages = response.data.data.totalPages
      const pageNumber = response.data.data.pageNumber
      const totalCountHere = response.data.data.totalCount

      setPaginationData({ totalPages, pageNumber, totalCount: totalCountHere })

      if (pickAllLocal && totalCount.lastValue === searchValueLocal)
        setTotalCount({
          lastValue: searchValueLocal,
          count: totalCountHere || 0,
        })

      const newOptions = response.data.data[path]

      if (pickAllLocal) {
        const newValues: Record<string, IOption> = Object.assign({}, listValue)
        newOptions.forEach((elm: IOption) => (newValues[elm.id] = elm))
        onChangeListValue?.(newValues)
      }

      setOptions(options.concat(newOptions))

      setLoading(false)
    })
  }

  useEffect(() => {
    if (getCallback) {
      setLoading(true)
      const debouncedFunction = debounce(() => {
        getCallback({ [id]: searchValueLocal, pageSize: 20 })
          .then((response: any) => {
            const newOptions = response.data.data[path]
            const totalPages = response.data.data.totalPages
            const pageNumber = response.data.data.pageNumber
            const totalCountHere = response.data.data.totalCount

            setTotalCount({
              lastValue: searchValueLocal,
              count: totalCountHere || 0,
            })

            setPaginationData({
              totalPages,
              pageNumber,
              totalCount: totalCountHere,
            })
            setOptions(newOptions)
          })
          .finally(() => setLoading(false))
      }, 500)
      debouncedFunction()

      return () => {
        debouncedFunction.cancel()
      }
    }
  }, [searchValueLocal])

  /** При монтирования компонента если есть */
  useEffect(() => {
    if (typeof pickAll === 'boolean') setPickAllLocal(pickAll)
  }, [])

  useEffect(() => {
    if (pickAllLocal) onChangeSearchValue?.(searchValueLocal)
  }, [pickAllLocal])

  useEffect(() => {
    if (searchValue) setSearchValueLocal(searchValue)

    return () => {
      onChangeSearchValue?.(searchValueRef.current)
    }
  }, [])

  const [focused, setFocused] = useState(false)

  const onRenderTags = useCallback(
    (tagValue: any, getTagProps: AutocompleteRenderGetTagProps) => {
      if (pickAllLocal) return
      if (focused) return
      return tagValue.map((option: IOption, index: number) => {
        return (
          <Chip
            size="small"
            label={option.name}
            {...getTagProps({ index })}
            onDelete={() => handleSelectOption(option)}
          />
        )
      })
    },
    [focused, handleSelectOption, pickAllLocal]
  )

  const getLoading = () => {
    return (
      <>
        {[...Array(10)].map((i, key) => (
          <Stack
            key={key}
            sx={{ padding: '0 20px' }}
            direction={'row'}
            alignItems={'center'}
            width="100%"
            gap="20px"
          >
            <Skeleton height={'38px'} width={'26px'} />
            <Skeleton height={'38px'} width="100%" />
          </Stack>
        ))}
      </>
    )
  }

  const ignoreListLength = useMemo(() => {
    return Object.keys(ignoreListValue || {}).length
  }, [ignoreListValue])

  const listLength = useMemo(() => {
    return Object.keys(listValue || {}).length
  }, [listValue])

  const totalCountView = () => {
    return totalCount.count - ignoreListLength
  }

  return (
    <Box
      sx={{
        width: 450,
        height: 500,
        backgroundColor: '#fff',
        boxShadow:
          '0px 12px 20px rgba(50,53,55,.11),0px 0px 20px rgba(50,53,55,.06)',
        borderRadius: '16px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          background: customTheme.palette.background.default,
          height: '60px',
          width: '100%',
          borderTopRightRadius: '16px',
          borderTopLeftRadius: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Autocomplete
          sx={{
            width: '90%',
          }}
          renderInput={(props) => {
            const { value, ...other } = props.inputProps
            return (
              <TextField
                sx={{
                  width: '100%',
                }}
                value={searchValueLocal}
                onChange={(event) => handleSearch(event.target.value)}
                inputProps={other}
                InputProps={{
                  startAdornment: props.InputProps.startAdornment,
                  endAdornment: (
                    <>
                      {pickAllLocal && !loading && totalCountView()}
                      <IconButton
                        sx={{
                          position: 'absolute',
                          right: '10px',
                        }}
                        onClick={onClearAll}
                      >
                        <ClearIcon id={id} />
                      </IconButton>
                    </>
                  ),
                }}
                placeholder={placeholder}
                size="small"
              />
            )
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          renderTags={onRenderTags}
          multiple
          open={false}
          limitTags={1}
          value={Object.values(listValue || {})}
          options={Object.values(listValue || {})}
          getOptionLabel={(option) => option.name}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: 1,
          maxHeight: '430px',
          overflow: 'hidden',
          paddingBottom: '25px',
        }}
      >
        <InfiniteScroll
          height={'390px'}
          dataLength={options.length}
          next={() => getInfiniteData()}
          hasMore={
            paginationData
              ? paginationData?.pageNumber !== paginationData?.totalPages
              : true
          }
          loader={<></>}
        >
          {options.length > 0 && (
            <SearchSelectItem
              selectedValue={
                pickAllLocal ? { all: { id: 'all', name: 'Выбрать всех' } } : {}
              }
              onSelect={(option) => handleSelectAll(!pickAllLocal)}
              option={{ id: 'all', name: 'Выбрать всех' }}
              indeterminate={
                pickAllLocal ? ignoreListLength > 0 : listLength > 0
              }
            />
          )}
          {options?.map((option) => (
            <SearchSelectItem
              key={option.id}
              selectedValue={listValue || {}}
              onSelect={handleSelectOption}
              option={option}
            />
          ))}
          {loading && getLoading()}
        </InfiniteScroll>
      </Box>

      <Stack
        sx={{
          width: '100%',
          background: customTheme.palette.background.default,
          borderRadius: '0 0 8px 8px',
          position: 'absolute',
          bottom: 0,
        }}
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
        gap={2}
      >
        <Button variant="contained" fullWidth onClick={onOk}>
          Применить
        </Button>
        <Button variant="text" color={'error'} fullWidth onClick={onCancel}>
          Отменить
        </Button>
      </Stack>
    </Box>
  )
})

export default SearchColumn
