import { createAsyncThunk } from '@reduxjs/toolkit'
import { getManager } from 'shared/api/requests/linkEntity/linkEntity'

export const fetchManager = createAsyncThunk(
  'user/fetchManager',
  async (params: any, thunkAPI) => {
    try {
      const response = await getManager(params)
      return response.data.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить пользователей')
    }
  }
)
