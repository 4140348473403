import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  TABLE_SETTINGS_MODULE,
  setColumnOrder,
  setColumnSizing,
  setColumnVisibility,
  setFetched,
  setIsNow,
  updateColumnOrder,
  updateFontSize,
  updateColumnSizing,
  updateColumnVisibility,
  setFonSize,
  updateColumnPinning,
  setDensity,
  updateDensity,
  setColumnPinning,
} from './tableSlice'
import { RootState } from 'store/store'
import { getUserTable, putUserTable } from 'shared/api/requests/users'
import { showSnack } from 'store/snackbar/snackbarSlice'
import { MRT_ColumnPinningState, MRT_DensityState } from 'material-react-table'

export type tableConfigModel = {
  v: number
  columnSizing: Record<string, number>
  columnVisibility: Record<string, boolean>
  columnOrder: string[] | []
  columnPinning: MRT_ColumnPinningState
  fontSize: number
  density: MRT_DensityState
}

// получить предыдущее состояние колонок
export const restoreTableConfigSettings = createAsyncThunk(
  `${TABLE_SETTINGS_MODULE}/restoreTableColumnsSettings`,
  async (
    target: { tableId: number; version: number },
    { dispatch, getState }
  ) => {
    // const {
    //   appSettingsSlice: { isSaveColumnsSettings },
    // } = getState() as RootState
    const { version, tableId } = target

    // Если в надстройках отключено запоминание настроек таблицы
    // if (!isSaveColumnsSettings) return

    try {
      const { data } = await getUserTable({
        tableId,
      })

      const tableConfig = data?.content
        ? (JSON.parse(data.content) as tableConfigModel)
        : undefined

      if (!tableConfig) {
        dispatch(setIsNow({ tableId, isNow: true }))
        throw new Error(`Config for ${tableId} table not found`)
      }

      if (!('v' in tableConfig && version === tableConfig.v))
        throw new Error(`Config for ${tableId} table outdated`)

      const {
        columnSizing,
        columnVisibility,
        columnOrder,
        fontSize,
        columnPinning,
        density,
      } = tableConfig

      if (columnSizing) dispatch(setColumnSizing({ tableId, columnSizing }))
      if (columnVisibility)
        dispatch(setColumnVisibility({ tableId, columnVisibility }))
      if (columnOrder) dispatch(setColumnOrder({ tableId, columnOrder }))
      if (fontSize) dispatch(setFonSize({ tableId, fontSize }))
      if (columnPinning) dispatch(setColumnPinning({ tableId, columnPinning }))
      if (density) dispatch(setDensity({ tableId, density }))

      // dispatch(addSnackNotification(SNACK_NOTIFICATIONS.tableSettings.restore.success))
    } catch (e) {
      dispatch(setIsNow({ tableId, isNow: true }))
      console.error(e)
    }
    dispatch(setFetched({ tableId, isFetched: true }))
  }
)

type updateConfigType = {
  columnSizing?: Record<string, number>
  columnVisibility?: Record<string, boolean>
  columnOrder?: string[]
  columnPinning?: MRT_ColumnPinningState
  fontSize?: number
  density?: MRT_DensityState
}

// сохранить измененные настройки колонок
export const saveTableConfigSettings = createAsyncThunk(
  `${TABLE_SETTINGS_MODULE}/saveTableColumnsSettings`,
  async (
    { tableId, content }: { tableId: number; content: updateConfigType },
    { getState, dispatch }
  ) => {
    const {
      columnSizing,
      columnVisibility,
      columnOrder,
      fontSize,
      columnPinning,
      density,
    } = content

    if (columnSizing) dispatch(updateColumnSizing({ tableId, columnSizing }))
    if (columnVisibility)
      dispatch(updateColumnVisibility({ tableId, columnVisibility }))
    if (columnOrder) dispatch(updateColumnOrder({ tableId, columnOrder }))
    if (fontSize) dispatch(updateFontSize({ tableId, fontSize }))
    if (columnPinning) dispatch(updateColumnPinning({ tableId, columnPinning }))
    if (density) dispatch(updateDensity({ tableId, density }))
    // const {
    //   tableSlice: { isSaveColumnsSettings },
    //   // @ts-ignore
    // } = getState() as RootState

    // if (!isSaveColumnsSettings) return

    try {
      const {
        columnSizing,
        columnVisibility,
        columnOrder,
        columnPinning,
        v,
        isNow,
        fontSize,
        density,
      } = (getState() as RootState).tableSettings.tables[tableId]

      const args = {
        params: {
          tableId,
          content: JSON.stringify({
            columnOrder,
            columnVisibility,
            columnSizing,
            columnPinning,
            fontSize,
            v,
            density,
          }),
        },
      }

      await putUserTable(args)
      dispatch(setIsNow({ tableId, isNow: false }))

      showSnack({
        typeSnack: 'success',
        messageSnack: 'Настройки таблицы успешно обновлены!',
      })
    } catch (e) {
      showSnack({
        typeSnack: 'error',
        messageSnack: 'Ошибка при изменении настроек таблицы!',
      })
      console.error(e)
    }
  }
)
