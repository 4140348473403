import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

// MUI
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import Tooltip from '@mui/material/Tooltip'
import Box from "@mui/material/Box";

// Icons
import MenuIcon from "@mui/icons-material/Menu";
import { SvgIconProps } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// Components
import { SubCategoryList } from './SubCategoryList'

const styles = {
  categoryList: { position: 'relative' },
  menuButton: {
    position: 'absolute',
    right: 15,
  },
  categoryBox: { mt: 7 },
  categoryButton: {
    p: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  categoryName: { mx: 3, ml: 4.5 , fontWeight: 600},
  subcategoryIconBox: {
    position: 'absolute',
    right: '-6px',
    bottom: '8px',
    pr: '4px'
  }
}

export type Category = {
  // Название раздела
  name: string;

  // Url раздела
  link: string;

  // Иконка раздела
  icon: React.ReactElement<SvgIconProps>;

  // Подразделы
  subCategories?: SubCategory[];
};

export type SubCategory = {
  // Название подраздела раздела
  name: string;

  // Url подраздела
  link: string;
};

export type CategoryListProps = {
  // Состояние открытия бокового меню
  open: boolean,

  // Разделы бокового меню
  categories: Category[];

  // Отображать название раздела при открытом меню, иначе ничего не отображать
  openCategory: string | null;

  // Хлебные крошки
  breadcrumbs: string;

  // Переход на страницу
  handleClickLink: (name: string) => void;

  // Открыть радел со списоком подразделов
  handleClickCategory: (name: string) => void;

  // Открыть полное боковое меню
  handleDrawerToggle: (val: boolean) => void;
}

export const CategoryList = (props: CategoryListProps) => {

  const {
    open,
    categories,
    openCategory,
    breadcrumbs,
    handleClickLink,
    handleClickCategory,
    handleDrawerToggle
  } = props;

  return (
    <List sx={styles.categoryList}>
      <Tooltip placement="right" title={open ? 'Свернуть меню' : 'Развернуть меню'}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerToggle(true)}
          edge="start"
          sx={styles.menuButton}
        >
          <MenuIcon color="disabled"  fontSize="large" />
        </IconButton>
      </Tooltip>
      <Box sx={styles.categoryBox}>
        {categories.map((category) => (
          <React.Fragment key={category.name}>
            <ListItem
              key={category.name}
              disablePadding
              selected={breadcrumbs === category.name}
            >
              <ListItemButton
                sx={styles.categoryButton}
                component={RouterLink}
                to={category.link}
                onClick={(event) => {
                  if (category.subCategories) {
                      event.preventDefault();
                    handleClickCategory(category.name)
                  } else {
                    handleClickLink(category.name)
                  }
                }}
              >
                <Tooltip placement="right" title={open ? '' : category.name}>
                  {category.icon}
                </Tooltip>
                <ListItemText>
                  <Typography sx={styles.categoryName}>
                    {category.name}
                  </Typography>
                </ListItemText>
                {category.subCategories && (
                  <Box
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   event.stopPropagation();
                    //   handleClickCategory(category.name)
                    // }}
                    sx={styles.subcategoryIconBox}
                  >
                    {openCategory === category.name ? <ArrowDropDownIcon  /> : <ArrowRightIcon />}
                  </Box>
                )}
              </ListItemButton>
            </ListItem>
            {category.subCategories && (
              <Collapse in={openCategory === category.name} timeout="auto" unmountOnExit>
                <SubCategoryList
                  subCategories={category.subCategories}
                  handleClickLink={handleClickLink}
                />
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </Box>

  </List>
  );
};

export default CategoryList;
