import { Box, Button, Stack, Typography } from '@mui/material'
import { FC, useCallback } from 'react'
import {
  setLastVisitValueSend,
  setLastVisitToUse,
  clearLastVisit,
} from 'widgets/UsersTableAll/model/table/tableSlice'
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux'
import { customTheme } from 'shared/configs/theme'
import { showSnack } from 'store/snackbar/snackbarSlice'
import RangePicker2 from 'shared/UI/RangePicker2/RangePicker2'
import moment from 'moment'
interface IRangePickerFilterProps {
  tableId: number
  onCancel: () => void
}

const startKey = 'userLastVisitStart'
const endKey = 'userLastVisitEnd'
const format = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'

const dateFormatter = (date: Date | string | null) => {
  if (!date) return '' // Если дата не определена, вернуть пустую строку

  if (typeof date === 'string') return date // Если дата уже строка, вернуть ее без изменений

  const momentDate = moment(date).utc() // Используем метод .utc() для снятия смещения временной зоны

  // Устанавливаем время на начало дня (00:00)
  momentDate.startOf('day')

  return momentDate.format(format)
}

const handleCheckDate = (date: Date | string | null) => {
  if (!date) return false // Если дата не определена, считать ее недопустимой
  const isValidDate = moment(date).isValid()

  return isValidDate
}

const RangePickerFilter: FC<IRangePickerFilterProps> = (props) => {
  const dispatch = useAppDispatch()

  const { tableId, onCancel } = props

  const filterToUse = useAppSelector(
    (state) => state.tableSettings.tables[tableId]?.filters
  )

  const startValue = filterToUse?.lastVisit?.userLastVisitStart
    ? filterToUse?.lastVisit?.userLastVisitStart
    : null

  const endValue = filterToUse?.lastVisit?.userLastVisitEnd
    ? filterToUse?.lastVisit?.userLastVisitEnd
    : null

  const handleChangeStart = useCallback(
    (startValue: Date | null) => {
      const formattedValue = dateFormatter(startValue)
      const payloadParam = {
        rangeValue: formattedValue,
        rangeKey: startKey,
        tableId,
      }
      dispatch(setLastVisitToUse(payloadParam))
    },
    [tableId, dispatch]
  )

  const handleChangeEndValue = useCallback(
    (endValue: Date | null) => {
      const formattedValue = dateFormatter(endValue)
      const payloadParam = {
        rangeValue: formattedValue,
        rangeKey: endKey,
        tableId,
      }
      dispatch(setLastVisitToUse(payloadParam))
    },
    [dispatch, tableId]
  )

  const handleSubmit = () => {
    if (
      (startValue?.toLocaleString().length && !handleCheckDate(startValue)) ||
      (endValue?.toLocaleString().length && !handleCheckDate(endValue))
    ) {
      dispatch(
        showSnack({
          typeSnack: 'error',
          messageSnack: 'Неправильный формат даты',
        })
      )
      return
    }
    dispatch(
      setLastVisitValueSend({ lastVisitObj: filterToUse?.lastVisit, tableId })
    )
    onCancel()
  }

  const handleCancel = () => {
    dispatch(clearLastVisit(tableId))
    onCancel()
  }

  return (
    <Box
      sx={{
        width: 480,
        height: 150,
        backgroundColor: '#fff',
        boxShadow:
          '0px 12px 20px rgba(50,53,55,.11),0px 0px 20px rgba(50,53,55,.06)',
        borderRadius: '16px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          p: '1px 23px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            textAlign: 'center',
            marginTop: '12px',
            mb: '12px',
          }}
        >
          Выберите период
        </Typography>
        <RangePicker2
          onStartChange={handleChangeStart}
          onEndChange={handleChangeEndValue}
          startValue={startValue}
          endValue={endValue}
        />
      </Box>
      <Stack
        sx={{
          width: '100%',
          background: customTheme.palette.background.default,
          borderRadius: '0 0 8px 8px',
          position: 'absolute',
          bottom: 0,
        }}
        alignItems={'center'}
        direction={'row'}
        justifyContent={'space-between'}
        gap={2}
      >
        <Button variant="contained" fullWidth onClick={handleSubmit}>
          Применить
        </Button>
        <Button variant="text" color={'error'} fullWidth onClick={handleCancel}>
          Отменить
        </Button>
      </Stack>
    </Box>
  )
}

export default RangePickerFilter
