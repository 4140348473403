import * as React from 'react'

import { useAppSelector } from '../../../shared/hooks/redux'
import { useLocation } from 'react-router'
import { parse, stringify } from 'query-string'
import { useNavigateWrapper } from 'shared/hooks/useNavigateWrapper'
import { AppointmentUsersTable } from 'feature/AppointmentUsersTable'
import { AppointmentUserElement } from 'feature/AppointmentUsersTable/model/types'
import { AppointmentUsersTableData } from 'feature/AppointmentUsersTable/ui/AppointmentUsersTable'
import { AppointmentsToolbarActions } from './AppointmentsToolbarActions'

export function AppointmentUsersCRUD({
  materialId,
}: {
  materialId: string | number
}) {
  const location = useLocation()
  const { navigate } = useNavigateWrapper()

  const [tableData, setTableData] =
    React.useState<AppointmentUsersTableData | null>(null)

  const selectedTabCourse = useAppSelector(
    (state) => state.breadCrumb.selectedTabCourse
  )

  const [clearSelectedTrigger, setClearSelectedTrigger] = React.useState(false)
  const onTriggerClearSelected = () => setClearSelectedTrigger((prev) => !prev)

  const handleOpenUserInformation = (row: AppointmentUserElement) => {
    const curParams = parse(location.search)
    const searchStr = stringify({ userId: row.userId })
    const prevStr = stringify({ ...curParams, tab: selectedTabCourse })
    const prevUrl = `${location.pathname}?${prevStr}`

    navigate(`/user-management/user-information?${searchStr}`, {
      state: { prev: prevUrl },
    })
  }

  return (
    <>
      <AppointmentsToolbarActions
        tableData={tableData}
        materialId={materialId || 0}
        onTriggerClearSelected={onTriggerClearSelected}
      />
      <AppointmentUsersTable
        materialId={materialId || 0}
        onClickUserName={(row) => handleOpenUserInformation(row)}
        clearTrigger={clearSelectedTrigger}
        onSelectedChange={setTableData}
      />
    </>
  )
}
