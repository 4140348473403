import { CSSProperties } from 'react'

interface HighlightSearchTextProps {
  text: string
  search: string
  style?: CSSProperties
}
export default function HighlightSearchText({
  text,
  search,
  style,
}: HighlightSearchTextProps) {
  const stroka = search.replace(/[\[\]{}()]/g, '')
  const regex = new RegExp(`(${stroka})`, 'gi')

  const highlightedText = text?.replace(regex, '<strong>$1</strong>')

  return (
    <div style={style} dangerouslySetInnerHTML={{ __html: highlightedText }} />
  )
}
