import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface AppointmentUsersCRUDSchema {
  openAssignCourse: boolean
}

const initialState: AppointmentUsersCRUDSchema = {
  openAssignCourse: false,
}

export const appointmentCRUDSlice = createSlice({
  name: 'appointmentCRUD',
  initialState,
  reducers: {
    setOpenAssignCourse: (state, action: PayloadAction<boolean>) => {
      state.openAssignCourse = action.payload
    },
  },
})

export const { actions: appointmentCRUDActions } = appointmentCRUDSlice
export const { reducer: appointmentCRUDReducer } = appointmentCRUDSlice
