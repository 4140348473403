import { store } from 'store/store'
import { apiUrl } from 'shared/configs/env.config'
import { authController, httpCore } from 'shared/configs/instance'

export const getAuthUser = () => httpCore.get('user/info')

export const loginUser = async () => {
  return await authController.authorize(apiUrl || '')
}

export const logoutUser = async () => {
  const res = await authController.logoutService(apiUrl || '')
  window.location.reload()
  return res
}
